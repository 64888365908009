<div class="modal-content">
  <div class="modal-header">
    <button
      type="button"
      class="m-0 p-0 popup-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="onCloseDialog()"
    >
      <i class="bi bi-x-lg"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="run-pipeline-popup">
      <h3 class="heading">Run new pipeline on a specific branch</h3>
      <form [formGroup]="pipeForm">
        <div class="form-group mb-5">
          <label class="msg-acc-label"
            >Branch Name<sup>*</sup></label
          >
          <input
            type="text"
            class="form-control"
            placeholder="dev"
            formControlName="branchName"
          />
        </div>

        <div class="form-group mb-5">
          <label class="msg-acc-label"
            >Version<sup>*</sup></label
          >
          <input
            type="text"
            class="form-control"
            placeholder="v1.0.1-dev"
            formControlName="version"
          />
        </div>

        <div class="form-group mb-5">
          <label> Configuration <i class="fa fa-spinner fa-spin" *ngIf="loadingBuildConfig"></i> </label>
          <select class="form-control" formControlName="dockerImage" (change)="onChangeConfigration()">
            <option *ngFor="let item of buildconfigs; let i =index " [value]="item.id" [disabled]="!canRunOrDeploy(item)" [attr.selected]="this.buildconfigs.length == 1 && onChangeConfigration() ? true: null">
              {{ item.name }} {{ !canRunOrDeploy(item) ? '(can\'t used)' : '' }}
           </option>
          </select>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-success run-pipe" [disabled]="pipeForm.invalid || submitStateButton" (click)="submitRunNewPipeline()">
            Run pipeline
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

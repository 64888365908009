<div class="modal-content">
  <div class="modal-body">
    <div class="invite-popup">
      <h3 class="heading">
        <span class="fa fa-warning fa-1x"></span>
        <br>
        <span>
          Cloudoor APP
        </span> <br>
        <span style="font-weight: normal; font-size: 16px;">Is currently only available on Desktops, We're coming soon to mobile.</span>
        <br>
        <span style="font-weight: 500; font-size: 16px;">
          Please open Cloudoor APP on your PC
        </span>

      </h3>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})


export class ModalComponent implements OnInit {
  @Input() modalId: string;
  @Input() size: string;

  constructor() { }

  ngOnInit(): void {
  }

  close():void {
    $(`#${this.modalId}`).modal('hide');
  }

}

<div class="col-12" *ngIf="pipelineInfo">
  <div class="row">
    <div class="col-xl-12 col-md-12 header-div">
      <div class="title" style="color: #043c66" *ngIf="!loadingPipeline">
        Codes&nbsp;&nbsp;<span (click)="onBack()" class="button-back">/&nbsp;&nbsp;{{pipelineInfo?.name}}</span><span
          *ngIf="pipelineInfo" class="routerName">/&nbsp;&nbsp;{{pipelineInfo?.commitMessage}}</span>
      </div>
      <div>
        <button [disabled]="logsDataTable.length == 0" *ngIf="applications.length == 0" class="btn btn-success"
          (click)="onDeployAppInAnEnvironment(null)">
          First Deploy
        </button>
        <div class="btn-group" dropdown *ngIf="applications.length > 1" [isDisabled]="logsDataTable.length == 0">
          <button id="button-basic" dropdownToggle type="button"
            class="btn btn-success dropdown-toggle" aria-controls="dropdown-basic">
            Update Deploy <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem" *ngFor="let application of applications; let i=index">
              <a class="dropdown-item py-1" [routerLink]="['/deliver/'+application?.workloadId]"
                [queryParams]="{ proj: application?.projectId, env: application?.environmentId, openForm: true }">
                {{ application?.metadata?.name }}({{ application?.environmentName }})
              </a>
            </li>
          </ul>
        </div>
        <button class="btn btn-success" *ngIf="applications.length == 1"
          [disabled]="logsDataTable.length == 0">
          <a [routerLink]="['/deliver/'+applications[0]?.workloadId]" style="text-decoration: none; color: white;"
            [queryParams]="{ proj: applications[0]?.projectId, env: applications[0]?.environmentId, openForm: true }">
            Update Deploy
          </a>
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <!-- First Column (col-5) -->
    <div class="col-md-12">
      <div class="card bg-sec border-0 px-3">
        <div class="card-body" *ngIf="!loadingPipeline">
          <div class="row shadow ">
            <div class="upper p-30 col-md-6 col-sm-6">
              <span class="fa-solid " style="font-size: 20px"
                *ngIf="pipelineInfo && (pipelineInfo.status == '' || (pipelineInfo.status !== 'Succeeded' && !getErrorFailedStatus(pipelineInfo.status)))">
                <i class="fa fa-spinner fa-spin"></i>
              </span>

              <span class="fa-solid bi bi-check-circle-fill success-text" style="font-size: 20px"
                *ngIf="pipelineInfo && pipelineInfo?.status == 'Succeeded'">
              </span>

              <span class="fa-solid bi bi-x-circle-fill danger-text" style="font-size: 20px"
                *ngIf="pipelineInfo && getErrorFailedStatus(pipelineInfo?.status)">
              </span>


              <div class="text color-1 h5-text mb-1" *ngIf="pipelineInfo">
                {{pipelineInfo?.commitMessage?.charAt(0).toUpperCase() + pipelineInfo?.commitMessage?.slice(1)}}
              </div>
              <div class="text color-1 h5-text mb-1" *ngIf="!pipelineInfo">
                {{"Loading ..."}}
              </div>
            </div>
            <div class="upper p-30 col-md-1 col-sm-1">
              <div class=" py-1">
                <div class="text color-1 h5-text mb-1">Status</div>
                <small class="color-2 desc-text"> {{pipelineInfo?.status}}</small>
              </div>
            </div>
            <div class="upper p-30 col-md-3 col-sm-3">
              <div class=" py-1">
                <div class="text color-2 h5-text mb-1">Image</div>
                <small class="color-1 desc-text">{{pipelineInfo.name}}:{{pipelineInfo?.appVersion}}</small>
              </div>
            </div>
            <div class="upper p-30 col-md-2 col-sm-2">
              <div style="display: flex; align-items: center">
                <span class="bi bi-calendar4" style="font-size: 14px; font-weight: 900"></span>
                <div style="margin-left: 12px">
                  <div class="text-color" style="font-size: 12px; font-weight: 600; color: #043c66">
                    {{displayDateForHuman(pipelineInfo?.createdAt)}}
                  </div>
                  <small class="text-color"
                    style="font-size: 12px; font-weight: 600; color: #135BF0">{{pipelineInfo?.duration == "" ? "0s" :
                    pipelineInfo?.duration}}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body loading-pipeline-section" *ngIf="loadingPipeline">
          Loading pipeline job infos ...
        </div>
      </div>
    </div>

    <!-- Second Column (col-7) -->
    <div class="col-md-12">
      <div class="card bg-sec border-0">
        <div class="card-body">
          <div class="text-right mb-2">
            <button class="btn btn-secondary" [disabled]="logsDataTable.length == 0" (click)="onRerunPipeline()">
              <i class="bi bi-arrow-repeat" *ngIf="logsDataTable.length != 0"></i>
              <i class="fa fa-spin fa-spinner" *ngIf="logsDataTable.length == 0"></i>
              Rerun job
            </button>
          </div>
          <div class="accordian-sec">
            <div class="accordion" id="accordionExample">

              <div class="accordion-item" *ngFor="let item of logsDataTable; let i = index"
                (click)="updateScroll('pre'+i)">
                <h2 class="accordion-header" id="heading{{ i }}">
                  <button class="accordion-button justify-content-between" type="button"
                    style="width: -webkit-fill-available; " [class.tab-active]="selectedTab == i"
                    (click)="toggleCollapse(i)" [attr.aria-controls]="'collapseOne' + i"
                    [attr.data-bs-target]="'#collapseOne' + i" [attr.aria-expanded]="i == this.currentOpenLog">
                    <div class="d-flex gap-3" style="width: -webkit-fill-available; ">
                      <span class="drop-down-arrow-opacity" [ngClass]="
                            i != this.currentOpenLog
                              ? 'bi bi-chevron-right'
                              : 'bi bi-chevron-down'
                          " style="font-size: 20px"></span>

                      <span class="fa-solid bi bi-check-circle-fill success-text" style="font-size: 20px;"
                        *ngIf="item.status == 'completed'">
                      </span>

                      <span class="fa-solid " style="font-size: 20px" *ngIf="item.status == 'running'">
                        <i class="fa fa-spinner fa-spin"></i>
                      </span>


                      <span class="fa-solid" style="font-size: 20px"
                        *ngIf="!item?.status.includes('error') && !item?.status.includes('failed') && !['running','completed'].includes(item?.status)">
                        <i class="bi bi-circle"></i>
                      </span>

                      <span class="fa-solid bi bi-x-circle-fill danger-text" style="font-size: 20px"
                        *ngIf="item?.status.includes('error') || item?.status.includes('failed')"></span>

                      <div class="h5-text" style="text-wrap: nowrap;">{{ item?.key }}</div>
                      <div class="duration-styled">{{ item?.duration }}</div>
                    </div>
                  </button>
                </h2>
                <div [id]="'collapseOne' + i" class="accordion-collapse collapse"
                  [ngClass]="{ show: i == this.currentOpenLog }" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="my-3">
                      <pre class="mono-text opacity-52 wrap-text" [id]="'pre'+i"
                        style="line-height: 17px; font-size: 15px;">
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="loading-section" *ngIf="!pipelineInfo || loadingLog || logsDataTable.length == 0">
                Wait for scheduling pod.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;"
  *ngIf="!pipelineInfo">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">App logs loading</span>
</div>

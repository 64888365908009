import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Workload } from 'src/app/data/model/workload';
import { CdService } from '../../../../services/cd.service';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
declare var $: any;

@Component({
  selector: 'app-modal-multi-deploy',
  templateUrl: './modal-multi-deploy.component.html',
  styleUrls: ['./modal-multi-deploy.component.scss'],
})
export class ModalMultiDeployComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  apps: Workload[] = [];
  strategies = [
    { name: 'Default', value: 'default' },
    { name: 'Blue/Green', value: 'blue/green' },
    { name: 'Canary', value: 'canary' },
    { name: 'A/B Testing', value: 'ab/testing' },
  ];
  appForm: FormGroup;
  selectedStrategy: string;
  steps: any[] = [];
  headers: any[] = [];
  headersForm: FormGroup;
  stepControl: FormControl;
  loading = false;
  workloadSpec = {};
  constructor(
    private modalRef: BsModalRef,
    private fb: FormBuilder,
    private cdService: CdService,
    private service: AsideExtenderService
  ) {}

  ngOnInit(): void {
    this.selectedStrategy = 'default';
    this.initForm();
  }

  initForm() {
    let strategy = this.selectedStrategy;
    this.headersForm = new FormGroup({});
    this.appForm = new FormGroup({
      strategy: new FormControl(strategy, [Validators.required]),
      replicas: new FormControl(null),
    });

    if (['canary', 'blue/green', 'ab/testing'].includes(strategy)) {
      let workloads = new FormGroup({});
      this.apps.forEach((app: any) => {
        let stagingForm = new FormGroup({});

        if (!app?.spec?.strategySpec?.staging) {
          app.spec.strategySpec.staging = [];
          app?.spec?.strategySpec?.live.forEach((element) => {
            app?.spec?.strategySpec?.staging.push({
              name: element?.name,
              version: element?.version,
              image: element?.image,
            });
          });
        }

        app?.spec?.strategySpec?.staging.forEach(
          (container: any, index: number) => {
            stagingForm.addControl(
              'staging_' + index,
              new FormControl(container?.version, Validators.required)
            );
          }
        );
        workloads.addControl(app?.metadata?.uid, stagingForm);
      });
      this.appForm.addControl('workloads', workloads);

      // Only Canary
      if (strategy == 'canary') {
        let autoProgression: any = {};
        let canarySpecForm = new FormGroup({
          trafficSplitting: new FormControl(0, [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          enabled: new FormControl(false),
          interval: new FormControl('30m', [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          rateMin: new FormControl(99, [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          rateMax: new FormControl(100, [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          latencyMin: new FormControl(0, [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          latencyMax: new FormControl(1, [
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]),
          type: new FormControl('increment'),
          increment: new FormControl(10, [Validators.required]),
          steps: new FormControl(null),
        });

        this.steps = [];

        this.stepControl = new FormControl(0, [
          Validators.min(1),
          Validators.max(100),
        ]);
        this.appForm.addControl('canarySpecForm', canarySpecForm);
      }

      // Only Ab/Testing
      if (strategy == 'ab/testing') {
        // this.stepControl = new FormControl(0, [Validators.min(0),Validators.max(100),]);

        let abTestingSpecForm = new FormGroup({});
        this.appForm.addControl('abTestingSpecForm', abTestingSpecForm);
      }
    }

    // Only Ab/Testing
    if (strategy == 'default') {
      let defaultSpecForm = new FormGroup({
        autoDeploy: new FormControl(false),
      });

      let workloads = new FormGroup({});

      this.apps.forEach((app: any) => {
        let liveForm = new FormGroup({});
        app?.spec?.strategySpec?.live.forEach((element: any, index) => {
          liveForm.addControl(
            'live_' + index,
            new FormControl(element?.version, Validators.required)
          );
        });
        workloads.addControl(app?.metadata?.uid, liveForm);
      });
      this.appForm.addControl('workloads', workloads);
      this.appForm.addControl('defaultSpecForm', defaultSpecForm);
    }
  }

  onChangeAutoDeploy(event: any) {
    let autoDeploy = event.target.checked;
    this.appForm.get('defaultSpecForm').get('autoDeploy').setValue(autoDeploy);
  }

  onStrategyChange(strategy: any): void {
    this.selectedStrategy = strategy?.value;
    this.appForm.get('strategy').setValue(strategy?.value);
    this.initForm();
  }

  onChangeStagingVersion(event, ov: any, index: any) {
    // $($(event.target).parent()).css('display', 'none');
    // this.appForm.get('stagingForm').get('staging_' + index).setValue(ov.name);
  }

  getLiveVersion(index: number, app) {
    return app?.spec?.strategySpec?.live[index]?.version;
  }

  getLiveContainer(index, app) {
    return app?.spec?.strategySpec?.live[index]?.name;
  }

  getStagingVersion(index: number, app) {
    return this.appForm
      .get('stagingForm')
      ?.get('workloads')
      .get(app.id)
      .get('staging_' + index)?.value;
  }

  getStagingContainer(index, app) {
    return app?.spec?.strategySpec?.staging[index]?.name;
  }

  displayLiveValue() {
    let val = this.appForm.get('canarySpecForm').get('trafficSplitting').value;

    if (val) {
      if (!isNaN(val)) {
        if (val <= 100) {
          val = parseFloat(val);
          return 100 - val;
        }
      }
    }

    return 100;
  }

  onDeleteStep(index: number) {
    this.steps.splice(index, 1);
  }

  onAddStep() {
    if (this.stepControl.valid) {
      this.steps.push(this.stepControl?.value);
      this.stepControl.setValue(0);
    }
  }

  onAddHeader() {
    let id = Date.now();
    this.headersForm.addControl(
      'key_' + id,
      new FormControl('', Validators.required)
    );
    this.headersForm.addControl(
      'value_' + id,
      new FormControl('', Validators.required)
    );
    this.headers.push({ key: '', value: '', index: id });
  }

  onDeleteHeader(index: any) {
    let arrayrIndex = this.headers.findIndex((h) => h.index == index);
    if (arrayrIndex !== -1) {
      this.headers.splice(arrayrIndex, 1);
      this.headersForm.removeControl('key_' + index);
      this.headersForm.removeControl('value_' + index);
    }
  }

  formatData(app: any) {
    let workloadSpec = {};

    const formvalue = this.appForm.value;
    const strategies = ['canary', 'blue/green', 'ab/testing'];

    workloadSpec['deploymentStrategy'] = formvalue?.strategy;
    workloadSpec['replicas'] = null;
    workloadSpec['id'] = app.metadata.uid;

    if (strategies.includes(formvalue.strategy)) {
      workloadSpec['stagingContainers'] = [];
      app?.spec?.strategySpec?.staging.forEach(
        (element: any, index: number) => {
          workloadSpec['stagingContainers'].push({
            image: element?.image,
            name: element?.name,
            version: formvalue?.workloads[app.metadata.uid]['staging_' + index],
          });
        }
      );

      if (formvalue.strategy == 'canary') {
        const metrics = new Map();
        (metrics['request-success-rate'] = {
          max: formvalue?.canarySpecForm?.rateMax,
          min: formvalue?.canarySpecForm?.rateMin,
        }),
          (metrics['request-duration'] = {
            max: formvalue?.canarySpecForm?.latencyMax,
            min: formvalue?.canarySpecForm?.latencyMin,
          });

        workloadSpec['trafficSplitting'] = parseFloat(
          formvalue?.canarySpecForm?.trafficSplitting
        );
        workloadSpec['autoProgression'] = {
          interval: formvalue?.canarySpecForm?.interval,
          maxWeight: 100,
          minRequest: 0,
          enabled: formvalue?.canarySpecForm?.enabled,
          metrics,
        };

        if (formvalue.canarySpecForm.type == 'steps') {
          workloadSpec['autoProgression'].steps = this.steps;
          workloadSpec['autoProgression'].increment = 0;
        } else {
          workloadSpec['autoProgression'].steps = [];
          workloadSpec['autoProgression'].increment =
            formvalue?.canarySpecForm?.increment;
        }
      }

      if (formvalue.strategy == 'ab/testing') {
        workloadSpec['headers'] = {};
        this.headers.forEach((element: any) => {
          let key = this.headersForm.get('key_' + element.index).value;
          let value = this.headersForm.get('value_' + element.index).value;
          workloadSpec['headers'][key] = value;
        });
      }
    }

    if (formvalue.strategy == 'default') {
      workloadSpec['autoDeploy'] = formvalue?.defaultSpecForm?.autoDeploy;
      workloadSpec['liveContainers'] = [];
      app?.spec?.strategySpec?.live.forEach((element: any, index: number) => {
        workloadSpec['liveContainers'].push({
          image: element?.image,
          name: element?.name,
          version: formvalue?.workloads[app.metadata.uid]['live_' + index],
        });
      });
    }

    return workloadSpec;
  }

  onUpdate(): void {
    let workloadSpecs = [];
    this.apps.forEach((app) => {
      workloadSpecs.push(this.formatData(app));
    });

    this.loading = true;
    this.cdService.editWorkloads(workloadSpecs).subscribe(
      async (response) => {
        this.onCloseDialog();
        this.service.show({
          title: 'Workload',
          message: `Workloads updated`,
          type: Type.SUCCESS,
          position: Position.TOP,
        });
        this.loading = false;
        this.event.emit({ apps: response, res: 200 });
      },
      (err) => {
        this.service.show({
          title: 'Workload',
          message: err.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.loading = false;
      }
    );
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }


}

<div class="wrapper py-5">
    <div class="container">
      <div class="row" *ngIf="type == 'subscription'">
        <div class="col-md-2 text-center">
          <p><i class="fa fa-exclamation-triangle fa-5x"></i><br />Unauthorized</p>
        </div>
        <div class="col-md-10">
          <h3>Oops!!! You're blocked.</h3>
          <p class="body">
            Sorry, your access has been denied due to non-payment of invoice. <br>
            Please contact support for further details.
          </p>
        </div>
      </div>
    </div>
</div>

import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ChartsModule } from 'ng2-charts';
import { CircularChartComponent } from './components/circular-chart/circular-chart.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewStatementComponent } from './components/view-statement/view-statement.component';
import { ModalComponent } from './components/modal/modal.component';
import { FilterPipe } from './pipes/filter.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast.component';

import { AvatarModule } from 'ngx-avatar';
import { DatediffPipe } from './pipes/datediff.pipe';
import { CodeEditorModule } from '@ngstack/code-editor';
import { ConfigUploadComponent } from './components/config-upload/config-upload.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule,
    NgxPaginationModule,
    AvatarModule,
    CodeEditorModule.forRoot({
      baseUrl: 'assets/monaco',
    }),
    // MonacoEditorModule.forRoot()
  ],
  declarations: [
    BarChartComponent,
    CircularChartComponent,
    ViewStatementComponent,
    ModalComponent,
    FilterPipe,
    SpinnerComponent,
    ToastComponent,
    DatediffPipe,
    ConfigUploadComponent,
    SelectSearchComponent,
    UnauthorizedComponent,
    CustomDropdownComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BarChartComponent,
    CircularChartComponent,
    AvatarModule,
    NgxPaginationModule,
    CodeEditorModule,
    ViewStatementComponent,
    ModalComponent,
    SpinnerComponent,
    ToastComponent,
    FilterPipe,
    DatediffPipe,
    CodeEditorModule,
    ConfigUploadComponent,
    SelectSearchComponent,
    CustomDropdownComponent,
  ],
})
export class SharedModule {}

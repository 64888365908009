<div class="modal-content app-content px-5">
  <div class="modal-header align-items-center mb-4">
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-primary link-btns mr-3" data-bs-toggle="modal" data-bs-target="#right" style="
          border-radius: 50% !important;
          color: #fff !important;
          background: #213a60 !important;
          border-color: #213a60;
        ">
        <i class="bi bi-pencil-fill"></i>
      </button>

      <h5 class="modal-title app-title">Go to live {{ app?.metadata.name }}</h5>
    </div>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <form [formGroup]="appForm" class="p-0 modal-body">
    <div class="row pt-1 pr-1">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="form-group d-flex flex-column pr-1">
          <label for="Strategy">Strategy</label>
          <div class="dropdown">
            <button class="dropbtn">
              <span style="font-size: 14px;">{{ selectedStrategy }}</span>
              <span><i class="bi bi-pencil-fill"></i></span>
            </button>

            <div class="dropdown-content">
              <a (click)="onStrategyChange(rec)" *ngFor="let rec of strategies">
                {{ rec.name }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div class="form-group pt-1">
          <label for="Replicats">Replicas</label>
          <input type="number" class="form-control form-control-sm" style="height: 3rem !important"
            formControlName="replicas" />
        </div>
      </div>

    </div>

    <div class="row pt-1 pr-1" *ngIf="['default'].includes(appForm.get('strategy').value)">
      <div [formGroup]="appForm.get('defaultSpecForm')">
        <div class="row p-1" style="align-items: baseline;">
          <div class="col-md-12 col-sm-12">
            <div class="table-responsive model-table">
              <table class="table">
                <thead>
                  <tr>
                    <!-- <th style="width: 20%"></th> -->
                    <th style="width: 50%">Live</th>
                    <th style="width: 50%">Version</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rec of app?.spec.strategySpec.live; let i = index">
                    <td style="width: 50%; padding-top: 1.3rem;">
                      {{ rec.name }}
                    </td>
                    <td style="width: 50%">
                      <select class="form-control form-control-sm select" [formControlName]="'live_' + i">
                        <option *ngFor="let ov of app?.status?.containerStatus[i]?.othersVersion"
                          (click)="onChangeStagingVersion(ov, i)" [value]="ov?.name">
                          {{ ov?.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="switch-on-off d-flex">
              <div class="text mb-5">Manual</div>
              <label class="switch">
                <input type="checkbox" (change)="onChangeAutoDeploy($event)"
                  [checked]="appForm.get('defaultSpecForm').get('autoDeploy').value" />
                <span class="slider round"></span>
              </label>
              <div class="text mb-5">Auto</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Version Block -->
    <div *ngIf="['canary', 'ab/testing', 'blue/green'].includes(appForm.get('strategy').value)">
      <div class="row no-gutters">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="table-responsive model-table">
            <table class="table">
              <thead>
                <tr>
                  <!-- <th style="width: 20%"></th> -->
                  <th style="width: 40%">Live</th>
                  <th style="width: 40%">Staging</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rec of app?.spec.strategySpec.live; let i = index">
                  <!-- <td style="width: 20%">
                    {{ getLiveContainer(i) }}
                  </td> -->
                  <td style="width: 40%">
                    {{ getLiveVersion(i) }}
                  </td>
                  <td style="width: 40%" class="td_staging p-0 no-wrap">
                    <div [formGroup]="appForm.get('stagingForm')">
                      <select [formControlName]="'staging_' + i">
                        <option [value]="ov?.name" *ngFor="
                            let ov of app?.status?.containerStatus[i]
                              ?.othersVersion
                          " (click)="onChangeStagingVersion(ov, i)">
                          {{ ov?.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <!-- <td></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Specific Canary Block -->
    <div *ngIf="['canary'].includes(appForm.get('strategy').value)">
      <div [formGroup]="appForm.get('canarySpecForm')">
        <div class="row no-gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="form-group d-flex flex-column load-balance">
              <label for="Strategy">Load balancer</label>

              <div class="d-flex flex-row">
                <div class="p-2 d-flex live-flex" style="width: 8rem;">
                  <span class="px-2 live">Live</span>
                  <span class="px-2">{{ displayLiveValue() }}%</span>
                </div>
                <div class="p-2 d-flex staging-flex" style="width: 9rem;">
                  <span class="px-2 staging">Staging</span>
                  <input type="number" formControlName="trafficSplitting" />
                  <strong style="padding-right: 5px;">%</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12"></div>
        </div>

        <section class="addition-set">
          <div class="row px-3 no-gutters bg">
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12"> -->
            <!-- <div class="form-group load-balance mb-0">
                <label for="Additionallysettings" class="add-seting pt-3 pl-1 mb-0">
                  Additionally settings
                </label>
              </div>
            </div> -->
            <div style="margin-top: 7px;">
              <div class="row">
                <div class="col-md-3">
                  <label class="form-check-label mb-0 pb-3" for="auto">
                    Auto progression
                  </label>
                </div>
                <div class="col-md-9">
                  <input formControlName="enabled" class="auto_progression" type="checkbox" id="auto"
                    value="option1" /><br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="form-check-label mb-0 pl-1 pb-4 mt-2">
                    Interval
                  </label>
                </div>
                <div class="col-md-9">
                  <input formControlName="interval" type="text" class="input_interval" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label class="form-check-label mb-0 pl-1 pb-3 mt-2" for="auto">
                    Type
                  </label>
                </div>
                <div class="col-md-9">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <select formControlName="type" class="input_type">
                        <option value="steps">Steps</option>
                        <option value="increment">Increment</option>
                      </select>
                    </div>
                    <div class="col-md-9">
                      <div *ngIf="
                          appForm.get('canarySpecForm').get('type').value ==
                          'increment'
                        ">
                        <input formControlName="increment" type="number" class="input_interval" />
                      </div>
                      <div class="wrapper_step" *ngIf="
                          appForm.get('canarySpecForm').get('type').value ==
                          'steps'
                        ">
                        <div class="step_box">
                          <span class="step" *ngFor="let step of steps; let k = index">
                            {{ step }}
                            <i class="fa fa-times" (click)="onDeleteStep(k)"></i>
                          </span>
                        </div>
                        <input type="number" [formControl]="stepControl"
                          [class]="{ invalid_input: stepControl.invalid }" />
                        <button class="btn_step">
                          <i class="fa fa-plus" (click)="onAddStep()"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <label class="form-check-label mb-0 pl-1 pb-3 mt-2" for="auto">
                    Success rate
                  </label>
                </div>
                <div class="col-md-9">
                  <div class="row no-gutters success_rate">
                    <div class="col-md-2 box_text">Min</div>
                    <div class="col-md-3">
                      <input type="number" formControlName="rateMin" />
                    </div>
                    <div class="col-md-2 box_text">Max</div>
                    <div class="col-md-3">
                      <input type="number" formControlName="rateMax" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-3">
                  <label class="form-check-label mb-0 pl-1 pb-3 mt-2" for="auto">
                    Latency
                  </label>
                </div>
                <div class="col-md-9">
                  <div class="row no-gutters success_rate">
                    <div class="col-md-2 box_text">Min</div>
                    <div class="col-md-3">
                      <input type="number" formControlName="latencyMin" />
                    </div>
                    <div class="col-md-2 box_text">Max</div>
                    <div class="col-md-3">
                      <input type="number" formControlName="latencyMax" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Specific Ab/Testing Block -->
    <div *ngIf="['ab/testing'].includes(appForm.get('strategy').value)" class="abtesting_spec">
        <label for="" style="font-weight: normal;color: #517bbd; font-size: 15px;">Headers</label>
        <div class="__variables" [formGroup]="headersFormArray">
          <div class="col-md-12 wrapper-env" *ngIf="headersFormArray.controls.length > 0">
            <div class="row mb-2 pl-3" *ngFor="let header of headersFormArray.controls; let i = index" [formGroupName]="i">
              <!-- Key block -->
              <div class="col-md-4">
                <div class="form-group">
                  <input type="text" formControlName="key" class="form-control" placeholder="Key" />
                  <div class="text-right d-flex justify-content-between mt-1">
                    <small *ngIf="header.controls.key.invalid && (header.controls.key.touched || formError)"
                      class="invalid-text">Invalid field</small>
                    <small *ngIf="header.controls.key.hasError('duplicate') && (header.controls.key.touched || formError)"
                      class="invalid-text">Duplicate key</small>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" formControlName="value" class="form-control" placeholder="Value" />
                  <div class="text-right d-flex justify-content-between mt-1">
                    <small *ngIf="header.controls.value.invalid && (header.controls.value.touched || formError)"
                      class="invalid-text">Invalid field</small>
                    <small *ngIf="header.controls.value.hasError('duplicate') && (header.controls.value.touched || formError)"
                      class="invalid-text">Duplicate key</small>
                  </div>
                </div>
              </div>
              <div class="col-md-1 pt-2">
                <button (click)="onRemoveFormGroup('header', i)" class="btn_delete">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn_add_style" (click)="onAddFormGroup('header')" [disabled]="headersFormArray.invalid">
          <i class="fa fa-plus"></i> &nbsp;Header
        </button>
    </div>
    <!-- end specific ab/testing block -->
    <!-- Environment variables  Block -->

    <div class="accordion mt-4" id="accordiEnvVarnvVars" *ngIf="['canary', 'ab/testing','default'].includes(appForm.get('strategy').value)">
      <div class="card p-0">
        <div class="card-header" id="headingEnvVar">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
              data-target="#collapseEnvVar" aria-controls="collapseEnvVar"
              style="text-decoration: none;" (click)="onCollapsed()">
              {{ ['canary', 'ab/testing'].includes(appForm.get('strategy').value) ? 'Staging' : '' }} Environments Variables
              <i style="font-size: 20px;" class="fa fa-angle-down" *ngIf="!collapsed"></i>
              <i style="font-size: 20px;" class="fa fa-angle-up" *ngIf="collapsed"></i>
            </button>
          </h2>
        </div>
        <div id="collapseEnvVar" class="collapse" aria-labelledby="headingEnvVar" data-parent="#accordiEnvVarnvVars">
          <div class="card-body p-0 px-3" *ngIf="isManagedByDoor()">
            <ul style="font-size: 14px; line-height: 15px;" class="mt-2">
              <li>Delete spaces at beginning and end of key</li>
              <li>Delete spaces in key</li>
              <li>Remove special characters from key</li>
            </ul>
            <div class="__enVars">
              <div class="__variables" [formGroup]="envFormArray">
                <div class="col-md-12 wrapper-env" *ngIf="envFormArray.controls.length > 0">
                  <div class="row mb-2 pl-3" *ngFor="let envVar of envFormArray.controls; let i = index" [formGroupName]="i">
                    <!-- Key block -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <input type="text" formControlName="key" class="form-control" placeholder="Key" />
                        <div class="text-right d-flex justify-content-between mt-1">
                          <small *ngIf="envVar.controls.key.invalid && (envVar.controls.key.touched || formError)"
                            class="invalid-text">Invalid field</small>
                          <small *ngIf="envVar.controls.key.hasError('duplicate') && (envVar.controls.key.touched || formError)"
                            class="invalid-text">Duplicate key</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input type="text" formControlName="value" class="form-control" placeholder="Value" />
                        <div class="text-right d-flex justify-content-between mt-1">
                          <small *ngIf="envVar.controls.value.invalid && (envVar.controls.value.touched || formError)"
                            class="invalid-text">Invalid field</small>
                          <small *ngIf="envVar.controls.value.hasError('duplicate') && (envVar.controls.value.touched || formError)"
                            class="invalid-text">Duplicate key</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 pt-2">
                      <button (click)="onRemoveFormGroup('env', i)" class="btn_delete">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn_add_style" (click)="onAddFormGroup('env')" [disabled]="envFormArray.invalid">
                <i class="fa fa-plus"></i> &nbsp;Env Var
              </button>
              <!-- <config-upload (envVars)="loadEnvs($event)"></config-upload> -->
            </div>
          </div>
          <div class="card-body p-0 p-3" *ngIf="!isManagedByDoor()">
            <small style="font-size: 15px; color: #fd8484a6;">Management of environment variables is not yet supported for this application.</small>
          </div>
        </div>
      </div>
    </div>

    <!-- Environment variables  Block -->

    <div class="row py-3 pt-4">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="form-group d-flex justify-content-center load-balance">
          <div style="margin-left: auto;display: flex;">
            <button type="button" class="btn btn-primary update-btn" (click)="onUpdate()" [disabled]="loading || appForm.invalid || envFormArray.invalid || headersFormArray.invalid">
              Update
              <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
                *ngIf="loading">
                <span class="sr-only">Loading...</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Environment } from 'src/app/data/model/environment';
import { Project } from 'src/app/data/model/project';
import { Workload } from 'src/app/data/model/workload';
import { CdService } from '../../../services/cd.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { Position, RESOURCES, Type } from 'src/app/utils/const';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { ModalMultiDeployComponent } from './modal-multi-deploy/modal-multi-deploy.component';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  @ViewChild('template', { read: TemplateRef }) modalResult: TemplateRef<any>;

  applications: Workload[] = [];
  projects: Project[] = [];
  environments: Environment[] = [];
  workloadsSelected: Workload[] = [];
  multiUpdate: boolean = false;
  loading: boolean = false;
  currProject: Project;
  currEnv: Environment;
  currentStrategy = '';
  bsModalRef: BsModalRef;

  hasNotAccess: boolean = false;

  resultsApps = [];
  modalRef: BsModalRef;

  resources = RESOURCES;

  query: any = {};

  namespaces = [
    'door-apigateway',
    'door-apigateway-internal',
    'door-ci',
    'door-monitoring',
    'door-system',
  ];

  constructor(
    private modalService: BsModalService,
    private cdService: CdService,
    public auth: AppAuthGuardService,
    private router: Router,
    private route: ActivatedRoute,
    private service: AsideExtenderService
  ) {}

  async ngOnInit(): Promise<void> {
    if (localStorage.getItem('query')) {
      const query = JSON.parse(localStorage.getItem('query'));

      const isEmpty = (object: any) => {
        return Object.values(object).every(
          (value) => value === null || value === undefined || value === ''
        );
      };

      if (!isEmpty(query)) {
        this.query = query;
      }
    }

    this.loading = true;
    await this.getProjectByOrganisation();

    this.makeQuery();
  }

  onSearch(event: any) {
    const value = event.target.value;

    if (value == '') {
      this.query['search'] = '';
      this.makeQuery();
      return;
    }

    if (value.length >= 2) {
      this.query['search'] = value;
      this.makeQuery();
    }
  }

  displayDateForHuman(date: any) {
    let parseToMoment = moment(date);
    return parseToMoment.fromNow();
  }

  getAllWorkloads(queryString: string): void {
    this.cdService.getWorkloads(queryString)?.subscribe(
      (resp: any) => {
        this.applications = resp.records;
        this.loading = false;
        localStorage.setItem('query', JSON.stringify(this.query));
      },
      (error) => {
        this.loading = false;
        if (error.status === 403) {
          this.hasNotAccess = true;
        } else {
          this.service.show({
            title: 'Get All Workloads',
            message: error?.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
        }
      }
    );
  }

  getProjectByOrganisation() {
    return new Promise((resolve, reject) => {
      this.cdService.getProjects()?.subscribe(
        (resp: any) => {
          this.projects = resp.records;
          if (this.query?.projectId) {
            const project = this.projects.find(
              (p) => p.id == this.query?.projectId
            );
            if (project) {
              this.environments = project.environments;
            }
          }
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getVersion(version: string): string {
    return version.split('-')[0];
  }

  getClusterTypeImage(
    clusterType: 'gke' | 'eks' | 'aks' | 'digitalocean' | 'openshift'
  ): string {
    switch (clusterType) {
      case 'eks':
        return '/assets/img/aws-white.png';

      case 'aks':
        return '/assets/img/azure-icon.png';

      case 'gke':
        return '/assets/img/google-provider.png';

      case 'digitalocean':
        return '/assets/img/digital-ocean.svg';

      case 'openshift':
        return '/assets/img/openshift.png';
      default:
        return '/assets/img/google-provider.png';
    }
  }

  getClusterImageColor(app: Workload) {
    const successRate = 90;

    if (['canary', 'ab/testing'].includes(app?.spec?.deploymentStrategy)) {
      if (successRate > 0 && successRate <= 50) {
        return 'bg-litred';
      } else if (successRate > 50 && successRate <= 75) {
        return 'bg-yellow';
      } else 'bg-darkblue';
    }

    return 'bg-darkblue';
  }

  onViewAppDetail(app: Workload): void {
    this.router.navigate(['/deliver/' + app?.metadata?.uid], {
      queryParams: {
        proj: app?.projectId,
        env: app?.environmentId,
      },
    });
  }

  onProjectSelect(project: Project): void {
    if (project) {
      this.query['projectId'] = project.id;
      this.environments = project.environments;
    } else {
      this.query['projectId'] = '';
      this.query['environmentId'] = '';
      this.query['strategy'] = '';
      this.query['search'] = '';
      this.environments = [];
    }

    this.makeQuery();
  }

  onEnvSelect(env: Environment): void {
    if (env) {
      this.query['environmentId'] = env.id;
    } else {
      this.query['environmentId'] = '';
      this.query['strategy'] = '';
      this.query['search'] = '';
    }

    this.makeQuery();
  }

  onChangeStrategy(strategy: string) {
    if (strategy) {
      this.query['strategy'] = strategy;
    } else {
      this.query['strategy'] = '';
      this.query['search'] = '';
    }
    this.makeQuery();
  }

  makeQuery() {
    let queryString = '';
    for (let key in this.query) {
      if (this.query.hasOwnProperty(key)) {
        if (
          (this.query[key] != '' && this.query[key] != null) ||
          this.query[key] != undefined
        ) {
          if (queryString == '') {
            queryString = `${key}=${this.query[key]}`;
          } else {
            queryString += `&${key}=${this.query[key]}`;
          }
        }
      }
    }

    this.loading = true;
    this.getAllWorkloads(queryString);
  }

  parseValueDailyFlow(app: any) {
    return parseInt(app?.metrics?.live?.flow['day']?.split(',')[0]);
  }

  parseValueSuccessRate(app: any) {
    return parseInt(app?.metrics?.live?.succesRate['5m']?.split(',')[0]);
  }
  isInfo(app: any) {
    let val = app?.metrics?.live?.succesRate['5m']?.split(',')[0];
    if (val >= 90) {
      return true;
    } else {
      return false;
    }
  }
  isWarning(app: any) {
    let val = app?.metrics?.live?.succesRate['5m']?.split(',')[0];
    if (val < 90 && val >= 75) {
      return true;
    } else {
      return false;
    }
  }
  isDanger(app: any) {
    let val = app?.metrics?.live?.succesRate['5m']?.split(',')[0];
    if (val < 75) {
      return true;
    } else {
      return false;
    }
  }

  async onSelectApp(e: any, app: any) {
    let isChecked = e.target.checked;

    if (isChecked) {
      if (
        app?.isProd &&
        this.auth.hasProjectRoles(
          this.resources.project.role.developer,
          app.projectId
        )
      ) {
        e.target.checked = false;
        this.service.show({
          title: 'Permission denied',
          message:
            'This profile is not authorized to deploy in this environment',
          type: Type.ERROR,
          position: Position.TOP,
        });
      } else {
        this.workloadsSelected.push(app);
      }
    } else {
      let index = this.workloadsSelected.findIndex(
        (w: any) => w.metadata.uid == app.metadata.uid
      );
      if (index !== -1) {
        this.workloadsSelected.splice(index, 1);
      }
    }
  }

  onSelectAllWorkloads(e: any) {
    this.changeSelectedWorkloads(e.target.checked);
  }

  changeSelectedWorkloads(checked: boolean) {
    this.workloadsSelected = [];
    let self = this;
    if (checked) {
      $('.w-checkbox').each(function (index, element) {
        $(element).prop('checked', true);
        let workload = $(element).data('app') as Workload;
        self.workloadsSelected.push(workload);
      });
    } else {
      $('.w-checkbox').each(function (index, element) {
        $(element).prop('checked', false);
      });

      $('#all').prop('checked', false);
    }
  }

  parseData(app) {
    return JSON.stringify(app);
  }

  onMultiUpdate(): void {
    const config: any = {
      class: 'popup-right-side-xl',
      initialState: {
        apps: this.workloadsSelected,
      },
    };

    this.bsModalRef = this.modalService.show(ModalMultiDeployComponent, config);

    this.bsModalRef.content.event.subscribe((res) => {
      this.resultsApps = res.apps;
      this.changeSelectedWorkloads(false);
      this.makeQuery();
      this.onOpenResultModal(this.modalResult);
    });
  }

  onOpenResultModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      backdrop: 'static',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onCloseDialog2(): void {
    this.modalRef.hide();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/base-http.service';
import { CloudProvider } from 'src/app/data/model/cloud-provider';
import { GetRecordsModel } from 'src/app/data/model/GetRecordsModel';
import { ShareService } from 'src/app/data/service/share.service';

@Injectable({
  providedIn: 'root'
})
export class CloudProviderService extends BaseHttpService<CloudProvider> {

  baseUrl = this.shareService.apiBaseURL + '/api/v1/organizations/';


  constructor(http: HttpClient,sharedService: ShareService) {
    super(http,'cloudprovider',sharedService);
  }


  findAll(): Observable<GetRecordsModel<CloudProvider>> {
    return this.http.get<GetRecordsModel<CloudProvider>>(`${this.baseUrl}`+`${this.shareService.organizationSelected.id}`+`/cloudproviders?page=${ this.defaultPage }'&limit='${this.maxItems}`);
  }


  getTypeClouds(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}` + `${this.shareService.organizationSelected.id}`+ '/cloudprovider/supported');
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetRecordsModel } from './data/model/GetRecordsModel';
import { ShareService } from './data/service/share.service';



export abstract class BaseHttpService<T>{

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  protected baseURL: string;
  protected maxItems = 5;
  protected defaultPage = 0;


  constructor(protected http: HttpClient, protected endpoint: string,  protected shareService: ShareService) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }

  create(form: T): Observable<T> {
    return this.http.post<T>(`${this.baseURL}`+`${this.shareService.organizationSelected.id}`+ `/${this.endpoint}/`, form, this.httpOptions);
  }

  update(form: T, id: string): Observable<object> {
    return this.http.put<any>(`${this.baseURL}`+`${this.shareService.organizationSelected.id}`+ `/${this.endpoint}`+`/${id}`,form, this.httpOptions);
  }

  getAll(): Observable<GetRecordsModel<T>> {
    return this.http.get<GetRecordsModel<T>>(`${this.baseURL}`+`${this.shareService.organizationSelected.id}`+ `/${this.endpoint}`+`/?page=${ this.defaultPage }'&limit='${this.maxItems}`);
  }

  getOne(id: string): Observable<T> {
    return this.http.get<T>(`${this.baseURL}`+`${this.shareService.organizationSelected.id}`+ `/${this.endpoint}`+`/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseURL}`+`${this.shareService.organizationSelected.id}`+ `/${this.endpoint}`+`/${id}`);
  }

}

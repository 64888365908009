import { Component, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AppService } from '../app.service';
import { ShareService } from 'src/app/data/service/share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { BuildConfig } from 'src/app/data/model/buildConfig';
import { Position, Type } from 'src/app/utils/const';
import { Pipeline, Registry } from 'src/app/utils/types';
import { decodeToBase64, encodeToBase64 } from 'src/app/utils/helpers';

@Component({
  selector: 'app-build-config',
  templateUrl: './build-config.component.html',
  styleUrls: ['./build-config.component.scss'],
})
export class BuildConfigComponent implements OnInit {
  openForm: UntypedFormGroup;
  otherBranchs = [];
  loading: boolean = false;
  globalLoader: boolean = false;
  organizationId: string = '';
  pipelineId: string = '';
  projectId: string = '';
  buildConfInitValue: any;
  image: string = '';
  repositoryUrl: any;
  pipelineData: Pipeline;
  registries: Registry[];
  registryUrl: string;

  constructor(
    public modalRef: BsModalRef,
    private appService: AppService,
    private shareService: ShareService,
    private route: ActivatedRoute,
    private service: AsideExtenderService,
    public context: ModalOptions,
    private fb: FormBuilder
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.buildConfInitValue = this.context.initialState;
    this.repositoryUrl = this.context.initialState;
    this.organizationId = this.shareService.organizationSelected.id;
    this.route.queryParams.subscribe((params) => {
      this.pipelineId = params['id'];
      this.projectId = params['projectId'];
    });
    this.getRegistry(this.projectId, this.pipelineId);
    this.initForm(this.buildConfInitValue?.data);
  }

  createForm() {
    this.openForm = new UntypedFormGroup({
      name: new FormControl('', Validators.required),
      branch: new FormControl(''),
      imageName: new FormControl('', Validators.required),
      repoUrl: new FormControl(''),
      scanSeverity: new FormControl('', Validators.required),
      otherArgs: new FormGroup({}),
      dockerfilePath: new FormControl('/Dockerfile', Validators.required),
      buildArgs: this.fb.array([], this.noDuplicateKeys),
    });
  }

  initForm(data = null) {
    this.createForm();

    if (this.repositoryUrl?.repositoryUrl) {
      this.openForm.patchValue({
        repoUrl: this.repositoryUrl?.repositoryUrl,
      });
    }



    if (data) {

      if (data?.branchs !== '') {
        this.otherBranchs = data?.branchs?.split(',');
      }
      const imageName = data?.imageName.split('/');
      const uri = imageName[0];
      const image = data?.imageName.replace(uri+'/', '');

      this.openForm.patchValue({
        name: data?.name,
        branch: '',
        repoUrl: uri,
        imageName: image,
        scanSeverity: this.severetyLevels.find(
          (item) => item.value === data?.scanSeverity
        ),
        dockerfilePath: data?.dockerfilePath,
      });
      if (data?.buildArgs !== '') {
        let decodeString = decodeToBase64(data?.buildArgs)
        const buildConfig =  decodeString.split(',').map((pair) => {
          const [key, value] = pair.split('=');
          return { key, value };
        });
        buildConfig?.forEach((element) => {
          this.onAddBuildArg(element.key, element.value);
        });
      }

      this.openForm.get('imageName').disable();
    }
  }

  get buildArgs() {
    return this.openForm.get('buildArgs') as FormArray;
  }

  getPipelineById(projectID: string, pipelineID: string){
    this.appService.getPipelineById(projectID, pipelineID).subscribe((response : Pipeline) => {
        this.pipelineData = response
        this.registryUrl = this.registries.filter((r) => r.id == this.pipelineData.registryId)[0].repo;
        this.openForm.patchValue({
          repoUrl: this.openForm.get('repoUrl').value ? this.openForm.get('repoUrl').value : this.registryUrl,
        });
        this.globalLoader = true;
        // this.webhookUrl = this.appService.getWebhookUrl(this.projectID,response?.id)
      })
  }

  getRegistry(projectId: string,pipelineId: string) {
    this.appService.getRegistries().subscribe(
      (_: Registry[]) => {
        this.registries =_;
        this.getPipelineById(projectId,pipelineId);
      });
  }

  createBuildArg(key: string, value: string): FormGroup {
    return this.fb.group({
      key: [
        key,
        [Validators.required, Validators.pattern(/^[A-Za-z_][A-Za-z0-9_]*$/)],
      ],
      value: [value, [Validators.required]],
    });
  }

  onAddBuildArg(key: string, value: string) {
    this.buildArgs.push(this.createBuildArg(key, value));
  }

  onRemoveBuildArg(index: number) {
    this.buildArgs.removeAt(index);
    this.openForm.get('buildArgs').updateValueAndValidity();
    this.openForm.updateValueAndValidity();
  }

  noDuplicateKeys(formArray: FormArray) {
    const keyValues = formArray.controls.map(
      (control) => control.get('key').value
    );
    const keyOccurrences = keyValues.reduce((acc, key) => {
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    formArray.controls.forEach((control, index) => {
      const keyControl = control.get('key');
      if (keyOccurrences[keyControl.value] > 1) {
        keyControl.setErrors({ duplicate: true });
      } else {
        if (keyControl.hasError('duplicate')) {
          delete keyControl.errors['duplicate'];
          if (!Object.keys(keyControl.errors).length) {
            keyControl.setErrors(null);
          }
        }
      }
    });

    return keyValues.some((key) => keyOccurrences[key] > 1)
      ? { duplicateKeys: true }
      : null;
  }

  severetyLevels = [
    { name: 'None', value: '' },
    { name: 'Critical', value: 'CRITICAL' },
    { name: 'High', value: 'HIGHT' },
    { name: 'Medium', value: 'MEDIUM' },
    { name: 'Low', value: 'LOW' },
  ];
  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onSubmit() {
    this.loading = true;
    let buildArgTab = this.buildArgs.value;
    const concatenatedString = buildArgTab
      .map((item: any) => `${item.key}=${item.value}`)
      .join(',');
    let newConcatenatedString = encodeToBase64(concatenatedString)
    let path = this.openForm.value.dockerfilePath ? this.openForm.value.dockerfilePath : '/Dockerfile'

    this.openForm.get('imageName').enable();
    let dataToSend = {
      name: this.openForm.value.name,
      branchs: this.otherBranchs?.join(','),
      imageName: this.openForm.value.imageName,
      organizationId: this.shareService.organizationSelected.id,
      scanSeverity: this.openForm.value.scanSeverity.value,
      projectID: this.projectId,
      pipelineId: this.pipelineId,
      buildArgs: newConcatenatedString,
      dockerfilePath: path,
    } as BuildConfig;

    if (this.otherBranchs.length === 0) {
      this.service.show({
        title: 'Create Configuration',
        message: 'Add a branch or tag.',
        type: Type.ERROR,
        position: Position.TOP,
      });
      this.loading = false;
    } else {
      if (this.buildConfInitValue?.data) {
        this.appService
          .updateBuildConfig(
            this.projectId,
            this.pipelineId,
            dataToSend,
            this.buildConfInitValue.data.id
          )
          .subscribe(
            (response: BuildConfig) => {
              this.service.show({
                title: 'Update Configuration',
                message: 'Configuration updated successfully',
                type: Type.SUCCESS,
                position: Position.TOP,
              });
              this.loading = false;

              this.onCloseDialog();
              this.reloadPage();
            },
            (error) => {
              this.service.show({
                title: 'Update Configuration',
                message: error.error,
                type: Type.ERROR,
                position: Position.TOP,
              });
              this.loading = false;
            }
          );
      } else {
        this.appService
          .createBuildConfig(this.projectId, this.pipelineId, dataToSend)
          .subscribe(
            (response: BuildConfig) => {
              this.service.show({
                title: 'Create Configuration',
                message: 'Configuration created successfully',
                type: Type.SUCCESS,
                position: Position.TOP,
              });
              this.loading = false;

              this.onCloseDialog();
              this.reloadPage();
            },
            (error) => {
              console.log(
                '🚀 ~ BuildConfigComponent ~ this.appService.createBuildConfig ~ error:',
                error
              );
              this.service.show({
                title: 'Create Configuration',
                message: error.error,
                type: Type.ERROR,
                position: Position.TOP,
              });
              this.loading = false;
            }
          );
      }

    }
  }
  reloadPage() {
    this.loading = false;
    window.location.reload();
  }
  pushOtherBranch() {
    if (this.openForm.value.branch !== '') {
      this.otherBranchs.push(this.openForm.value.branch);
      this.openForm.patchValue({
        branch: '',
      });
    }
  }

  onDeleteOtherBranch(index: number) {
    if (index < 0 || index >= this.otherBranchs.length) {
      return;
    }
    this.otherBranchs.splice(index, 1);
  }

  loadEnvs(event: any) {
    event.forEach((m: any) => {
      let key = m[0];
      if (m[0][0] == `"` || m[0][0] == `'`) {
        key = m[0].slice(1)
        key = key.slice(0,[key.length - 1])
      }
      let value = m[1];
      if (m[1][0] == `"` || m[1][0] == `'`) {
        value = m[1].slice(1)
        value = value.slice(0,[value.length - 1])
      }
      this.onAddBuildArg(key, value);
    });
  }
}

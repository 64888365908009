import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Workload } from 'src/app/data/model/workload';
import { CdService } from '../../../../../services/cd.service';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';

@Component({
  selector: 'app-modal-switch',
  templateUrl: './modal-switch.component.html',
  styleUrls: ['./modal-switch.component.scss'],
})
export class ModalSwitchComponent implements OnInit {
  app: Workload;
  public event: EventEmitter<any> = new EventEmitter();
  loading = false;
  isToggled = false;
  oldTrafficSplitting: number;
  oldStagingContainer: any;
  oldLiveContainer: any;

  constructor(
    private modalRef: BsModalRef,
    private cdService: CdService,
    private service: AsideExtenderService
  ) {}

  ngOnInit(): void {
    if (['canary'].includes(this.app.spec.deploymentStrategy)) {
      this.oldTrafficSplitting = this.app.spec.strategySpec['trafficSplitting'];
    }
    this.oldStagingContainer = this.app.spec.strategySpec.staging;
    this.oldLiveContainer = this.app.spec.strategySpec.live;
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  getVersion(version: string): string {
    return version;
  }

  truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }

  onToggleChange(ev: any): void {
    this.isToggled = ev.target.checked;

    if (this.isToggled) {
      this.app.spec.strategySpec.staging = this.oldLiveContainer;
      this.app.spec.strategySpec.live = this.oldStagingContainer;

      if (['canary'].includes(this.app.spec.deploymentStrategy)) {
        this.app.spec.strategySpec['trafficSplitting'] = 0;
      }
    } else {
      this.app.spec.strategySpec.staging = this.oldStagingContainer;
      this.app.spec.strategySpec.live = this.oldLiveContainer;
      this.app.spec.strategySpec['trafficSplitting'] = this.oldTrafficSplitting;
    }
  }

  onConfirm(): void {
    // if (this.isToggled) {
    //   let payload = {
    //     deploymentStrategy: this.app.spec.deploymentStrategy,
    //   };

    //   if (['blue/green'].includes(this.app.spec.deploymentStrategy)) {
    //     payload['bascule'] =
    //       this.app.spec.strategySpec.bascule == 'blue' ? 'green' : 'blue';
    //   } else {
    //     payload['liveContainers'] = this.app.spec.strategySpec.live;
    //     payload['stagingContainers'] = this.app.spec.strategySpec.staging;

    //     if (['canary'].includes(this.app.spec.deploymentStrategy)) {
    //       let autoProgression = this.app.spec.strategySpec.autoProgression;
    //       autoProgression.enabled = false;
    //       payload['trafficSplitting'] = 0;
    //       payload['autoProgression'] = autoProgression;
    //     }
    // }

    const ids = {
      projectID: this.app.projectId,
      envID: this.app.environmentId,
      workloadID: this.app.metadata.uid,
    };

      this.loading = true;
      this.cdService.switchWorkload(ids).subscribe(
        (response) => {
          let switched = this.isToggled ? 'live' : 'staging';
          this.onCloseDialog();
          this.service.show({
            title: 'Workload',
            message: `${this.app?.metadata?.name} has been switched to ${switched}`,
            type: Type.SUCCESS,
            position: Position.TOP,
          });
          this.loading = false;
          this.event.emit({ appId: response, res: 100 });
        },
        (error) => {
          this.service.show({
            title: 'Workload',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.loading = false;
        }
      );
  }
}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShareService } from 'src/app/data/service/share.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pipeline } from 'src/app/utils/types';
import { GitService } from 'src/app/shared/services/git.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  baseURL: string;

  constructor(
    private shareService: ShareService,
    private httpClient: HttpClient,
    private gitService: GitService,

    ) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }

  getGithubUserToken(requestData: any) {
    const url = this.baseURL + this.shareService.organizationSelected.id + "/githubtoken"
    const body = {
      clientId: requestData.client_id,
      clientSecret: requestData.client_secret,
      code: requestData.code,
    };
    return this.httpClient.post(url, body)

  }


  deletePipeline(pipeline: Pipeline): Observable<object> {
    return this.httpClient.request('delete', this.baseURL + this.shareService.organizationSelected.id + "/projects/" + pipeline.projectId + '/pipelines/' + pipeline.id);
  }

  getOrganisationPipelines() {
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/pipelines")

  }
  getApplicationById(appId: string, projectId: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + '/pipelines/' + appId)
  }
  getVariablesEnvironment(appID: string, projectId: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + "/pipelines/" + appID + "/environmentvariables")
  }

  updateVariablesEnvironment(appID: string, body: any[], projectId: string){
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + "/pipelines/" + appID + "/environmentvariables"
    return this.httpClient.put(url, body)
  }
  createVariablesEnvironment(appID: string, body: any[], projectId: string){
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + "/pipelines/" + appID + "/environmentvariables"
    return this.httpClient.post(url, body)
  }
  createDeploymentInEnvironment(envID: string, projectID: string, body: any){
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/environments/" + envID + "/apps"
    return this.httpClient.post(url, body)
  }

  updateDeploymentInEnvironment(appID: string, envID: string, projectID: string, body: any){
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/environments/" + envID + "/apps/" + appID
    return this.httpClient.put(url, body)
  }

  getDeploymentInEnvironment(appID: string, envID: string, projectID: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/environments/" + envID + "/code/" + appID + "/apps")

  }
  getEnvironmentByApplication(projectID: string) {
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/environments?limit=100")

  }
  getGitlabUserToken(requestData: any, org: string) {
    const body = {
      client_id: requestData.client_id,
      client_secret: requestData.client_secret,
      code: requestData.code,
      grant_type: requestData.grant_type,
      redirect_uri: environment.gitProviderRedirectURL +"?provider=gitlab%26currentOrg=" + org
    };
    const url = `https://gitlab.com/oauth/token?client_id=${body.client_id}&client_secret=${body.client_secret}&code=${body.code}&grant_type=${body.grant_type}&redirect_uri=${body.redirect_uri}`;
    return this.httpClient.post(url, body)

  }

  getBitbucketUserToken(requestData: any, org: string) {

    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('code', requestData.code);
    body.set('redirect_uri', environment.gitProviderRedirectURL + "?provider=bitbucket&currentOrg=" + org);
    return this.httpClient.post('https://bitbucket.org/site/oauth2/access_token',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set("Authorization", 'Basic ' + btoa(environment.bitbucketClientID+":"+environment.bitbucketClientSecret))
      }
    )

  }


  createPipeline(projectID: string,body: any) {
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/"
    return this.httpClient.post(url, body)

  }
  runPipeline(projectID: string, pipelineID: string, body: any) {
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/run"
    return this.httpClient.post(url, body)
  }
  rerunPipeline(projectID: string, pipelineRunID: string) {
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelineruns/" + pipelineRunID + "/rerun"
    return this.httpClient.post(url, {})
  }

  updatePipeline(pipelineID: string,projectID: string,body: any) {
    let url = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID
    return this.httpClient.put(url, body)
  }
  getBuildconfigPipeline(projectID: string, pipelineID: string) {
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/buildconfigs")
  }
  getPipelineById(projectID: string, pipelineID: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID)
  }
  createWebhookForGithub(token: string,projectID: string,pipelineID: string, webhookSecret: string,providerName: string, repository: string,orgName: string){
    let webhookUrl = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/providers/" + providerName + "/webhooks"
    console.log(repository, orgName)
    return this.gitService.createGithubWebhooks(token,webhookUrl,webhookSecret, repository,orgName)
  }
  createDeploymentKeyForGithub(token: string, repository: string,sshKey: string,orgName: string){
    return this.gitService.createGithubDeploymentKey(token,repository,sshKey,orgName)
  }
  createWebhookForGitlab(token: string,projectID: string,repoID: string,pipelineID: string,webhookSecret: string,providerName: string){
    let webhookUrl = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/providers/" + providerName + "/webhooks"
    return this.gitService.createGitlabWebhooks(token,webhookUrl,webhookSecret,repoID)
  }
  createDeploymentKeyForGitlab(token: string,sshKey: string, repoID: string){
    return this.gitService.createGitlabDeploymentKey(token,sshKey,repoID)
  }
  createWebhookForBitbucket(token: string,projectID: string,pipelineID: string,providerName: string,workspace: string){
    let webhookUrl = this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/providers/" + providerName + "/webhooks"
    return this.gitService.createBitbucketWebhooks(token,webhookUrl,workspace)
  }
  createDeploymentKeyForBitbucket(token: string,workspace: string,repo_slug: string,sshKey: string){
    return this.gitService.createBitbucketDeploymentKey(token,workspace,repo_slug,sshKey)
  }
  getPipelineRun(pipelineID: string,projectId: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + "/pipelines/"+ pipelineID + "/pipelineruns")

  }
  getPipelineRunById(pipelineId: string,projectId: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + "/pipelineruns/" + pipelineId)

  }
  getDeployApplication(appId: string,projectId: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectId + "/code/" + appId + "/apps")
  }
  getRegistries(){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/registries")
  }
  createRegistry(body: any){
    let url = this.baseURL + this.shareService.organizationSelected.id + "/registries"
    return this.httpClient.post(url, body)
  }
  getRegistryById(registryID: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/registries/" + registryID)

  }
  updateRegistry(body: any, registryID: string) {
    let url = this.baseURL + this.shareService.organizationSelected.id + "/registries/" + registryID
    return this.httpClient.put(url, body)
  }
  deleteRegistry(registryID: string){
    return this.httpClient.delete(this.baseURL + this.shareService.organizationSelected.id + "/registries/" + registryID)
  }
  getDomains(){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/domains")
  }
  createDomain(body: any){
    let url = this.baseURL + this.shareService.organizationSelected.id + "/domains"
    return this.httpClient.post(url, body)

  }
  getDomainById(domainID: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/domains/" + domainID)

  }

  updateDomain(body: any, domainID: string) {
    let url = this.baseURL + this.shareService.organizationSelected.id + "/domains/" + domainID
    return this.httpClient.put(url, body)
  }

  deleteDomain(domainID: string){
    return this.httpClient.delete(this.baseURL + this.shareService.organizationSelected.id + "/domains/" + domainID)
  }

  deleteDeploAppliation(envID: string, appID: string, projectID: string){
    return this.httpClient.delete(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/environments/" + envID + "/apps/" + appID)

  }
  getWebhookUrl(projectID, pipelineID, provider){
    return `${this.baseURL + this.shareService.organizationSelected.id }/projects/${projectID}/pipelines/${pipelineID}/providers/${provider}/webhooks`
  }
  createBuildConfig(projectID: string, pipelineID: string, body : any){
    let url = `${this.baseURL + this.shareService.organizationSelected.id }/projects/${projectID}/pipelines/${pipelineID}/buildconfigs`
    return this.httpClient.post(url, body)
  }
  updateBuildConfig(projectID: string, pipelineID: string, body : any, id: string){
    let url = `${this.baseURL + this.shareService.organizationSelected.id }/projects/${projectID}/pipelines/${pipelineID}/buildconfigs/${id}`
    return this.httpClient.put(url, body)
  }
  deleteBuildConfig(projectID: string, pipelineID: string,id: string){
    return this.httpClient.delete(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/buildconfigs/" + id)
  }
  getBuildConfigById(projectID: string, pipelineID: string,id: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/buildconfigs/" + id)
  }
  getAllBuildConfig(projectID: string, pipelineID: string){
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + projectID + "/pipelines/" + pipelineID + "/buildconfigs")
  }

  getDomainsByEnv(envID: string) {
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + "/environments/"+ envID +"/domains");
  }

}

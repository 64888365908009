import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Workload, StringMap } from 'src/app/data/model/workload';
import { CdService } from '../../../../../services/cd.service';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
declare var $: any;

@Component({
  selector: 'app-modal-update',
  templateUrl: './modal-update.component.html',
  styleUrls: ['./modal-update.component.scss'],
})
export class ModalUpdateComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  app: Workload;
  strategies = [
    { name: 'Default', value: 'default' },
    { name: 'Blue/Green', value: 'blue/green' },
    { name: 'Canary', value: 'canary' },
    { name: 'A/B Testing', value: 'ab/testing' },
  ];
  appForm: UntypedFormGroup;
  selectedStrategy: string;
  steps: any[] = [];
  headersFormArray: FormArray;
  stepControl: UntypedFormControl;
  loading = false;
  workloadSpec = {};
  envFormArray: FormArray;
  collapsed = false;
  constructor(
    private modalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private cdService: CdService,
    private service: AsideExtenderService
  ) {}

  ngOnInit(): void {
    this.selectedStrategy = this.app?.spec?.deploymentStrategy;
    this.initForm();
  }

  initForm() {
    this.headersFormArray = new FormArray([], this.noDuplicateKeys);
    this.envFormArray = new FormArray([], this.noDuplicateKeys);

    let strategy = this.selectedStrategy;
    this.appForm = new UntypedFormGroup({
      strategy: new UntypedFormControl(strategy),
      replicas: new UntypedFormControl(
        this.app?.spec?.replicas,
        Validators.required
      ),
    });

    if (['canary', 'blue/green', 'ab/testing'].includes(strategy)) {
      let stagingForm = new UntypedFormGroup({});
      if (!this.app?.spec?.strategySpec?.staging) {
        this.app.spec.strategySpec.staging = [];
        this.app?.spec?.strategySpec?.live.forEach((element) => {
          this.app?.spec?.strategySpec?.staging.push({
            name: element?.name,
            version: element?.version,
            image: element?.image,
          });
        });
      }
      this.app?.spec?.strategySpec?.staging.forEach(
        (container: any, index: number) => {
          stagingForm.addControl(
            'staging_' + index,
            new UntypedFormControl(container?.version, Validators.required)
          );
        }
      );
      this.appForm.addControl('stagingForm', stagingForm);

      // Only Canary
      if (strategy == 'canary') {
        this.initEnv(this.app.stagingEnvironment);
        let autoProgression = this.app?.spec?.strategySpec?.autoProgression;
        let canarySpecForm = new UntypedFormGroup({
          trafficSplitting: new UntypedFormControl(
            this.app?.spec?.strategySpec?.trafficSplitting
              ? this.app?.spec?.strategySpec?.trafficSplitting
              : 0,
            [Validators.required, Validators.min(0), Validators.max(100)]
          ),
          enabled: new UntypedFormControl(
            autoProgression?.enabled ? autoProgression?.enabled : false
          ),
          interval: new UntypedFormControl(
            autoProgression?.interval ? autoProgression?.interval : '30m',
            [Validators.required, Validators.min(0), Validators.max(100)]
          ),
          rateMin: new UntypedFormControl(
            autoProgression?.metrics
              ? autoProgression?.metrics['request-success-rate']?.min
              : 99,
            [Validators.required, Validators.min(0), Validators.max(100)]
          ),
          rateMax: new UntypedFormControl(
            autoProgression?.metrics
              ? autoProgression?.metrics['request-success-rate'].max
              : 100,
            [Validators.required, Validators.min(0), Validators.max(100)]
          ),
          latencyMin: new UntypedFormControl(
            autoProgression?.metrics
              ? autoProgression?.metrics['request-duration']?.min
              : 0,
            [Validators.required, Validators.min(0), Validators.max(100)]
          ),
          latencyMax: new UntypedFormControl(
            autoProgression?.metrics
              ? autoProgression?.metrics['request-duration']?.max
              : 1,
            [Validators.required, Validators.min(0), Validators.max(100)]
          ),
        });

        canarySpecForm.addControl(
          'type',
          new UntypedFormControl(autoProgression?.steps ? 'steps' : 'increment')
        );
        canarySpecForm.addControl('steps', new UntypedFormControl(null));
        this.steps = autoProgression?.steps ? autoProgression?.steps : [];

        canarySpecForm.addControl(
          'increment',
          new UntypedFormControl(
            autoProgression?.increment ? autoProgression?.increment : 10,
            Validators.required
          )
        );

        this.stepControl = new UntypedFormControl(0, [
          Validators.min(1),
          Validators.max(100),
        ]);

        this.appForm.addControl('canarySpecForm', canarySpecForm);
      }

      // Only Ab/Testing
      if (strategy == 'ab/testing') {
        this.initEnv(this.app.stagingEnvironment);
        let abTestingSpecForm = new UntypedFormGroup({});
        if (this.app?.spec?.strategySpec?.headers) {
          const headersKeys = this.app?.spec?.strategySpec?.headers;

          for (const key in headersKeys) {
            if (headersKeys.hasOwnProperty(key)) {
              this.onAddFormGroup('header', {
                key: key,
                value: headersKeys[key],
              });
            }
          }
        }

        this.appForm.addControl('abTestingSpecForm', abTestingSpecForm);
      }
    }

    // Only Ab/Testing
    if (strategy == 'default') {
      this.initEnv(this.app.liveEnvironment);
      let defaultSpecForm = new UntypedFormGroup({
        autoDeploy: new UntypedFormControl(
          this.app?.spec?.strategySpec?.autoDeploy
        ),
      });
      this.app?.spec?.strategySpec?.live.forEach((element, index) => {
        defaultSpecForm.addControl(
          'live_' + index,
          new UntypedFormControl(element?.version, Validators.required)
        );
      });
      this.appForm.addControl('defaultSpecForm', defaultSpecForm);
    }
  }

  noDuplicateKeys(formArray: FormArray) {
    const keyValues = formArray.controls.map(
      (control) => control.get('key').value
    );
    const keyOccurrences = keyValues.reduce((acc, key) => {
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    formArray.controls.forEach((control, index) => {
      const keyControl = control.get('key');
      if (keyOccurrences[keyControl.value] > 1) {
        keyControl.setErrors({ duplicate: true });
      } else {
        if (keyControl.hasError('duplicate')) {
          delete keyControl.errors['duplicate'];
          if (!Object.keys(keyControl.errors).length) {
            keyControl.setErrors(null);
          }
        }
      }
    });

    return keyValues.some((key) => keyOccurrences[key] > 1)
      ? { duplicateKeys: true }
      : null;
  }

  initEnv(env: StringMap) {
    for (const key in env) {
      if (env.hasOwnProperty(key)) {
        this.onAddFormGroup('env', { key: key, value: env[key] });
      }
    }
  }

  onCollapsed(){
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      $('#collapseEnvVar').collapse({
        toggle: true,
      });
    }else{
      $('#collapseEnvVar').collapse({
        toggle: false,
      });
    }
  }
  isManagedByDoor() {
    const keys = Object.keys(this.app.metadata.labels);
    const values = Object.values(this.app.metadata.labels);

    let ownLabel = 0;
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      let value = values[index] as string;
      if (value) {
        value = value.toLowerCase();
      }

      if (key == 'app.kubernetes.io/managed-by' && value == 'helm') {
        ownLabel += 1;
      }
      if (key == 'helm.sdk.operatorframework.io/chart' && value == 'app') {
        ownLabel += 1;
      }
    }

    if (ownLabel == 2) {
      return true;
    }
    return false;
  }

  onChangeAutoDeploy(event: any) {
    let autoDeploy = event.target.checked;
    this.appForm.get('defaultSpecForm').get('autoDeploy').setValue(autoDeploy);
  }

  onStrategyChange(strategy: any): void {
    this.selectedStrategy = strategy?.value;
    this.appForm.get('strategy').setValue(strategy?.value);
    this.initForm();
  }

  onChangeStagingVersion(event, ov: any, index: any) {
    $($(event.target).parent()).css('display', 'none');
    this.appForm
      .get('stagingForm')
      .get('staging_' + index)
      .setValue(ov.name);
  }

  getLiveVersion(index: number): string {
    return this.app?.spec?.strategySpec?.live[index]?.version;
  }

  getLiveContainer(index) {
    return this.app?.spec?.strategySpec?.live[index]?.name;
  }

  getStagingVersion(index: number): string {
    return this.appForm.get('stagingForm')?.get('staging_' + index)?.value;
  }

  getStagingContainer(index) {
    return this.app?.spec?.strategySpec?.staging[index]?.name;
  }

  displayLiveValue() {
    let val = this.appForm.get('canarySpecForm').get('trafficSplitting').value;

    if (val) {
      if (!isNaN(val)) {
        if (val <= 100) {
          val = parseFloat(val);
          return 100 - val;
        }
      }
    }

    return 100;
  }

  onDeleteStep(index: number) {
    this.steps.splice(index, 1);
  }

  onAddStep() {
    if (this.stepControl.valid) {
      this.steps.push(this.stepControl?.value);
      this.stepControl.setValue(0);
    }
  }

  getFormArray(type: string): FormArray {
    if (type == 'header') {
      return this.headersFormArray;
    }

    if (type == 'env') {
      return this.envFormArray;
    }
  }

  // On add dynamic
  onAddFormGroup(type: string, data?: any) {
    let formArray = this.getFormArray(type);
    formArray.push(this.createFormGroup(type, data));
  }

  onRemoveFormGroup(type: string, index: number) {
    let formArray = this.getFormArray(type);
    formArray.removeAt(index);
  }

  private createFormGroup(type: string, data?: any): FormGroup {
    let formGroup: FormGroup;

    switch (type) {
      case 'header':
        formGroup = this.fb.group({
          key: this.fb.control(data?.key, [Validators.required]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      case 'env':
        formGroup = this.fb.group({
          key: this.fb.control(data?.key, [
            Validators.required,
            Validators.pattern('^[a-zA-Z][a-zA-Z0-9_]*$'),
          ]),
          value: this.fb.control(data?.value, [Validators.required]),
        });
        break;
      default:
        break;
    }
    return formGroup;
  }

  onUpdate(): void {
    const formvalue = this.appForm.value;
    this.workloadSpec['deploymentStrategy'] = formvalue?.strategy;
    this.workloadSpec['replicas'] = formvalue?.replicas.toString();

    if (['canary', 'blue/green', 'ab/testing'].includes(formvalue.strategy)) {
      this.workloadSpec['stagingEnvironmentToPath'] = {};
      this.workloadSpec['stagingContainers'] = [];
      this.app?.spec?.strategySpec?.staging.forEach((element, index) => {
        this.workloadSpec['stagingContainers'].push({
          image: element?.image,
          name: element?.name,
          version: formvalue?.stagingForm['staging_' + index],
        });
      });

      if (formvalue.strategy == 'canary') {
        const metrics = new Map();
        (metrics['request-success-rate'] = {
          max: formvalue?.canarySpecForm?.rateMax,
          min: formvalue?.canarySpecForm?.rateMin,
        }),
          (metrics['request-duration'] = {
            max: formvalue?.canarySpecForm?.latencyMax,
            min: formvalue?.canarySpecForm?.latencyMin,
          });

        this.workloadSpec['trafficSplitting'] = parseFloat(
          formvalue?.canarySpecForm?.trafficSplitting
        );
        this.workloadSpec['autoProgression'] = {
          interval: formvalue?.canarySpecForm?.interval,
          maxWeight: 100,
          minRequest: 0,
          enabled: formvalue?.canarySpecForm?.enabled,
          metrics,
        };

        if (formvalue.canarySpecForm.type == 'steps') {
          this.workloadSpec['autoProgression'].steps = this.steps;
          this.workloadSpec['autoProgression'].increment = 0;
        } else {
          this.workloadSpec['autoProgression'].steps = [];
          this.workloadSpec['autoProgression'].increment =
            formvalue?.canarySpecForm?.increment;
        }
      }

      if (this.envFormArray.value.length > 0) {
        this.envFormArray.value.forEach((env: any) => {
          let key = env.key;
          let value = env.value;
          this.workloadSpec['stagingEnvironmentToPath'][key] = value;
        });
      }

      if (formvalue.strategy == 'ab/testing') {
        this.workloadSpec['headers'] = {};
        if (this.headersFormArray.value.length > 0) {
          this.headersFormArray.value.forEach((header: any) => {
            let key = header.key;
            let value = header.value;
            this.workloadSpec['headers'][key] = value;
          });
        }
      }
    }

    if (formvalue.strategy == 'default') {
      this.workloadSpec['liveEnvironmentToPath'] = {};
      this.workloadSpec['autoDeploy'] = formvalue?.defaultSpecForm?.autoDeploy;
      this.workloadSpec['liveContainers'] = [];
      this.app?.spec?.strategySpec?.live.forEach((element, index) => {
        this.workloadSpec['liveContainers'].push({
          image: element?.image,
          name: element?.name,
          version: formvalue?.defaultSpecForm['live_' + index],
        });
      });

      if (this.envFormArray.controls.length > 0) {
        this.envFormArray.value.forEach((env) => {
          let key = env.key;
          let value = env.value;
          this.workloadSpec['liveEnvironmentToPath'][key] = value;
        });
      }
    }

    this.loading = true;
    this.cdService
      .editWorkload(
        {
          idproject: this.app?.projectId,
          idEnv: this.app?.environmentId,
          idWorkload: this.app?.metadata?.uid,
        },
        this.workloadSpec
      )
      .subscribe(
        async (response) => {
          this.onCloseDialog();
          this.service.show({
            title: 'Workload',
            message: `${this.app?.metadata?.name} has been update`,
            type: Type.SUCCESS,
            position: Position.TOP,
          });
          this.loading = false;
          this.event.emit({ appId: response, res: 200 });
        },
        (err) => {
          this.service.show({
            title: 'Workload',
            message: err.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.loading = false;
        }
      );
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }
}

<div id="toast" class="notify" [ngClass]="classes">
  <div class="n-contain">
      <div class="notify-indic">
          <em [class]="currentState?.icon"></em>
      </div>
      <div class="notify-desc">
        <div>
          <span>{{title}}</span>
        </div>
        <div>
            <span>{{message}}</span>
        </div>
      </div>
      <div class="notify-close">
          <a (click)="close()"><em class="bi bi-x"></em></a>
      </div>
  </div>
</div>

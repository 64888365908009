<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="!loading && hasNotAccess">
  <span style="color: #f3280db9; font-size: 20px;">
    Access Denied
    <i class="fa fa-window-close" aria-hidden="true"></i>
  </span>
  <p>You don't have permission for this page</p>
</div>

<section class="deployeMain" *ngIf="!hasNotAccess">
  <div class="row no-gutters">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="deployfilter">
        <div class="tableheading">Applications ({{ applications.length }})</div>

        <div class="dropdown">
          <button class="dropbtn" [disabled]="loading">
            <span>Project ({{ projects.length }})</span>
            <span><i class="bi bi-chevron-down"></i></span>
          </button>

          <div class="dropdown-content">
            <button [class.active]="!this.query?.projectId" (click)="onProjectSelect(null)" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn">
              <span>All</span>
            </button>

            <button *ngFor="let project of projects" type="button" [disabled]="loading"
              [class.active]="project?.id == this.query?.projectId" (click)="onProjectSelect(project)"
              class="my-1 m-0 btn btn-success form-control filter-btn">
              <span>{{ project.name }}</span>
            </button>
          </div>
        </div>

        <div class="dropdown">
          <button class="dropbtn" [disabled]="loading">
            <span>Environment ({{ environments.length }})</span>
            <span><i class="bi bi-chevron-down"></i></span>
          </button>

          <div class="dropdown-content">
            <button [class.active]="!this.query?.environmentId" (click)="onEnvSelect(null)" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn">
              <span>All</span>
            </button>

            <button *ngFor="let env of environments" type="button" [disabled]="loading"
              [class.active]="env?.id == this.query?.environmentId" (click)="onEnvSelect(env)"
              class="my-1 m-0 btn btn-success form-control filter-btn">
              <span>{{ env.name }}</span>
            </button>
          </div>
        </div>

        <div class="dropdown">
          <button class="dropbtn" [disabled]="loading">
            <span>Strategies (4)</span>
            <span><i class="bi bi-chevron-down"></i></span>
          </button>
          <div class="dropdown-content">
            <button (click)="onChangeStrategy('')" type="button" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn" [class]="{active: !this.query?.strategy}">
              <span>All</span>
            </button>
            <button (click)="onChangeStrategy('default')" type="button" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn" [class]="{active: this.query?.strategy == 'default'}">
              <span>Default</span>
            </button>
            <button (click)="onChangeStrategy('canary')" type="button" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn" [class]="{active: this.query?.strategy == 'canary'}">
              <span>Canary</span>
            </button>
            <button (click)="onChangeStrategy('blue/green')" type="button" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn"
              [class]="{active: this.query?.strategy == 'blue/green'}">
              <span>Blue/Green</span>
            </button>
            <button (click)="onChangeStrategy('ab/testing')" type="button" [disabled]="loading"
              class="my-1 m-0 btn btn-success form-control filter-btn"
              [class]="{active: this.query?.strategy == 'ab/testing'}">
              <span>A/B Testing</span>
            </button>
          </div>
        </div>

        <div class="input-search d-flex">
          <input type="text" (input)="onSearch($event)" class="" placeholder="Search..." [value]="query?.search ? query?.search : ''">
          <i class="fa fa-search"></i>
        </div>

        <div class="text-right w-100 mt-2 pr-4">
          <button class="btn-multi-deploy" (click)="multiUpdate = !multiUpdate" *ngIf="workloadsSelected.length == 0">
            Multi Deploy
          </button>
          <button class="btn-multi-update" *ngIf="workloadsSelected.length > 0" (click)="onMultiUpdate()">
            Update App ({{ workloadsSelected.length.toString().padStart(2, '0') }})
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters bg">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="background-color: #fff;">

      <div class="app-list-container px-2" *ngIf="applications?.length > 0 && !loading">

        <div *ngIf="multiUpdate" class="d-flex justify-content-end">
          <!-- <div [style]="{width: multiUpdate ? '90%' : '100%'}"></div> -->
          <div class="select-all">
            <label for="all" class="text-all">Select All</label>
            <label class="wrapper-checkbox">
              <input type="checkbox" id="all" (click)="onSelectAllWorkloads($event)">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div class="app-item card" *ngFor="let app of applications;">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between" style="white-space: nowrap !important;">
              <div class="" style="white-space: normal !important;"
                [style]="{width: multiUpdate ? '95%' : '100%'}" (click)="onViewAppDetail(app)">
                <div class="row no-gutters">
                  <div class="col-auto p-3 app-logo" [class]="getClusterImageColor(app)">
                    <img *ngIf="!app.shared" [src]="getClusterTypeImage(app.clusterType)" alt="Cluster type image"
                      [ngClass]="{'doimage': app.clusterType =='digitalocean' || 'openshift'}" />
                    <img *ngIf="app.shared" width="30px" src="../../../../../../assets/img/news/cloudoor-04.png" alt="Cloud provider image"/>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="tableTab">
                        <div class="ist-col fix-width-col app-title">{{ app?.metadata?.name }}</div>

                        <div class="fix-width-col app-name">{{ app.clusterName }}</div>

                        <!-- <div class="fix-width-col app-name">{{ app.projectName }}</div> -->

                        <div class="app-info leftinline border-bottom-0 remove-padding">
                          <div class="col-sm envName remove-padding">
                            <div class="border-0 " style="width: 16rem;">Last Update :<span class="last-seen">{{
                                displayDateForHuman(app.lastUpdatedDate) }}</span></div>
                          </div>
                        </div>

                        <div class="leftinline">
                          <div class="col-sm appName" style="width: 8rem;">{{ ['Auto deployment', 'Manual deployment'].includes(app?.spec?.deploymentStrategy) ? 'default' : app?.spec?.deploymentStrategy
                            }}
                          </div>
                        </div>

                        <div class="col-sm app-info leftinline border-bottom-0">
                          <div class="col">
                            <div class="flex justify-content-start envName">
                              <div class="d-flex" *ngIf="app?.spec?.strategySpec?.live?.length > 0;">
                                <span class="bold">Live</span>

                                <ng-container *ngFor="let rec of app?.spec?.strategySpec?.live; let last = last;">
                                  <span>{{ getVersion(rec.version) }}</span>


                                  <span class="bar" *ngIf="app?.spec?.strategySpec?.live?.length > 1 && !last">|</span>
                                </ng-container>
                                <span class="bold m-0" *ngIf="app?.spec?.deploymentStrategy === 'canary' ">{{ 100 -
                                  app.spec.strategySpec.trafficSplitting}}%</span>
                              </div>

                              <div class="d-flex" *ngIf="app?.spec?.strategySpec?.staging?.length > 0;">
                                <span class="bar">|</span>

                                <span class="bold">Staging</span>

                                <ng-container *ngFor="let rec of app?.spec?.strategySpec?.staging; let last = last;">
                                  <span>{{ getVersion(rec.version) }}</span>


                                  <span class="bar" *ngIf="app?.spec?.strategySpec?.staging?.length > 1 && !last">|</span>
                                </ng-container>
                                <span class="bold m-0" *ngIf="app?.spec?.deploymentStrategy === 'canary'">{{
                                  app?.spec?.strategySpec?.trafficSplitting }}%</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="colsm" style="margin-top: 5px;">
                          <div *ngIf="parseValueDailyFlow(app) > 0" [ngClass]="{
                      'litinfo': isInfo(app),
                      'warninginfo': isWarning(app),
                      'litRed': isDanger(app) }">
                            <div class="px-2 successRate">Success rate:</div>
                            <div class="successRate">{{parseValueSuccessRate(app)}}%</div>
                          </div>
                          <div class="warninginfo" *ngIf="parseValueDailyFlow(app) == 0">
                            <div class="px-2 successRate"></div>
                            <div class="successRate">
                              <div class="wrapper_tooltip" ngClass="{'pl-5': last}">
                                <span style="padding: 3px 7px;" class="tooltip">
                                  {{"&nbsp; Use CNO API Gateway to get metrics"}}
                                </span>
                                <i class="bi bi-exclamation-circle"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-auto p-3 bg-white">
                    <i class="bi bi-three-dots-vertical"></i>
                  </div> -->
                </div>
              </div>
              <div class="selectchekbox" style="width: 5%;" *ngIf="multiUpdate">
                <label class="wrapper-checkbox">
                  <input type="checkbox" [attr.data-app]="parseData(app)" [attr.id]="app.id" (click)="onSelectApp($event, app)"
                    class="w-checkbox">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="m-5">
        <spinner *ngIf="loading"></spinner>
      </div>

      <view-statement *ngIf="!loading && applications?.length === 0" [element]="'No Apps found'"></view-statement>
    </div>
  </div>
</section>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog2()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <h4 class="heading">Result after update</h4>
          <div class="table-responsive model-table">
            <table class="table">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Env</th>
                  <th>App</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let app of resultsApps; let i = index">
                  <td [ngClass]="{green: app.Result == 'ok',red: app.Result != 'ok' }">
                    {{ app?.Project }}
                  </td>
                  <td [ngClass]="{green: app.Result == 'ok',red: app.Result != 'ok' }">
                    {{ app?.Environment }}
                  </td>
                  <td [ngClass]="{green: app.Result == 'ok',red: app.Result != 'ok' }">
                    {{ app?.Workload }}
                  </td>
                  <td [ngClass]="{green: app.Result == 'ok',red: app.Result != 'ok' }">
                    {{ app?.Result }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        <div class="attachment-link d-flex justify-content-center mt-3">
          <button class="btn-cancel" (click)="onCloseDialog2()">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { DEFAULT_ORGANIZATION, Position, SUPPORT_EMAIL, Type } from 'src/app/utils/const';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { ShareService } from 'src/app/data/service/share.service';
import { Organization } from 'src/app/data/model/organization';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { OrganizationFormModalComponent } from '../organization-form-modal/organization-form-modal.component';

@Component({
  selector: 'app-list-organisation',
  templateUrl: './list-organisation.component.html',
  styleUrls: ['./list-organisation.component.scss'],
})
export class ListOrganisationComponent implements OnInit {
  organizations = null;
  page: number = 1;
  limit = 10;
  total = 0;
  pagination: any;
  search: string = '';
  modalRef: BsModalRef;
  organizationSelected: any;
  organizationLoaded = false;
  disabledBtn = true;
  cannotDelete = false;
  organizationNameControl: UntypedFormControl;
  supoortEmail = SUPPORT_EMAIL;
  defaultOrganization = DEFAULT_ORGANIZATION;
  currentUser: any;

  constructor(
    private organizationService: OrganizationService,
    public auth: AppAuthGuardService,
    private service: AsideExtenderService,
    private modalService: BsModalService,
    public shareService: ShareService
  ) {}

  async ngOnInit(): Promise<void> {
    const session = this.shareService.getSession();
    this.currentUser = session.currentUser;

    this.getOrganizations(this.page, this.limit, this.search);
  }

  getOrganizations(page: number, limit: number, search: string) {
    this.total = 0;
    this.organizationLoaded = true;
    this.organizationService.getAllOrganizations(page, limit, search).subscribe(
      (response: any) => {
        this.organizationLoaded = false;
        this.organizations = response.records;
        this.page = response.page;
        this.total = response.totalRecord;

        this.organizations?.forEach((organization) => {
          organization.color = this.stringToColorCode(
            organization.name.replace('-', '')
          );
        });
      },
      (error) => {
        this.organizationLoaded = false;
        this.organizations = [];
      }
    );
  }

  noAction(org) {
    if (org.name == this.defaultOrganization) {
      return true;
    }

    return false;
  }

  onUpSearch(search: any): void {
    if (search.length > 0) {
      this.search = search;
      this.organizationLoaded = true;
      this.getOrganizations(1, this.limit, this.search);
    } else {
      this.getOrganizations(1, this.limit, "");
    }
  }

  truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }

  onOpenDeleteModal(template: TemplateRef<any>, organization: any) {
    this.cannotDelete = false;
    if (this.noAction(organization)) {
      this.service.show({
        type: Type.INFO,
        message: 'Action not authorized',
        position: Position.TOP,
        title: 'Organization',
      });
      return;
    }

    this.organizationSelected = organization;
    this.organizationNameControl = new UntypedFormControl('', [
      Validators.required,
    ]);
    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
      backdrop: 'static',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  getPage(page: number) {
    this.getOrganizations(page, this.limit, this.search);
  }

  stringToColorCode(str: string) {
    let color_codes = {};
    return str in color_codes
      ? color_codes[str]
      : (color_codes[str] =
          '#' +
          ('000000' + ((Math.random() * 0xffffff) << 0).toString(16)).slice(
            -6
          ));
  }

  onSubmitDeleteOrganization() {
    this.organizationService.delete(this.organizationSelected.id).subscribe(
      (_: any) => {
        this.onCloseDialog();
        this.getOrganizations(this.page, this.limit, this.search);
        this.service.show({
          type: Type.SUCCESS,
          message: 'The organization has been deleted successfully',
          position: Position.TOP,
          title: 'Organization',
        });
      },
      (err) => {
        this.service.show({
          type: Type.ERROR,
          message: err?.error,
          position: Position.TOP,
          title: 'Organization',
        });
        this.cannotDelete = true;
      }
    );
  }

  onUpOrganizationName(name: any): void {
    if (this.organizationSelected.name === name) {
      this.disabledBtn = false;
    } else {
      this.disabledBtn = true;
    }
  }

  onOpenFormModalOrganization(organization = null): void {
    if (organization) {
      if (this.noAction(organization)) {
        this.service.show({
          type: Type.INFO,
          message: 'Action not authorized',
          position: Position.TOP,
          title: 'Organization',
        });
        return;
      }
    }
    const config: any = {
      class: 'popup-right-side-mini',
      initialState: {
        organization: organization ? organization : new Organization(),
      },
    };

    this.modalRef = this.modalService.show(
      OrganizationFormModalComponent,
      config
    );

    this.modalRef.content.event.subscribe((res) => {
      this.getOrganizations(this.page, this.limit, this.search);
    });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invitation } from 'src/app/data/model/invitation';
import { Role } from 'src/app/data/model/role';
import { User } from 'src/app/data/model/user';
import { ShareService } from 'src/app/data/service/share.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    observe: 'response' as 'body'
  };
  baseURL = '';
  constructor(private httpClient: HttpClient, public shareService: ShareService) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }
  getUsersHavingRole(privilege, resource, resourceId: string): Observable<User[]> {
    return this.httpClient.get<User[]>(
      this.baseURL + this.shareService.organizationSelected.id + '/resources/' + resource + '/' + resourceId + '/privileges/' + privilege + '/users');
  }
  getAll(page?: number, limit?: number): Observable<User[]> {
    return this.httpClient.get<User[]>(
      this.baseURL + this.shareService.organizationSelected.id + '/users?page=' + page + '&limit=' + limit);
  }
  createInvitation(invitation: Invitation): Observable<Invitation[]> {
    return this.httpClient.post<Invitation[]>(
      this.baseURL + this.shareService.organizationSelected.id +'/users/invitations', invitation, this.httpOptions);
  }
  AddUser(upUser: User): Observable<User> {
    return this.httpClient.post<User>(
      this.baseURL + this.shareService.organizationSelected.id +'/users', upUser, this.httpOptions);
  }
  getUserById(id: string): Observable<User> {
    return this.httpClient.get<User>(
      this.baseURL + this.shareService.organizationSelected.id + '/users/' + id);
  }
  getUserByEmail(email: string): Observable<User> {
    return this.httpClient.get<User>(
      this.baseURL + this.shareService.organizationSelected.id + '/users/email/' + email);
  }
  searchUsers(query: string): Observable<User> {
    return this.httpClient.get<User>(
      this.baseURL + this.shareService.organizationSelected.id + '/users/?search='+query);
  }
  deleteUserFromOrganization(email: string): Observable<object> {
    return this.httpClient.delete(
      this.baseURL + this.shareService.organizationSelected.id + '/users/' + email, this.httpOptions);
  }
  assigneRoleToUser(role: Role): Observable<object> {
    return this.httpClient.post(
      this.baseURL + this.shareService.organizationSelected.id + '/users/privileges', role, this.httpOptions);
  }
  deleteRoleFromUser(email: string, realmRole: Role): Observable<object> {
    return this.httpClient.delete(this.baseURL + this.shareService.organizationSelected.id + '/users/' + email + '/resources/' + realmRole.resource + '/' + realmRole.resourceId + '/privileges/' + realmRole.privilege, this.httpOptions);
  }
  upDateUser(upUser: User): Observable<object>{
    return this.httpClient.put(
      this.baseURL + this.shareService.organizationSelected.id + '/users/' + upUser.id, upUser ,this.httpOptions);
  }
}

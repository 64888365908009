import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectSearchComponent),
  multi: true,
};

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SelectSearchComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  static activeInstance: SelectSearchComponent | null = null;

  @Input('option-label') optionLabel: string;
  @Input('option-value') optionValue: string = '';
  @Input('placeholder') placeholder: string = 'Select option';
  @Input('filtering') filtering: boolean = false;
  @Input('disable') disable: boolean = true;
  @Input('options') options: any[] = [];
  @Input('no-result-string') noResultString: string = '';
  @Input('no-data-string') noDataString: string = '';
  @Input('options-visible') optionsVisible: boolean = false;
  @Input('select-class') selectClass: string = '';
  @Input('multiple') multiple: boolean = false;

  @Output('change') valueChange = new EventEmitter<any>();
  @Output('filter') filter = new EventEmitter<string>();

  loader: boolean = false;
  label: any;
  value: any;
  labels = [];
  values = [];
  tempOptions: any[] = [];

  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.updateOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.updateOptions();
    }
    if (changes.label) {
      this.setDefaultValue();
    }
  }

  showOptions(): void {
    if (!this.disable) {
      if (SelectSearchComponent.activeInstance && SelectSearchComponent.activeInstance !== this) {
        SelectSearchComponent.activeInstance.optionsVisible = false;
      }

      this.optionsVisible = true;
      SelectSearchComponent.activeInstance = this;
    }

  }

  onFilterChange(): void {
    if (this.filtering) {
      if (this.filter.observers.length > 0) {
        this.filter.emit(this.label);
      } else {
        this.tempOptions = this.filterOptions(this.label, this.options);
      }
    }
    this.onTouchedCallback();
  }

  onOptionChange(option: any): void {
    this.optionsVisible = false;
    if (this.optionLabel) {
      this.label = option[this.optionLabel];
    } else {
      this.label = option;
    }

    if (this.optionValue) {
      this.value = option[this.optionValue];
    } else {
      this.value = option;
    }

    if (this.multiple) {
      const indexLabel = this.labels.indexOf(this.label);
      const indexValue = this.values.indexOf(this.value);

      if (indexLabel === -1) {
        this.labels.push(this.label);
      } else {
        this.labels.splice(indexLabel, 1);
      }

      if (indexValue === -1) {
        console.log(this.values);
        this.values.push(this.value);
      } else {
        this.values.splice(indexValue, 1);
      }

      if (this.labels[0]) {
        this.label = this.labels[0];
      }

      if (this.labels[2]) {
        this.label = `${this.labels[0]},${this.labels[1]},${this.labels[2]}`;
      }

      this.onChangeCallback(this.values);
      this.valueChange.emit(this.values);
    } else {
      this.onChangeCallback(this.value);
      this.valueChange.emit(this.value);
    }
  }

  activeOption(option: any): boolean {
    if (this.multiple) {
      if (this.optionLabel) {
        return this.labels.includes(option[this.optionLabel]);
      }
      return this.labels.includes(option);
    }
    if (this.optionLabel) {
      return option[this.optionLabel] === this.label;
    }
    return option === this.label;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (
      !event.target ||
      !(event.target as HTMLElement).closest('.select-wrapper')
    ) {
      this.optionsVisible = false;
      SelectSearchComponent.activeInstance = null;
    }
  }

  private updateOptions(): void {
    this.tempOptions = this.options;
  }

  private filterOptions(searchTerm: string, options: any[]): any[] {
    const filter = searchTerm.toLowerCase();
    return options.filter((option) =>
      option[this.optionLabel].toLowerCase().includes(filter)
    );
  }

  private setDefaultValue(): void {
    setTimeout(() => {
      if (typeof this.label === 'object') {
        for (let index = 0; index < this.options.length; index++) {
          const option = this.options[index];

          if (this.deepEqual(this.label, option)) {
            this.onOptionChange(option);
            break;
          }
        }
      } else {
        for (let index = 0; index < this.options.length; index++) {
          const option = this.options[index];
          if (
            this.label === option ||
            this.label === option[this.optionLabel] ||
            option[this.optionValue] === this.label
          ) {
            this.onOptionChange(option);
            break;
          }
        }
      }
    }, 100);
  }

  private deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    )
      return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;
      if (!this.deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  //From ControlValueAccessor interface
  writeValue(label: any) {
    if (label !== this.label) {
      if (this.multiple) {
        this.labels = label;
        for (let index = 0; index < this.options.length; index++) {
          const option = this.options[index];
          if (this.labels.includes(option)) {
            this.values.push(option);
            continue;
          }
          if (this.labels.includes(option[this.optionLabel])) {
            this.values.push(option[this.optionLabel]);
            continue;
          }
          if (this.labels.includes(option[this.optionValue])) {
            this.values.push(option[this.optionValue]);
            continue;
          }
        }
        setTimeout(() => {
          this.label = this.labels.join(',');
          this.onChangeCallback(this.values);
          this.valueChange.emit(this.values);
        }, 100);
      } else {
        this.label = label;
        this.setDefaultValue();
      }
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

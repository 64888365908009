import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ShareService } from 'src/app/data/service/share.service';
import { Position, Type } from 'src/app/utils/const';


type MessageAccount = {
  email: string,
  id?: string,
  insecureSkipVerify?: boolean,
  organizationId: string,
  password: string,
  port: number,
  serverSmtp: string,
  validatorAddresses?: string
}

@Component({
  selector: 'app-messaging-account',
  templateUrl: './messaging-account.component.html',
  styleUrls: ['./messaging-account.component.scss'],
})
export class MessagingAccountComponent implements OnInit {
  openForm: UntypedFormGroup;
  loading: boolean = false;
  account: MessageAccount;
  constructor(
    public modalRef: BsModalRef,
    private organizationService: OrganizationService,
    private service: AsideExtenderService,
    private shareService: ShareService,

    ) {}

  ngOnInit(): void {
    this.getAllMessagingAccounts()
    this.initForm();

  }
  initForm() {
    this.openForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.required),
      smtp_server: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      port: new UntypedFormControl('', Validators.required),
    });
  }
  getAllMessagingAccounts() {
    this.organizationService.getMessagingAccount().subscribe((response: MessageAccount) => {
      if(response){
        this.account = response
        this.setCurrentAccountValues(response)
      }else{
        this.account = null
      }
    }, (error) => console.log(error))
  }

  setCurrentAccountValues(data: MessageAccount){
    this.openForm.patchValue({
      email: data?.email,
      smtp_server: data?.serverSmtp,
      password: data?.password,
      port: data?.port,
    });
  }
  onCreateAccount() {
    this.loading = true
    const dataToSend : MessageAccount = {
      email : this.openForm.value.email,
      organizationId: this.shareService.organizationSelected.id,
      password: this.openForm.value.password,
      port: this.openForm.value.port,
      serverSmtp: this.openForm.value.smtp_server,
    }
    this.organizationService.createSendMessagingAccounts(dataToSend).subscribe(() => {
 
      this.service.show({
        title: 'Messaging Account',
        message: "Created successfully",
        type: Type.SUCCESS,
        position: Position.TOP,
      });
      this.loading = false
      this.onCloseDialog()

    }, (error) => {
      this.service.show({
        title: 'Messaging Account',
        message: error.error,
        type: Type.ERROR,
        position: Position.TOP,
      });
      this.loading = false

    });
  }
  
  onUpdateAcount() {
    this.loading = true
    const dataToSend : MessageAccount = {
      email : this.openForm.value.email,
      organizationId: this.shareService.organizationSelected.id,
      password: this.openForm.value.password,
      port: this.openForm.value.port,
      serverSmtp: this.openForm.value.smtp_server,
    }
    this.organizationService.updateSendMessagingAccounts(dataToSend).subscribe(() => {
      this.service.show({
        title: 'Messaging Account',
        message: "Updated successfully",
        type: Type.SUCCESS,
        position: Position.TOP,
      });
      this.loading = false
      this.onCloseDialog()

    }, (error) => {
      this.service.show({
        title: 'Messaging Account',
        message: error.error,
        type: Type.ERROR,
        position: Position.TOP,
      });
      this.loading = false

    });
  }
  onDeleteAccount() {
    this.loading = true

    this.organizationService.deleteSendMessagingAccounts().subscribe(() => {
      this.service.show({
        title: 'Messaging Account',
        message: "Deleted successfully",
        type: Type.SUCCESS,
        position: Position.TOP,
      });
      this.loading = false
      this.initForm()
      this.account = null

    }, (error) => {
      this.service.show({
        title: 'Messaging Account',
        message: error.error,
        type: Type.ERROR,
        position: Position.TOP,
      });
      this.loading = false

    });
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }
}

import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-check-screen',
  templateUrl: './check-screen.component.html',
  styleUrls: ['./check-screen.component.scss'],
})
export class CheckScreenComponent implements OnInit {

  constructor(
    public modalRef: BsModalRef,
  ) {}

  ngOnInit(): void {
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }
}

<div class="row div-container">
  <div class="col-md-12 div-title d-flex justify-content-between">
    <div>
      <button (click)="goBack()"><i class="bi-chevron-left"></i> &nbsp; Go back</button>
      <strong>Registry</strong>
    </div>
    <button class="btn btn-update" *ngIf="auth?.hasOrganizationRoles('super_admin')" (click)="onCreate()">Create new registry</button>
  </div>
  <div class="col-md-12 table-bloc">
    <div class="div-table">
      <table class="table member-table table-responsive">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Username</th>
            <th scope="col">Repository</th>
            <th scope="col">Created at</th>
            <th scope="col" class="action"> <i class="bi bi-x-lg"></i></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of registries">
            <td class="pt-3">{{ item?.name }}</td>
            <td class="pt-3">
              <img *ngIf="item?.registryType == 'docker'" width="25px" src="../../../../../../../assets/img/docker.svg" alt="docker"/>
              <img *ngIf="item?.registryType == 'harbor'" width="20px" src="../../../../../../../assets/img/harbor.png" alt="harbor"/>
              <img *ngIf="item?.registryType == 'gcr'" width="25px" src="../../../../../../../assets/img/gcp.svg" alt="gcr"/>
              <img *ngIf="item?.registryType == 'acr'" width="25px" src="../../../../../../../assets/img/Tracé-azure.svg" alt="acr"/>
              <img *ngIf="item?.registryType == 'ecr'" width="25px" src="../../../../../../../assets/img/aws.svg" alt="ecr"/>
            </td>
            <td class="pt-3">{{ item?.username }}</td>
            <td class="pt-3"> {{ item?.repo }}</td>
            <td class="pt-3"> {{ toAllowUpdateRegistry(item) ? "---" : formatDateToHuman(item?.createdDate) }}</td>                
            <td class="pt-3 cancel nowrap">
              <i *ngIf="!toAllowUpdateRegistry(item)" class="bi-pencil-fill mr-3" (click)="onEdit(item)"></i>
              <i *ngIf="!toAllowUpdateRegistry(item)" class="bi bi-x-lg" (click)="toggleModal('deletePopup', item)"></i>
            </td>
          </tr>
        </tbody>

      </table>
      <p *ngIf="registryLoaded" class="no-app-found">Loading registries.</p>

      <p *ngIf="!registryLoaded && registries?.length == 0" class="no-app-found">No registry found.</p>
    </div>
  </div>
  <!-- <div class="col-md-4 form-bloc">
    <div class="div-form">
      <span>
        <i class="bi-pencil-fill"></i>
        {{ currentRegistry?.id ? 'Update' : 'Add' }} Registry
      </span>
      <hr class="hr">

    </div>
  </div> -->
</div>


<modal modalId="deletePopup" size="cno-modal-sm">
  <div class="flex-c" content>
    <div class="mheader">
      <img src="../../../../../../assets/icons/clear_white.svg" alt="" srcset="" data-dismiss="modal"><span>{{'Are you sure ?'}}</span>
    </div>
    <div class="mbody" *ngIf="deleteRegistryForm">
      <div>
        <span>{{'delete registry : ' + registrySelected?.name}}</span>
      </div>

      <div>
        <form [formGroup]="deleteRegistryForm">
          <label for="name">
            <span *ngIf="(cname|async) == 'INVALID'">{{'invalid registry name'}}</span>
          </label>
          <input id="" type="text" class="form-control" placeholder="ENTER REGISTRY NAME" formControlName="name">
        </form>
      </div>

      <div>
        <button class="btn submit" (click)="deleteRegistryById()"
          [disabled]="deleteRegistryForm?.invalid"><span>{{'Delete'}}</span></button>
        <button class="btn" data-dismiss="modal"><span>{{'Cancel'}}</span></button>
      </div>
    </div>
  </div>
</modal>

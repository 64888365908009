import { regions } from './../../utils/const';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octokit } from 'octokit';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GitService {

  constructor(private httpClient: HttpClient) { }


  async getOrganization(token: string){
     const octokit = new Octokit({
       auth: token
     })
    return await octokit.request('GET /organizations', {
    headers: {
      'X-GitHub-Api-Version': '2022-11-28'
    }
  })}

  getUserRepositories(token: string, page: number){
    const octokit = new Octokit({
      auth: token
    })

    return octokit.request('GET /user/repos?page='+page+'&per_page=100', {
      headers: {
        'X-GitHub-Api-Version': '2022-11-28'
      }
    })
  }

  getGitlabRepositories(token: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`

      }),
    };
    const url = "https://gitlab.com/api/v4/projects?membership=true"
    return this.httpClient.get(url, httpOptions);
  }

  getBitbucketRepositories(token: string, page: number){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`

      }),
    };
    const url = 'https://api.bitbucket.org/2.0/repositories/?role=member&page='+page+'&pagelen=100'
    return this.httpClient.get(url, httpOptions);
  }

  createGithubDeploymentKey(token: string, repository: string,sshKey: string,orgName: string){
    const octokit = new Octokit({
      auth: token
    })

    return octokit.request('POST /repos/{owner}/{repo}/keys', {
      owner: orgName,
      repo: repository,
      title: 'door-ci',
      key: sshKey,
      read_only: true,
      headers: {
        'X-GitHub-Api-Version': '2022-11-28'
      }
    })
  }

  createGithubWebhooks(token: string,webhookUrl: string, webhookSecret: string,repository: string,orgName: string){
    const octokit = new Octokit({
      auth: token
    })

    return octokit.request('POST /repos/{owner}/{repo}/hooks', {
      owner: orgName,
      repo: repository,
      name: 'web',
      active: true,
      events: [
        'push'
      ],
      config: {
        url: webhookUrl,
        content_type: 'json',
        insecure_ssl: '0',
        secret: webhookSecret
      },
      headers: {
        'X-GitHub-Api-Version': '2022-11-28'
      }
    })
  }

  createGitlabWebhooks(token: string,webhookUrl: string,webhookSecret: string,repoID: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`

      }),
    };
    const body = {
      push_events : true,
      token : webhookSecret,
      url: webhookUrl
    }
    const url = `https://gitlab.com/api/v4/projects/${repoID}/hooks`
    return this.httpClient.post(url,body, httpOptions);
  }

  createGitlabDeploymentKey(token: string,sshKey: string,repoID: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`

      }),
    };
    const body = {
      id : repoID,
      key : sshKey,
      title: 'door-ci',
      can_push: true,
    }
    const url = `https://gitlab.com/api/v4/projects/${repoID}/deploy_keys`
    return this.httpClient.post(url,body, httpOptions);
  }

  createBitbucketWebhooks(token: string,webhookUrl: string,workspace: string){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`

      }),
    };
    const body =   {
      description: "Webhook Description",
      url: webhookUrl,
      active: true,
      secret: "this is a really bad secret",
      events: [
        "repo:push"
      ]
    }
    const url = `https://api.bitbucket.org/2.0/workspaces/${workspace}/hooks`
    return this.httpClient.post(url,body, httpOptions);
  }

  createBitbucketDeploymentKey(token: string,workspace: string,repo_slug: string,sshKey: string){

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`

      }),
    };
    const body = {
      key: sshKey,
      label: "door-ci"
    }
    const url = `https://api.bitbucket.org/2.0/repositories/${workspace}/${repo_slug}/deploy-keys`
    return this.httpClient.post(url,body, httpOptions);
  }

}

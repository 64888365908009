import { OrganizationService } from 'src/app/data/service/organization.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CloudProvider } from 'src/app/data/model/cloud-provider';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, regions, Type } from 'src/app/utils/const';
import { CloudProviderService } from '../../../parameters/services/cloud-provider.service';
import { Cluster } from 'src/app/data/model/cluster';
import { ClusterService } from '../../services/cluster.service';
import { ShareService } from 'src/app/data/service/share.service';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss'],
})
export class AddProviderComponent implements OnInit {
  isFirstClick: any = null;
  items = [
    { name: 'Azure', value: 'azure' },
    { name: 'Google Cloud Provider', value: 'gcp' },
  ];
  switchView: string = 'aws';

  providers = [];
  choose = false;
  awsProviderForm: UntypedFormGroup;
  gcpProviderForm: UntypedFormGroup;
  azureProviderForm: UntypedFormGroup;
  cloudSelected: string;
  selectControl: UntypedFormControl;

  sort = '';
  regions = regions.filter((m) => m.cloudprovider == 'aws');
  toggle = false;
  choosed: number;
  loading = false;
  cloud: string = "";
  deleteProviderForm: UntypedFormGroup;
  formDisable: boolean = false;
  providerSelected: any;
  currentModal: any;
  state: boolean = false;
  file: any;
  filepreview = 'JSON File';
  selected: CloudProvider;
  gcpregions = regions.filter((m) => m.cloudprovider == 'gcp');
  clusterForm: UntypedFormGroup;
  error: any;
  cloudProvidersList: object[] = [];
  spin = false;
  cloudType: object[] = [];
  existingCloudProvider: any;

  constructor(
    private router: Router,
    private cloudProviderService: CloudProviderService,
    private clusterService: ClusterService,
    private shareService: ShareService,
    private service: AsideExtenderService,
    public auth: AppAuthGuardService,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    this.isFirstClick = null;
    this.getTypeClouds();
    this.initForm();
    this.initCloudProvider();
    this.getExistingCloudProvider();
  }
  AddAws() {
    this.router.navigate(['/auth/add-cloud-providers']);
  }

  cloudButton() {
    this.isFirstClick = true;
  }

  onpemButton() {
    this.isFirstClick = false;
  }

  onChangeCloud(value: string) {
    this.isFirstClick = true;
    this.selectControl.setValue(value);
  }

  goToDashboard() {
    this.organizationService.makeOrganizationConfigComplited().subscribe(
      (data) => {
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        this.service.show({
          title: 'Failed to skip',
          message: error.message,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
    );
  }

  cloudChange(event: any) {
    let item = event.target.value;
    this.switchView = item;
  }

  regionChange(event: any) {
    let item = event.target.value;
  }

  getTypeClouds() {
    this.clusterService.getTypeClouds().subscribe((data) => {
      this.cloudType = data;
    }),
      (error) => {
        this.service.show({
          title: 'Failed to fetch cluster type',
          message: error.message,
          type: Type.ERROR,
          position: Position.TOP,
        });
      };
  }

  getExistingCloudProvider() {
    this.clusterService.getCloudProviders().subscribe((response) => {
      if (response?.records[0]) {
        this.existingCloudProvider = response?.records[0];

        this.selectControl.setValue(this.existingCloudProvider.cloudprovider);

        if (this.existingCloudProvider.cloudprovider == 'aws') {
          this.awsProviderForm
            .get('name')
            .setValue(this.existingCloudProvider.name);
          this.awsProviderForm
            .get('accessKey')
            .setValue(this.existingCloudProvider.awsaccesskeyid);
          this.awsProviderForm
            .get('sessionToken')
            .setValue(this.existingCloudProvider.awsSessionToken);
          this.awsProviderForm
            .get('secretKey')
            .setValue(this.existingCloudProvider.awssecretaccesskey);
          this.awsProviderForm
            .get('defaultRegion')
            .setValue(this.existingCloudProvider.awsdefaultregion);

        } else if (this.existingCloudProvider.cloudProvider == 'azure') {
          this.azureProviderForm
            .get('name')
            .setValue(this.existingCloudProvider.name);
          this.azureProviderForm
            .get('clientId')
            .setValue(this.existingCloudProvider.armclientid);
          this.azureProviderForm
            .get('clientSecret')
            .setValue(this.existingCloudProvider.armclientsecret);
          this.azureProviderForm
            .get('subscription')
            .setValue(this.existingCloudProvider.armsubscriptionid);
          this.azureProviderForm
            .get('tenant')
            .setValue(this.existingCloudProvider.armtenantd);
          this.azureProviderForm
            .get('resourcegroup')
            .setValue(this.existingCloudProvider.armresourcegroupname);
        } else {
          this.gcpProviderForm
            .get('name')
            .setValue(this.existingCloudProvider.name);
          // For gcp comming soon
        }
      }
    }),
      (error) => {
        this.service.show({
          title: 'Failed to fetch cloud providers',
          message: error.message,
          type: Type.ERROR,
          position: Position.TOP,
        });
      };
  }

  initCloudProvider(): void {
    this.awsProviderForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      accessKey: new UntypedFormControl('', Validators.required),
      sessionToken: new UntypedFormControl(''),
      secretKey: new UntypedFormControl('', Validators.required),
      defaultRegion: new UntypedFormControl('', Validators.required),
    });

    this.azureProviderForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      clientId: new UntypedFormControl('', Validators.required),
      clientSecret: new UntypedFormControl(''),
      subscription: new UntypedFormControl('', Validators.required),
      tenant: new UntypedFormControl('', Validators.required),
      resourcegroup: new UntypedFormControl('', Validators.required),
    });

    this.gcpProviderForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      serviceAccount: new UntypedFormControl(''),
    });

    this.selectControl = new UntypedFormControl('');
  }

  upload(event) {
    this.filepreview = event.target.files[0]?.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.file = reader.result;
    };

    let extension = this.filepreview.split('.')[1];

    if (extension != 'json') {
      this.file = null;
      this.filepreview = null;
      this.service.show({
        title: 'File',
        message: "Your file format is incorrect. Please upload json file",
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
  }

  save() {
    let cloudprovider = this.makeData();
    this.cloudProviderService.create(cloudprovider).subscribe(
      (data: any) => {
        this.providers.unshift(data);
        this.service.show({
          title: 'Cloud provider',
          message: 'has been added',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
        this.file = '';
        const queryParams = {
          cloudProviderId: data.id,
          type: this.selectControl.value,
          region: data.awsdefaultregion,
        };
        this.router.navigate(['/auth/select-cluster'], { queryParams });
        this.loading = false;
      },
      (error) => {
        this.service.show({
          title: 'Cloud Provider',
          message: error.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.loading = false;
      }
    );
  }


  update() {
    this.loading = true;
    const cloudprovider = this.makeData();
    this.cloudProviderService
      .update(cloudprovider, this.existingCloudProvider.id)
      .subscribe(
        (response: any) => {
          this.providers.unshift(response);
          this.service.show({
            title: 'Cloud provider',
            message: 'has been added',
            type: Type.SUCCESS,
            position: Position.TOP,
          });
          this.file = '';
          const queryParams = {
            cloudProviderId: response.id,
            type: this.selectControl.value,
            region: response.awsdefaultregion,
          };
          this.router.navigate(['/auth/select-cluster'], { queryParams });
          this.loading = false;
        },
        (error) => {
          this.service.show({
            title: 'Cloud Provider',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.loading = false;
        }
      );
  }

  makeData() {
    const cloudprovider = new CloudProvider();
    if (this.selectControl.value == 'aws' && this.awsProviderForm?.valid) {
      this.loading = true;
      const awsform = this.awsProviderForm.value;
      cloudprovider.name = awsform.name;
      cloudprovider.cloudprovider = 'aws';
      cloudprovider.awsaccesskeyid = awsform.accessKey;
      cloudprovider.awssecretaccesskey = awsform.secretKey;
      cloudprovider.awsSessionToken = awsform.sessionToken;
      cloudprovider.awsdefaultregion = awsform.defaultRegion;
    }

    if (
      this.selectControl.value == 'gcp' &&
      this.gcpProviderForm?.valid &&
      this.file
    ) {
      this.loading = true;
      const gcpform = this.gcpProviderForm.value;
      cloudprovider.name = gcpform.name;
      cloudprovider.cloudprovider = 'gcp';
      cloudprovider.gcpserviceaccountjsonkeyfile = this.file.replace(
        'data:application/json;base64,',
        ''
      );
    }

    if (this.selectControl.value == 'azure' && this.azureProviderForm?.valid) {
      this.loading = true;
      const azureform = this.azureProviderForm.value;
      cloudprovider.name = azureform.name;
      cloudprovider.armclientid = azureform.clientId;
      cloudprovider.armclientsecret = azureform.clientSecret;
      cloudprovider.armsubscriptionid = azureform.subscription;
      cloudprovider.armtenantd = azureform.tenant;
      cloudprovider.cloudprovider = 'azure';
      cloudprovider.armresourcegroupname = azureform.resourcegroup;
    }

    return cloudprovider;
  }

  initForm() {
    this.clusterForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      apiServerUrl: new UntypedFormControl('', Validators.required),
    });
  }

  AddMyCluster(): void {
    if (this.clusterForm.valid) {
      const formValue = this.clusterForm.value;
      const cluster = new Cluster(
        '',
        formValue.name,
        formValue.type,
        formValue.apiServerUrl
      );
      this.loading = true;
      this.clusterService.create(cluster).subscribe(
        (data) => {
          this.loading = false;
          this.goToDashboard();
          this.error = null;
          this.service.show({
            title: 'Cluster',
            message: 'created successfully',
            position: Position.TOP,
            type: Type.SUCCESS,
          });
        },
        (er) => {
          this.loading = false;
          this.service.show({
            title: 'Cluster',
            message: er.error,
            position: Position.TOP,
            type: Type.ERROR,
          });
        }
      );
    }
  }
}

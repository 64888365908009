import { OrganizationService } from "src/app/data/service/organization.service";
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CHAGEBEE_STATUS_REJECT, SUBSCRIPTION_DEFAULT_STATUS, SUPPORT_EMAIL } from "src/app/utils/const";
import { Organization } from "src/app/data/model/organization";
import { ShareService } from "src/app/data/service/share.service";

export const CheckSubscriptionGuard = () => {
  const shareService = inject(ShareService);
  const organizationService = inject(OrganizationService);
  const router = inject(Router);
  const subscriptionDefaultStatus = SUBSCRIPTION_DEFAULT_STATUS;
  const chargebeeStatusReject = CHAGEBEE_STATUS_REJECT;
  const session = shareService.getSession();
  const currentUser = session.currentUser;
  const supportEmail = SUPPORT_EMAIL;
  let hasAccess = false;

  if (currentUser.email == supportEmail) {
    return true;
  }

  organizationService.checkSubscription(shareService.organizationSelected?.id).subscribe(
    (response: Organization) => {
      if (response.chargebeeSubscriptionStatus == subscriptionDefaultStatus) {
        hasAccess = true;
      } else {
        if (!chargebeeStatusReject.includes(response.chargebeeSubscriptionStatus)) {
          hasAccess = true;
        }
      }

      if (hasAccess) {
        return true;
      } else {
        router.navigateByUrl('/unauthorized/subscription');
        return false;
      }
    },
    (error: any) => {
      router.navigateByUrl('/unauthorized/subscription');
      return false;
    }
  );
};

<div class="modal-content msg-content">
  <div class="modal-header msg-head align-items-center">
    <h5 class="modal-title msgtitle" id="exampleModalLabel">
      <button type="button" class="btn btn-primary link-btn" data-bs-toggle="modal" data-bs-target="#right">
        <i class="bi bi-link-45deg"></i>
      </button>

      <span>CNOCTL Support Commands</span>
    </h5>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body">
    <div class="section_title">
    </div>
    <div class="cnoctl-support" [ngClass]="{ toggled: linkOpen == true }">
      <div class="card">
        <div class="card-body">
          <div class="line">
            <div class="_htem">
              <em class="fa fa-link"></em>
              <span>Install CNO</span>
            </div>
            <div class="_btem">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="brew-tab" data-toggle="tab" href="#brew" role="tab"
                    aria-controls="brew" aria-selected="true">Brew</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="curl-tab" data-toggle="tab" href="#curl" role="tab" aria-controls="curl"
                    aria-selected="false">Curl</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="release-tab" data-toggle="tab" href="#release" role="tab"
                    aria-controls="release" aria-selected="false">Release</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="brew" role="tabpanel" aria-labelledby="brew-tab"
                  style="margin-top: 15px;">

                  <span>Install with brew on MacOS</span>
                  <p>
                    <code><span>brew tap beopencloud/cno ; brew install cnoctl</span></code>
                    <button (click)="onCopy('brew', 7)">
                      <em class="bi" [ngClass]="{
                    'bi-files': copyState == false || copiedIndex != 7,
                    'bi-check-square': copyState == true && copiedIndex == 7
                  }"></em>
                    </button>
                  </p>
                </div>
                <div class="tab-pane fade" id="curl" role="tabpanel" aria-labelledby="curl-tab"
                  style="margin-top: 15px;">
                  <span>Install with curl</span>
                  <p>
                    <code><span>curl -sSL https://raw.githubusercontent.com/beopencloud/cno/main/scripts/cnoctl.sh | sh -s</span></code>
                    <button (click)="onCopy('curl', 8)">
                      <em class="bi" [ngClass]="{
                                    'bi-files': copyState == false || copiedIndex != 8,
                                    'bi-check-square': copyState == true && copiedIndex == 8
                                  }"></em>
                    </button>
                  </p>
                </div>
                <div class="tab-pane fade" id="release" role="tabpanel" aria-labelledby="release-tab"
                  style="margin-top: 15px;">

                  <span *ngIf="platformInfo.includes('Mac')" style="margin-top: 15px;">Install from the Release for
                    Mac</span>
                    <a class="releaseUrl" href="{{downloadReleaseForMac}}" *ngIf="platformInfo.includes('Mac')"> Download CNO for Mac  <em class="fa fa-download" style="margin-left: 10px;"></em></a>
                  <span *ngIf="platformInfo.includes('Linux')" style="margin-top: 15px;">Install from the Release for
                    Linux</span>
                    <a class="releaseUrl" href="{{downloadReleaseForLinux}}" *ngIf="platformInfo.includes('Linux')"> Download CNO for Linux <em class="fa fa-download" style="margin-left: 10px;"></em></a>
                  <span *ngIf="platformInfo.includes('Win')" style="margin-top: 15px;">Install from the Release for
                    Windows</span>
                    <a class="releaseUrl" href="{{downloadReleaseForWin}}" *ngIf="platformInfo.includes('Win')"> Download CNO for Windows <em class="fa fa-download" style="margin-left: 10px;"></em></a>
                </div>
              </div>
            </div>
            <div class="_htem">
              <em class="fa fa-link"></em>
              <span>Organization commands</span>
            </div>
            <div class="_btem">
              <span>Login with token </span>
              <p>
                <code>
                  <span>cnoctl login --token {{organizationToken}} --organization {{organizationName}}</span>
                </code>
                <button (click)="onCopy('tokenLogin', 1)">
                  <em class="bi" [ngClass]="{
                      'bi-files': copyState == false || copiedIndex != 1,
                      'bi-check-square': copyState == true && copiedIndex == 1
                    }"></em>
                </button>
              </p>

            </div>
          </div>
          <div class="line">
            <div class="_htem">
              <em class="fa fa-link"></em>
              <span>Project commands</span>
            </div>
            <div class="_btem">
              <span>Create a project</span>
              <p>
                <code>
                  <span>
                    cnoctl create project myproject</span
                  >
                </code>
                <button (click)="onCopy('newProject', 4)">
                  <em class="bi" [ngClass]="{
                      'bi-files': copyState == false || copiedIndex != 4,
                      'bi-check-square': copyState == true && copiedIndex == 4
                    }"></em>
                </button>
              </p>
              <span>Create an environment</span>
              <p>
                <code>
                  <span
                    >cnoctl create environment my-env --project myproject --prod false --cluster my-cluster</span
                  >
                </code>
                <button (click)="onCopy('newEnv', 5)">
                  <em class="bi" [ngClass]="{
                      'bi-files': copyState == false || copiedIndex != 5,
                      'bi-check-square': copyState == true && copiedIndex == 5
                    }"></em>
                </button>
              </p>
              <span>Connect to an environment</span>
              <p>
                <code>
                  <span>cnoctl use env -e my-env --project myproject</span>
                </code>

                <button (click)="onCopy('useEnv', 6)">
                  <em class="bi" [ngClass]="{
                      'bi-files': copyState == false || copiedIndex != 6,
                      'bi-check-square': copyState == true && copiedIndex == 6
                    }"></em>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
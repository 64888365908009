<div class="modal-content msg-content">
  <div class="modal-header msg-head align-items-center">
    <h5 class="modal-title msgtitle" id="exampleModalLabel">
      <button type="button" class="btn btn-primary link-btn mr-4" data-bs-toggle="modal" data-bs-target="#right">
        <i class="bi bi-pencil-fill"></i>
      </button>

      <span *ngIf="!isUpdateRepo">Add your code</span>
      <span *ngIf="isUpdateRepo">Update your code</span>

    </h5>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body" *ngIf="openForm">
    <form [formGroup]="openForm">
      <div class="row">
        <div class="col-md-12 col-xl-12 div-provider">
          <label> Choose your git provider* </label>
          <select class="" formControlName="host"  (change)="onSelectChange()" >

            <option *ngFor="let item of gitProviders; let i =index " [ngValue]="item" [disabled]="isUpdateRepo">
               {{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-12 col-xl-12 div-provider ">
          <label> Project* <i class="fa fa-spinner fa-spin" *ngIf="loadingProjects"></i></label>
          <input type="text" (input)="filterOptions()" class="form-control" type="text" placeholder="Select project..." formControlName="project" (click)="myFunction()" style="margin: 0px !important;font-size: 1rem !important;">

          <ul *ngIf="!isSelected && defaultOptions.length > 0" class="ulStyled">
            <li *ngFor="let option of defaultOptions" (click)="selectOption(option)">
              {{ option?.name }}
            </li>
          </ul>
          <ul *ngIf="!isSelected && showAddProjectButton" class="ulStyledNewProject" (click)="addNewProject()" >
            <li>Create new project : &nbsp;{{openForm.value.project}}</li>
          </ul>
        </div>

        <div class="col-md-12 col-xl-12 div-provider" *ngIf="this.registries.length != 1">
          <label> Registry* <i class="fa fa-spinner fa-spin" *ngIf="loadingRegistries"></i></label>
          <select class="" formControlName="registry" (change)="onChangeRegistry()">

            <option *ngFor="let item of registries; let i =index " [ngValue]="item" [attr.selected]="this.registries.length == 1 && onChangeRegistry()? true: null">
               {{ item.name?.charAt(0).toUpperCase() + item.name?.slice(1) }}
            </option>
          </select>
          <!-- <span class="span-styled" (click)="redirectToRegistryCreation()" >Create new registry</span> -->
        </div>

        <div class="col-md-12 col-xl-12 div-text">
          <label> Select desired Codes </label>
          <p>Please, Correctly select the framework at the risk of having impacts at the <br /> production level.</p>
        </div>
        <div class="col-md-12 col-xl-12 div-apps-input" *ngIf="isOtherProvider">
         <div class="row">
          <!-- <div class="col-md-6 col-xl-6 div-input" >
            <label> Code name*</label>
            <input id="app-name" [ngClass]="{'input-red': this.openForm.get('name').invalid }" class="form-control" type="text" autocomplete="off" formControlName="name" [readonly]="isUpdateRepo" placeholder="Code name*" style="margin: 0px !important;height: 2.7rem !important;" >
          </div> -->
          <!-- <div class="col-md-6 col-xl-6 div-input"  >
            <label> Branch ref*</label>
            <input id="branchref" class="form-control" type="text" autocomplete="off" formControlName="branchRef"  placeholder="Branch ref*" style="margin: 0px !important;height: 2.7rem !important;">
          </div> -->

          <div class="col-md-9 col-xl-9 div-input" >
            <label > Repo URL </label>
            <input id="repo-url" class="form-control" (input)="onRepoChange($event)" [readonly]="isUpdateRepo" type="text" autocomplete="off" formControlName="repository"  placeholder="Git clone URL*" style="margin: 0px !important;height: 2.7rem !important;" >
          </div>
          <div class="col-md-3 col-xl-3 div-input">
            <label class="fl-police"> Framework/Langage* </label>
            <select class="" formControlName="framework" >
              <option *ngFor="let item of frameworks; let i =index " [ngValue]="item">
                {{ item.name }}
              </option>
            </select>
          </div>
         </div>
        </div>
        <div class="col-md-12 col-xl-12 div-provider" *ngIf="!isOtherProvider">
            <div class="row">
              <div class="col-md-9 col-xl-9 div-input" >
                <label > Repo name* </label>
                <select class="" formControlName="repository" (change)="onRepoChange($event)">
                  <option *ngFor="let item of repositories; let i =index " [ngValue]="item" [disabled]="isUpdateRepo">
                    {{ item?.full_name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 col-xl-3 div-input">
                <label class="fl-police"> Framework/Langage* </label>
                <select class="" formControlName="framework" >
                  <option *ngFor="let item of frameworks; let i =index " [ngValue]="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

        </div>

        <div class="col-md-12 col-xl-12 div-provider">

          <button type="button" class="advanced" (click)="onChangeAdvance()"
          >
            <label style="margin-right: 10px;" [ngClass]="{'text-red': (openForm.get('name').invalid || openForm.get('imageName').invalid) && repoSelected}" >Advanced
              <i class="bi" [ngClass]="advanced == false ? 'bi-chevron-down': 'bi-chevron-up'"></i>
            </label>
          </button>
          <div *ngIf="advanced" >
            <div *ngIf="this.registries.length == 1 ">
              <label class="mt-2">Registry* <i class="fa fa-spinner fa-spin" *ngIf="loadingRegistries"></i></label>
              <select class="" formControlName="registry">
                <option *ngFor="let item of registries; let i =index " [ngValue]="item" [attr.selected]="this.registries.length == 1 && onChangeRegistry()? true: null">
                  {{ item.name?.charAt(0).toUpperCase() + item.name?.slice(1) }}
                </option>
              </select>
              <!-- <span class="span-styled" (click)="redirectToRegistryCreation()" >Create new registry</span> -->
            </div>
            <div class="row mt-4">
              <div class="col-md-12 col-xl-12 div-input">
                <label> App Name </label>
                  <input class="form-control"  type="text" autocomplete="off" formControlName="name" placeholder="App Name" style="margin: 0px 0px 5px 0px !important;">
                  <span *ngIf="openForm.get('name').invalid && openForm.value.name !== ''" style="font-size: 11; color: #ff523b;">Invalid name</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 col-xl-12 div-input">
                <label>Default Image URL</label>
                <div class="composed" (click)="toggleEdit(editableInput)" style="justify-content: left">
                  <span style="font-weight: 100; color:currentColor">{{repoUrl ? repoUrl: 'REGISTRY_REPO_URL'}}/</span>
                  <input type="text" autocomplete="off" formControlName="imageName" placeholder="Image Name" style="margin: 0px !important;height: 2.7rem !important;color: currentColor;border: aliceblue;" #editableInput>
                  <i class="bi-pencil-square edit" style="margin-left: auto;"></i>
                </div>
                <span *ngIf="openForm.get('imageName').invalid && openForm.value.imageName !== ''" style="font-size: 11; color: #ff523b;">Invalid image name</span>

              </div>
            </div>
          </div>
        </div>



        <div class="col-md-12 col-xl-12 div-button" >
          <button class="create-app" *ngIf="!isUpdateRepo" (click)="onCreateCode()"  [disabled]="loading || openForm.invalid" >Add code</button>
          <button class="create-app" *ngIf="isUpdateRepo" (click)="onUpdateCode()"  [disabled]="loading || openForm.invalid" >Update code</button>
        </div>
      </div>
    </form>


  </div>
</div>

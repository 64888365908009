<div class="modal-content">
  <div class="modal-header-switch">
    <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="onCloseDialog()">
      <i class="bi bi-x-lg"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="row ">
      <div class="col-sm-12 text-center">
        <p class="switch">Rollback <br> <span class="app-name">{{ app?.metadata.name }}</span></p>
      </div>

      <div class="col-md-1 text-center"></div>

      <div class="col-sm-12 text-center">
        <p class="description">
          You will return to a previous version and configuration, after confirming.
        </p>

        <div class="row">
          <div class="col-md-12 text-center">
            <section>
              <ul class="list-group">
                <li class="list-group-item live-status">
                  Live
                </li>
              </ul>
            </section>
          </div>

          <div class="col-md-12 text-center">
            <section>
              <ul class="list-group">
                <li class="list-group-item live-status">
                  <i class="fa fa-arrow-up"></i>
                </li>
              </ul>
            </section>
          </div>

          <div class="col-md-12 text-center">
            <section>
              <ul class="list-group">
                <li class="list-group-item live-status" *ngFor="let rec of app?.rollbackSpec?.liveContainers">
                  {{ rec.version }}
                </li>
              </ul>
            </section>
          </div>
        </div>
        <button type="button" class="btn btn-success mx-1 confiem-btn mt-3" (click)="onConfirm()" [disabled]="loading">
          Confirm
          <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
            *ngIf="loading">
            <span class="sr-only">Loading...</span>
          </span>
        </button>
      </div>

      <div class="col-md-1 text-center"></div>
    </div>
  </div>
</div>

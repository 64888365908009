import { Component, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type, regions } from 'src/app/utils/const';
import { Registry } from 'src/app/utils/types';
import { ShareService } from 'src/app/data/service/share.service';
import * as moment from 'moment';
import { AppService } from '../../app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;
export function equalsTo(name: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value?.toLowerCase() === name?.toLowerCase()
      ? null
      : { wrongColor: control.value };
}

@Component({
  selector: 'app-rregistry-form-modal',
  templateUrl: './registry-form-modal.component.html',
  styleUrls: ['./registry-form-modal.component.scss'],
})
export class RegistryFormModalComponent {
  public event: EventEmitter<any> = new EventEmitter();
  formSubmited: boolean = false;
  registryForm: UntypedFormGroup;
  error: any = null;
  currentModal: string = '';
  registryLoaded: boolean = false;
  loading: boolean = false;
  deleteRegistryForm: UntypedFormGroup;
  formDisable: boolean = false;
  gcpServiceAccountFile: any;
  fileName = 'Select file';
  registryTypes = [
    { label: 'Docker', value: 'docker' },
    { label: 'Harbor', value: 'harbor' },
    { label: 'ECR', value: 'ecr' },
    { label: 'GCR', value: 'gcr' },
    { label: 'ACR', value: 'acr' },
  ];
  registry: Registry;
  showPassword = false;

  awsRegions = regions.filter((m) => m.cloudprovider == 'aws');

  constructor(
    private modalRef: BsModalRef,
    private service: AsideExtenderService,
    private appService: AppService,
    private shareService: ShareService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.onChangeRegistryType(
      this.registry?.id ? this.registry?.registryType : 'docker'
    );
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  initForm(): void {
    this.registryForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.registry?.id ? this.registry?.name : '',
        Validators.required
      ),
      username: new UntypedFormControl(
        this.registry?.id ? this.registry?.username : '',
        Validators.required
      ),
      repo: new UntypedFormControl(
        this.registry?.id ? this.registry?.repo : '',
        Validators.required
      ),
      password: new UntypedFormControl(
        this.registry?.id ? this.registry?.password : '',
        Validators.required
      ),
      registryType: new UntypedFormControl(
        this.registry?.id ? this.registry?.registryType : 'docker',
        Validators.required
      ),
      gcpServiceAccountJsonKeyFile: new UntypedFormControl(
        '',
        Validators.required
      ),
      region: new UntypedFormControl(
        this.registry?.id ? this.registry?.region : '',
        Validators.required
      ),
    });

    this.gcpServiceAccountFile = '';
    this.fileName = 'Select file';
  }

  onChangeRegistryType(type) {
    this.initForm();

    this.registryForm.get('registryType').setValue(type);

    if (type == 'ecr') {
      this.removeValidators(['gcpServiceAccountJsonKeyFile', 'repo']);
    }

    if (type == 'gcr') {
      this.removeValidators(['region', 'username', 'password']);
    }

    if (['docker', 'harbor', 'acr'].includes(type)) {
      this.removeValidators(['gcpServiceAccountJsonKeyFile', 'region']);
    }
  }

  removeValidators(fields): void {
    if (fields.length > 0) {
      fields.forEach((field) => {
        this.registryForm.get(field).clearValidators();
        this.registryForm.get(field).updateValueAndValidity();
      });

      this.registryForm.updateValueAndValidity();
    }
  }

  onUploadFile(event): void {
    this.fileName = event.target.files[0]?.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.gcpServiceAccountFile = reader.result;
      this.registryForm
        .get('gcpServiceAccountJsonKeyFile')
        .setValue(this.fileName);
    };
  }

  formatDateToHuman(dateStr) {
    const date = moment(dateStr);
    return date.fromNow();
  }

  onSubmit(): void {
    if (this.registryForm?.valid) {
      const formValue = this.registryForm.value;
      const dataToSend = {
        name: formValue.name,
        organizationId: this.shareService.organizationSelected.id,
        password: formValue.password,
        repo: formValue.repo,
        username: formValue.username,
        registryType: formValue.registryType,
      };

      if (formValue.registryType == 'ecr') {
        dataToSend['region'] = formValue.region;
      }

      if (formValue.registryType == 'gcr') {
        if (this.gcpServiceAccountFile) {
          dataToSend['gcpServiceAccountJsonKeyFile'] =
            this.gcpServiceAccountFile.replace(
              'data:application/json;base64,',
              ''
            );
        }
      }

      this.loading = true;
      if (this.registry?.id) {
        this.appService.updateRegistry(dataToSend, this.registry?.id).subscribe(
          (_) => {
            this.event.emit({ appId: _, res: 200 });
            this.registry = {} as Registry;
            this.loading = false;
            this.initForm();
            this.onCloseDialog();
            this.error = null;
            this.service.show({
              title: 'Update Registry',
              message: 'Registry updated successfully',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
          },
          (er) => {
            this.loading = false;
            this.error = er;
            this.service.show({
              title: 'Update Registry',
              message: er.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
          }
        );
      } else {
        this.appService.createRegistry(dataToSend).subscribe(
          (_) => {
            this.loading = false;
            this.initForm();
            this.event.emit({ appId: _, res: 200 });
            this.onCloseDialog();
            this.error = null;
            this.service.show({
              title: 'Add Registry',
              message: 'Registry created successfully',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
          },
          (er) => {
            this.loading = false;
            this.error = er;
            this.service.show({
              title: 'Add Registry',
              message: er.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
          }
        );
      }
    }
  }
}

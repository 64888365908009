<div class="select-wrapper">
  <div class="input-wrapper" [class]="selectClass" [class.disable]="disable">
    <input type="text" class="input" [class]="selectClass" [placeholder]="placeholder" [disabled]="disable" [(ngModel)]="label"
      [readonly]="!filtering" [class.cursor]="filtering ? 'cursor-text' : 'cursor-default'" (focus)="showOptions()"
      (input)="onFilterChange()">
    <small class="plus" *ngIf="multiple && labels.length > 3">
      +{{ labels.length - 3 }}
    </small>
    <i class="icon" *ngIf="!disable" (click)="optionsVisible = !optionsVisible" [ngClass]="optionsVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" ></i>
  </div>
  <div class="select-options" *ngIf="loader">
    <span class="fa fa-spinner fa-spin"></span>
  </div>
  <div *ngIf="!loader" class="select-options" [class]="selectClass" [class.show]="optionsVisible" [class.disable]="disable">
    <div class="option" *ngFor="let option of tempOptions" [class.active]="activeOption(option)" (click)="onOptionChange(option)">
      {{ optionLabel ? option[optionLabel] : option }}
    </div>
    <div *ngIf="tempOptions.length === 0">
      <span>{{ filtering ? noResultString : noDataString }}</span>
    </div>
    <ng-content></ng-content>
  </div>
</div>

import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Workload } from 'src/app/data/model/workload';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { CdService } from 'src/app/modules/deploy/services/cd.service';
import { Position, Type } from 'src/app/utils/const';

@Component({
  selector: 'app-modal-rollback',
  templateUrl: './modal-rollback.component.html',
  styleUrls: ['./modal-rollback.component.scss']
})
export class ModalRollbackComponent implements OnInit {
  app: Workload;
  loading = false;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalRef: BsModalRef,
    private cdService: CdService,
    private service: AsideExtenderService) {}

  ngOnInit(): void {

  }

  onCloseDialog() {
    this.modalRef.hide();
  }

  onConfirm(): void {
      const ids = {
        projectID: this.app.projectId,
        envID: this.app.environmentId,
        workloadID: this.app.metadata.uid,
      };

      this.loading = true;
      this.cdService.rollWorkload(ids).subscribe(
        (response) => {
          this.onCloseDialog();
          this.service.show({
            title: 'Workload',
            message: `${this.app?.metadata?.name} has been rollback`,
            type: Type.SUCCESS,
            position: Position.TOP,
          });
          this.loading = false;
          this.event.emit({ appId: response, res: 100 });
        },
        (error) => {
          this.service.show({
            title: 'Workload',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.loading = false;
        }
      );
  }

}

import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CHAGEBEE_PLANS } from '../../../../app/utils/const';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Position, Type } from 'src/app/utils/const';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { ShareService } from 'src/app/data/service/share.service';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { LoginService } from 'src/app/auth/pages/login/services/login.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss'],
})
export class UpgradePlanComponent implements OnInit, OnDestroy {
  close = true;
  chargebeePlans = CHAGEBEE_PLANS;
  planPer: any;
  teamPrice = 396;
  loading: boolean = false;
  entrepriseForm: FormGroup;
  nIntervId: any;
  currentOrganization: any;
  selectionPer = [
    { name: 'month', value: 1 },
    { name: 'year', value: 12 },
  ];
  selectedPlanPer = { name: 'month', value: 1 };
  selectPerForm: FormGroup;
  detailsFree = [
    'Only Auto Deploy & Blue/Green',
    'Unlimited Users',
    'Up to 200 Deployments',
    '1 Cluster or Cloud Provider',
    'Slack Channel Support',
  ];
  detailsTeams = [
    'Auto Deploy, Blue/Green, Canary & A/B Testing',
    'Unlimited Users',
    'Up to 2000 Deployments',
    '3 Clusters/Cloud providers',
    'Dedicated Slack Channel Support',
  ];
  detailsEntreprise = [
    'Auto Deploy, Blue/Green, Canary & A/B Testing',
    'Unlimited Users',
    'Unlimited Deployments',
    'Unlimited Clusters',
    '24/7 Support',
  ];

  constructor(
    public modalRef: BsModalRef,
    public modalRef2: BsModalRef,
    public auth: AppAuthGuardService,
    private modalService: BsModalService,
    public shareService: ShareService,
    private router: Router,
    private authService: AppAuthGuardService,
    private asideExtenderService: AsideExtenderService,
    private organizationService: OrganizationService
  ) {}

  ngOnDestroy(): void {
    if (this.shareService.isFree) {
      // this.shareService.logout()
    }
  }

  ngOnInit(): void {
    this.currentOrganization = this.shareService.organizationSelected;
    this.selectPerForm = new FormGroup({
      selectedPlanPer: new FormControl({
        value: this.selectionPer[0],
        disabled:
          this.auth?.hasOrganizationRoles('super_admin') &&
          this.currentOrganization?.subscriptionType.includes('team'),
      }),
      numberOfUser: new FormControl(1),
    });
  }

  async onUpgradePlan() {
    this.modalRef.hide();

    const currentPath = window.location.origin + '/dashboard';

    let hostedPage = await this.organizationService
      .askHostedPageForUpdateSubscriptionFromChargebee(
        this.chargebeePlans.proMonthly,
        currentPath
      )
      .toPromise()
      .catch((error) => {
        this.asideExtenderService.show({
          type: Type.ERROR,
          message: 'Chargebee failed',
          title: error.message,
          position: Position.TOP,
        });
      });

    window.location.replace(hostedPage.url);
  }

  onCloseDialog(): void {
    this.modalRef.hide();
    this.modalRef2.hide();
  }

  onCloseDialog2(): void {
    this.modalRef2.hide();
  }

  openModal2(template: TemplateRef<any>, plan: any) {
    // let currentPlan = this.currentOrganization.subscriptionType;
    // if (currentPlan.includes('team')) {
    //   this.onUpgradePlan();
    // } else {
    const validations: any[] = [
      Validators.pattern('[a-za-z0-9][-a-za-z0-9.]*'),
      Validators.required,
    ];
    this.entrepriseForm = new FormGroup({
      organizationName: new FormControl('', validations),
      plan: new FormControl(plan),
    });

    this.modalRef2 = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
    // }
  }

  onSubmit() {
    let formData = this.entrepriseForm.value;

    let plan = this.chargebeePlans.proMonthly;
    if (formData.plan != 'pro') {
      plan = this.chargebeePlans.teamsMonthly;
      // plan = this.selectPerForm.get('selectedPlanPer').value.name == 'month' ? this.chargebeePlans.teamsMonthly : this.chargebeePlans.teamsYearly;
    }

    let organization = {
      name: formData.organizationName,
      subscriptionType: plan,
      isContacted: false,
    };

    this.loading = true;
    this.organizationService.create(organization).subscribe(
      async (response) => {
        this.modalRef.hide();
        this.modalRef2.hide();
        this.loading = false;
        this.asideExtenderService.show({
          type: Type.SUCCESS,
          title: 'Create Organization',
          message: 'Your organization has been created successfully',
          position: Position.TOP,
        });

        const session = this.shareService.getSession();
        this.shareService.setOrganizationSelected(response, session);

        if (environment.local) {
          this.router.navigate(['/dashboard']);
        } else {
          const url = `${environment.schemas}://${response.name}.${environment.baseUrl}/#/dashboard`;
          window.location.replace(url);
        }
      },
      (error) => {
        this.loading = false;
        this.asideExtenderService.show({
          type: Type.ERROR,
          title: 'Create Organization',
          message: error.error,
          position: Position.TOP,
        });
      }
    );
  }

  OnChangePer(event: any) {
    this.planPer = event.target.value;
  }
}

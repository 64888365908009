import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'view-statement',
  templateUrl: './view-statement.component.html',
  styleUrls: ['./view-statement.component.scss']
})
export class ViewStatementComponent implements OnInit {

  @Input() element: string;
  @Input() size: string;


  constructor() { }

  ngOnInit(): void {
  }

}

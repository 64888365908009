<div class="modal-content">
  <div class="modal-header">
    <button
      type="button"
      class="m-0 p-0 popup-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="onCloseDialog()"
    >
      <i class="bi bi-x-lg"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="invite-popup">
      <h3 class="heading">Invite members to join your organization</h3>
      <form [formGroup]="inviteForm">
        <input
          type="text"
          class="form-control"
          placeholder="louisane@gocno.io"
          formControlName="email"
        />

        <select formControlName="privilege">
          <option value="" disabled selected hidden>Select your option</option>
          <option
            *ngFor="let item of privileges"
            [ngValue]="item.privileges[0]"
            [selected]="item?.value === 'Member'"
          >
            {{ item?.value }}
          </option>
        </select>
      </form>
      <!-- <input type="text" class="form-control" placeholder="louisane.salagnax@beopenit.com" [formControl]="emailFC"> -->

      <div class="attachment-link text-center">
        <img
          class="attachment-icon"
          src="/assets/img/attachment.svg"
          alt="attachment-icon"
        />
        <a (click)="memberInvitation()">
          Send a link to invite your teams by email.
        </a>
      </div>
    </div>
  </div>
</div>

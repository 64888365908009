import { Component, OnDestroy, OnInit } from '@angular/core';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position } from 'src/app/utils/const';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit , OnDestroy{

  types = [
    { ref: 'error', icon: 'bi bi-x-circle-fill' },
    { ref: 'success', icon: 'bi bi-check-circle-fill' },
    { ref: 'info', icon: 'bi bi-exclamation-circle-fill'},
    { ref: 'warning' , icon: 'bi bi-exclamation-triangle-fill'}
  ];

  currentState:any;

  position: string;
  title: any;
  message: string;
  subscription: any;
  hide : boolean = false;

  constructor(private service: AsideExtenderService) { }



  ngOnInit(): void {
    this.checkType();
  }

  get classes() {
    return {
      'success': this.currentState?.ref == 'success',
      'error': this.currentState?.ref == 'error',
      'warning': this.currentState?.ref == 'warning',
      'info': this.currentState?.ref == 'info',
      'top': this.position == Position.TOP,
      'bottom': this.position == Position.BOTTOM,
      'hidden': this.hide == true
    }
  }

  checkType(): void {
    this.subscription = this.service.notify.subscribe((_) => {
      this.hide = false;
      this.currentState = this.types.filter(m => m?.ref == _?.options?.type)[0];
      this.position = _?.options?.position;
      this.title = _?.options?.title;
      this.message = _?.options?.message;
      let self = this;
      setTimeout(function() {
          self.hide = true
      },12000);
    });
  }



  close() {
   this.hide = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

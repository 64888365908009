export function decodeToBase64(text: string) {
  if (isBase64(text)) {
    let textDecoder = new TextDecoder();
    const binString = atob(text);
    const textDecoded = textDecoder.decode(
      Uint8Array.from(binString, (m) => m.codePointAt(0))
    );

    return textDecoded;
  } else {
    return text;
  }
}

export function encodeToBase64(text: string) {
  let textEncoder = new TextEncoder();
  let byte64 = textEncoder.encode(text);

  const binString = Array.from(byte64, (byte) =>
    String.fromCodePoint(byte)
  ).join('');

  return btoa(binString);
}

export function isBase64(text) {
  if (text === '' || text.trim() === '') {
    return false;
  }
  try {
    return btoa(atob(text)) == text;
  } catch (err) {
    return false;
  }
}

import { Injectable } from '@angular/core';
import { ShareService } from '../../../data/service/share.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetRecordsModel } from 'src/app/data/model/GetRecordsModel';
import { Workload } from 'src/app/data/model/workload';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  baseURL: string;
  maxItems : any;
  defaultPage = 0;
  contextValue: any;

  constructor(
    private shareService: ShareService,
    private httpClient: HttpClient) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }

  getAllApps(): Observable<GetRecordsModel<Workload>> {
    return this.httpClient.get<GetRecordsModel<Workload>>(
      this.baseURL + this.shareService.organizationSelected.id + '/workloads?page=' + this.defaultPage + '&limit=' + this.maxItems);
  }

}

<div class="modal-content msg-content">
  <div class="modal-header msg-head align-items-center">
    <h5 class="modal-title msgtitle" id="exampleModalLabel">
      <button type="button" class="btn btn-primary link-btn mr-4" data-bs-toggle="modal" data-bs-target="#right">
        <i class="bi bi-pencil-fill"></i>
      </button>

      <span>Config SAML</span>
    </h5>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body">

    <form class="msg-acc-form mt-4" [formGroup]="configForm">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10 col-sm-12">
          <div class="form-group mb-4">
            <label class="msg-acc-label">Entity ID<sup>*</sup></label>
            <input formControlName="entityId" class="form-control" placeholder="Entity ID" />
          </div>
          <div class="form-group mb-4">
            <label class="msg-acc-label">Url SSO<sup>*</sup></label>
            <input formControlName="urlSso" class="form-control" placeholder="Url SSO" />
          </div>

          <div class="form-group mb-4">
            <label class="msg-acc-label">Certificate<sup>*</sup></label>
            <textarea class="form-control" formControlName="certificate" style="height: 150px !important;" name="" rows="4" placeholder="Certificate"></textarea>
          </div>

          <button type="button" (click)="onTest()" [disabled]="loadingTest" class="btn-sm btn-primary mb-3 test"
            *ngIf="!tested || saml?.samlEntityID">
            <span>Test</span>
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loadingTest">
              <span class="sr-only">Loading...</span>
            </span>
          </button>

          <button type="button" (click)="onSubmit()" [disabled]="configForm.invalid || loading" class="form-control btn btn-primary submit">
            <span>Save</span>
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </span>
          </button>

          <button class="delete mt-4" (click)="onOpenDeleteModal(template)" *ngIf="!confirmDelete && saml?.samlEntityID">
            <i class="fa fa-times"></i>
            Delete configuration
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #template>
  <div class="modal-content modal2">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog2()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup">
        <h3 class="heading">Are you sure ?</h3>
        <div class="delete-text">
          Delete SAML Configuration
        </div>
        <div class="attachment-link d-flex justify-content-between mt-3">
          <button class="btn-delete" [disabled]="loaderDelete" type="button" (click)="deleteSamlConf()">
            <span>Delete</span>
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loaderDelete">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
          <button class="btn-cancel" (click)="onCloseDialog2()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

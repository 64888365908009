export const cpuUnits = [
  {
    key: 'core',
    value: ''
  },
  {
    key: 'mCore',
    value: 'm',
    default: true
  }
];

export const memoryUnits = [
  {
    key: 'Gi',
    value: 'Gi'
  },
  {
    key: 'G',
    value: 'G'
  },
  {
    key: 'Mi',
    value: 'Mi',
    default: true
  },
  {
    key: 'M',
    value: 'M'
  }
];

export const GetValueAndUnit = (data: string): {value: number, unity: string} => {
  switch (data.substring(data.length - 1, data.length)) {
    case 'm': {
      return {
        value: parseFloat(data.substring(0, data.length - 1)),
        unity: data.substring(data.length - 1, data.length)
      };
    }
    case 'M': {
      return {
        value: parseFloat(data.substring(0, data.length - 1)),
        unity: data.substring(data.length - 1, data.length)
      };
    }
    case 'G': {
      return {
        value: parseFloat(data.substring(0, data.length - 1)),
        unity: data.substring(data.length - 1, data.length)
      };
    }
    case 'i': {
      switch (data.substring(data.length - 2, data.length)) {
        case 'Mi': {
          return {
            value: parseFloat(data.substring(0, data.length - 2)),
            unity: data.substring(data.length - 2, data.length)
          };
        }
        case 'Gi': {
          return {
            value: parseFloat(data.substring(0, data.length - 2)),
            unity: data.substring(data.length - 2, data.length)
          };
        }
        default: {
          console.log('unit not managed', data.substring(data.length - 2, data.length));
          return {
            value: NaN,
            unity: ''
          };
        }
      }
    }
    default: {
      if (!isNaN(Number(data))) {
        return {
          value: Number(data),
          unity: ''
        };
      }
      console.log('unit not managed', data.substring(data.length - 1, data.length));
      console.log('error on value', data);
      return {
        value: NaN,
        unity: 'not'
      };
    }
  }
};

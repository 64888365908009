export class App {
  id: string;
  metadata: Metadata = new Metadata();
  organizationId: string;
  environmentName: string;
  buildConfigId: string;
  projectId: string;
  codeId: string;
  environmentId: string;
  registryId: string;
  createdDate: string;
  lastUpdatedDate: string;
  createdBy: string;
  lastUpdatedBy: string;
  spec: AppSpec = new AppSpec();
  action: string;
  // deploy app: name, namespace, environmentId, registryId,spec
}

export class Metadata {
  name: string;
  namespace: string;
}

export class AppSpec {
  nameOverride: string;
  port: number;
  image: ImageType = new ImageType();
  ingress: IngressType = new IngressType();
  // {key1: value1, key2: value2 }
  env: any;
  // SPRING_DB_PASSWORD: string SPRING_DB_URL: string
  secret: Object;
  lifecycle: LifeCycle = new LifeCycle();
}

export class IngressType {
  enabled: boolean;
  host: string;
  annotations: object;
  labels: object;
  paths: IngressPath[];
  secretName: string;
}
export class IngressPath {
  path: string;
}

export class ImageType {
  repository: string;
  tag: string;
}

export class LifeCycle {
  postStart: PostStart = new PostStart();
}

export class PostStart {
  command: string;
}

<div class="container-fluid bg-main" >
    <div class="row">
        <div class="col-md-5 bg-col-1" >
            <div class="bg-img">
                <img src="../../../../assets/img/cno.svg" class="cnoimg-styl">

            </div>
            <div class="text-application">
                <p>Which application will <br> we deploy today?</p>
            </div>
        </div>
        <div class="col-md-7 bg-col-2">
            <div class="container-div" >
               <div style="color: #199E58; text-align: center;">
                <h2 style="font-weight: 600;">The last step, add <br> your cluster & enjoy</h2>
               </div>
                <div style="display: flex; flex-direction: row; margin-bottom: 10px;">
                    <button
                    class="leftButton"
                    (click)="cloudButton()"
                    [style.color]="isFirstClick || isFirstClick == null  ? '#fff' : '#00A150 '"
                    [style.background]="isFirstClick || isFirstClick == null  ? '#00A150' : '#fff'"
                    >Cloud registration</button>
                    <button
                    class="rightButton"
                    (click)="onpemButton()"
                    [style.color]="isFirstClick == false ? '#fff' : '#00A150 '"
                    [style.background]="isFirstClick == false  ? '#00A150' : '#fff'"
                    >On-prem registration</button>
                </div>


                <div *ngIf="isFirstClick == null" style="display: flex; flex-direction: column;">

                    <button class="buttonStyled" (click)="onChangeCloud('aws')">
                        <span style="margin: 0px 20px;" ><img src="../../../../../assets/img/aws.svg"  ></span> <p class="pstyled" >Add an existing cluster from AWS</p>
                    </button>

                    <button class="buttonStyled"  (click)="onChangeCloud('gcp')">
                        <span style="margin: 0px 20px;"><img src="../../../../../assets/img/gcp.svg"  ></span> Add an existing cluster from GCP
                    </button>

                    <button class="buttonStyled" (click)="onChangeCloud('azure')">
                        <span style="margin: 0px 20px;"><img src="../../../../../assets/img/Tracé-azure.svg"  ></span> <p  class="pstyled">Add an existing cluster from Azure</p>
                    </button>

                    <button class="buttonConfirm" (click)="goToDashboard()">
                        Skip this step.
                    </button>
                </div>

                <div *ngIf="isFirstClick == false" class="formdiv" style="display: flex; width: 22rem; justify-content: center;margin-top: 10px;">
                  <form  [formGroup]="clusterForm" style="width: inherit;" >

                  <div class="form-group">
                    <input id="name" type="text" class="form-control on-prem" placeholder="Name*" autocomplete="off" formControlName="name">
                  </div>
                  <div class="form-group">
                    <select class="form-control select-btn" formControlName="type" id="type" onfocus='this.size=1;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                      <option value="" >{{'Cluster type'}}</option>
                      <option [value]="item?.name" *ngFor="let item of cloudType; let i =index ">{{item?.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input id="apiServerUrl" type="text" class="form-control on-prem" placeholder="API server URL *" autocomplete="off" formControlName="apiServerUrl">
                  </div>
                    </form>
                    <button class="buttonConfirm" (click)="AddMyCluster()">
                        Add my cluster.
                    </button>
                    <button class="skip" (click)="goToDashboard()">
                        Skip this step.
                    </button>
                </div>


                <div *ngIf="isFirstClick == true" class="formdiv" >
                    <select
                    (change)="cloudChange($event)"
                    class="input-select"
                    [formControl]="selectControl"
                  >
                    <option value="aws" >AWS</option>
                    <option *ngFor="let item of items" [value]="item.value">
                    {{ item.name }}
                    </option>
                  </select>
                  <div class="form" >
                                          <!-- aws section  -->
                    <form [formGroup]="awsProviderForm"  style="width: inherit;" *ngIf="selectControl.value == 'aws'" >
                      <div class="form-group">
                        <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
                      </div>
                      <div class="form-group">
                        <select class="form-control" formControlName="defaultRegion" id="defaultRegion" onfocus='this.size=1;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                          <option value="" selected disabled>{{'Default region*'}}</option>
                          <option [value]="item?.name" *ngFor="let item of regions; let i =index ">{{item?.label}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <input id="accessKey" type="text" class="form-control" placeholder="Access Key*" autocomplete="off" formControlName="accessKey">
                      </div>

                      <div class="form-group">
                        <input id="secretKey" type="password" class="form-control" placeholder="Secret Key*" autocomplete="off" formControlName="secretKey">
                      </div>
                      <div class="form-group">
                        <input id="sessionToken" type="text" class="form-control"  autocomplete="off" formControlName="sessionToken"  placeholder="Session Token" >
                      </div>
                    </form>
                    <!-- azure section  -->
                      <form [formGroup]="azureProviderForm" style="width: inherit;" *ngIf="selectControl.value== 'azure'">
                        <div class="form-group">
                          <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
                        </div>
          
                        <div class="form-group">
                          <input id="clientId" type="text" class="form-control" placeholder="Client ID*" autocomplete="off" formControlName="clientId">
                        </div>
          
                        <div class="form-group">
                          <input id="clientSecret" type="text" class="form-control"  autocomplete="off" formControlName="clientSecret"  placeholder="Client Secret" >
                        </div>
                        <div class="form-group">
                          <input id="subscription" type="text" class="form-control" placeholder="Subscription ID*" autocomplete="off" formControlName="subscription">
                        </div>
                        <div class="form-group">
                          <input id="tenant" type="text" class="form-control" placeholder="Tenant ID*" autocomplete="off" formControlName="tenant">
                        </div>
                        <div class="form-group">
                          <input id="resourcegroup" type="text" class="form-control" placeholder="Resource Groupe name*" autocomplete="off" formControlName="resourcegroup">
                        </div>
                      </form>
                      <!-- gcp section  -->

                      <form [formGroup]="gcpProviderForm"  style="width: inherit;" *ngIf="selectControl.value == 'gcp'">
                        <div class="form-group">
                          <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
                        </div>
                        <input type="file" style="display: none;" #file (change)="upload($event)">
                        <div class="form-group file-field mt-3">
                            <input type="text" class="form-control" [value]="filepreview" placeholder="File" (click)="file.click()">
                            <em class="fa fa-download" (click)="file.click()"></em>
                        </div>
                      </form>

                  </div>
                    <div style="display: flex;flex-direction: row;">
                      <button class="buttonConfirm" (click)="existingCloudProvider?.cloudprovider == selectControl.value ? update() : save()" [disabled]="loading">
                        Connect your cloud provider.
                      </button>
                      <div class="spinner-border text-primary spinner-style" role="status" *ngIf="loading">
                          <span class="sr-only">Loading... </span>
                        </div>
                  </div>

                    <button class="skip" (click)="goToDashboard()">
                        Skip this step.
                    </button>
                </div>

            </div>

        </div>
    </div>

</div>

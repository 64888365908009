import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShareService } from './share.service';
import { Organization } from '../model/organization';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from '../model/Session';
import { AsideExtenderService } from '../service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import { param } from 'jquery';
import {environment} from '../../../environments/environment';
import { GetRecordsModel } from '../model/GetRecordsModel';
@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  orgUrl: string;
  email: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  constructor(
    private httpClient: HttpClient,
    private shareService: ShareService,
    private router: Router,
    private service: AsideExtenderService,
    private route: ActivatedRoute
  ) {
    this.orgUrl = '/api/v1/organizations/';
  }

  create(organization: any): Observable<any> {
    const url = `${this.shareService.apiBaseURL}${this.orgUrl}create`;
    return this.httpClient.post(url,organization);
  }

  update(id: any, form: any): Observable<any> {
    const url = `${this.shareService.apiBaseURL}${this.orgUrl}${id}/update`;
    return this.httpClient.put(url, form);
  }

  delete(id: string): Observable<object> {
    const url = `${this.shareService.apiBaseURL}${this.orgUrl}${id}/delete`;
    return this.httpClient.delete(url, this.httpOptions);
  }

  configureAuthSaml(id: any, configSaml: any): Observable<any> {
    return this.httpClient.put(
      this.shareService.apiBaseURL + this.orgUrl + id + '/auth/saml',
      configSaml
    );
  }

  getConfAuthSaml(id: any): Observable<any> {
    return this.httpClient.get(
      this.shareService.apiBaseURL + this.orgUrl + id + '/auth/saml'
    );
  }

  deleteConfAuthSaml(id: any): Observable<any> {
    return this.httpClient.delete(
      this.shareService.apiBaseURL + this.orgUrl + id + '/auth/saml'
    );
  }

  getAllOrganizations(page?: any,limit?: any,search?: any): Observable<GetRecordsModel<Organization>> {
    const url = `${this.shareService.apiBaseURL}${this.orgUrl}/all?page=${page}&limit=${limit}&search=${search}`;
    return this.httpClient.get<GetRecordsModel<Organization>>(url);
  }

  getOrganizations(
    page?: any,
    limit?: any,
    search?: any
  ): Observable<Organization[]> {
    return this.httpClient.get<Organization[]>(
      this.shareService.apiBaseURL +
        this.orgUrl +
        '?page=' +
        page +
        '&limit=' +
        limit +
        '&search=' +
        search
    );
  }
  getOrganizationById(id: string): Observable<Organization> {
    return this.httpClient.get<Organization>(
      this.shareService.apiBaseURL + this.orgUrl + id
    );
  }

  makeOrganizationConfigComplited(): Observable<Object> {
    return this.httpClient.put(
      this.shareService.apiBaseURL +
        this.orgUrl +
        this.shareService.organizationSelected.id +
        '/completed',
      this.shareService.organizationSelected,
      this.httpOptions
    );
  }

  getUrlQueryParam(param: string): string {
    var paramsString: string;
    if (window.location.href.split('?').length > 1) {
      paramsString = window.location.href.split('?')[1];
    } else {
      return '';
    }
    let paramsArray = paramsString.split('&');
    for (let i = 0; i < paramsArray.length; i++) {
      let keyValue = paramsArray[i].split('=');
      if (keyValue.length == 2 && keyValue[0] == param) {
        return keyValue[1];
      }
    }

    return '';
  }
  async fetchOrganizations(session: Session): Promise<void> {
    this.shareService.organizations = await this.getOrganizations().toPromise();
    if (this.shareService.organizations?.length === 0) {
      this.service.show({
        title: 'Organizations',
        position: Position.TOP,
        type: Type.ERROR,
        message: `Organization not found.`,
      });
      this.shareService.logout();
      return;
    }

    this.email = session.currentUser.email;
    // var organizationId = localStorage.getItem(this.email + '-organizationId');
    // let currentOrganization = this.shareService.organizations.find((org) => {
    //   return org.id == organizationId;
    // });

    let currentOrganization: Organization;
    // this.route.snapshot.queryParamMap.get('organizationId');
    if (this.getUrlQueryParam('organizationId')) {
      const organizationId = this.getUrlQueryParam('organizationId');
      currentOrganization = this.shareService.organizations.find((org) => {
        return org.id == organizationId;
      });
    } else {
      if (!environment.local) {
        let orgName = window.location.hostname.replace(
          environment.baseUrl.split(':')[0],
          ''
        );
        orgName = orgName.replace('www.', '');
        if (orgName.length > 0) {
          orgName = orgName.replace('.', '');
        }
        if (orgName.length == 0) {
          orgName = 'default';
        }
        currentOrganization = this.shareService.organizations.find((org) => {
          return org.name === orgName;
        });
        if (!currentOrganization) {
          // this.shareService.removeSession();
          this.router.navigate(['/auth/unknown-organization']);
          return;
        }
      } else {
        const orgName = localStorage.getItem('organization');
        if (orgName) {
          currentOrganization = this.shareService.organizations.find((org) => {
            return org.name === orgName;
          });
        }
        if (!currentOrganization) {
          currentOrganization = this.shareService.organizations[0];
        }
      }
    }
    if (currentOrganization) {
      this.shareService.setOrganizationSelected(currentOrganization, session);
    }
  }

  checkSubscription(orgId: any) {
    let prefixUrl = this.shareService.apiBaseURL + this.orgUrl + orgId;
    return this.httpClient.put<any>(prefixUrl + '/check-subscription', {});
  }

  askHostedPageForCreateSubscriptionFromChargebee(
    redirectUrl: string,
    organisation
  ) {
    let prefixUrl =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id;
    const url = prefixUrl + '/subscription/create?redirect-url=' + redirectUrl;
    return this.httpClient.post<any>(url, organisation, this.httpOptions);
  }

  askHostedPageForUpdateSubscriptionFromChargebee(
    plan: string,
    redirectUrl: string
  ) {
    let prefixUrl =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id;
    return this.httpClient.put<any>(
      prefixUrl +
        '/subscription/update?plan=' +
        plan +
        '&redirect-url=' +
        redirectUrl,
      this.shareService.organizationSelected,
      this.httpOptions
    );
  }

  askPortalSessionFromChargebee(redirectUrl: string) {
    let prefixUrl =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id;
    return this.httpClient.get<any>(
      prefixUrl + '/billing-account?redirect-url=' + redirectUrl
    );
  }
  getOrganizationInfoByName(name: string) {
    return this.httpClient.get<any>(
      this.shareService.apiBaseURL + this.orgUrl + 'name/' + name
    );
  }

  getSAMLConfigurationByOrganizationId(id: string) {
    return this.httpClient.get<any>(
      this.shareService.apiBaseURL +
        this.orgUrl +
        id +
        '/auth/saml/isconfigured'
    );
  }

  getOrganizationToken() {
    return this.httpClient.get<any>(
      this.shareService.apiBaseURL + this.orgUrl + 'iam/userToken'
    );
  }
  createSendMessagingAccounts(body: any) {
    const url =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id +
      '/messagingaccounts';
    return this.httpClient.post(url, body);
  }
  getMessagingAccount() {
    const url =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id +
      '/messagingaccounts';
    return this.httpClient.get(url);
  }
  deleteSendMessagingAccounts() {
    const url =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id +
      '/messagingaccounts';
    return this.httpClient.delete(url);
  }
  updateSendMessagingAccounts(body: any) {
    const url =
      this.shareService.apiBaseURL +
      this.orgUrl +
      this.shareService.organizationSelected.id +
      '/messagingaccounts';
    return this.httpClient.put(url, body);
  }
}

<div class="modal-content app-content">
  <div class="modal-header align-items-center mb-4">
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-primary link-btns mr-3" data-bs-toggle="modal" data-bs-target="#right" style="
          border-radius: 50% !important;
          color: #fff !important;
          background: #213a60 !important;
          border-color: #213a60;
        ">
        <i class="bi bi-pencil-fill"></i>
      </button>
      <h5 class="modal-title app-title"> {{ registry?.id ? 'Update' : 'Create new' }} registry</h5>
    </div>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body px-4">
    <form [formGroup]="registryForm">
      <div class="row px-4">
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Registry Type*</label>
            <select class="form-control" formControlName="registryType" id="registryType"
              (change)="onChangeRegistryType($event.target.value)">
              <option [value]="registryType?.value" *ngFor="let registryType of registryTypes;">
                {{ registryType?.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Name*</label>
            <input id="name" type="text" class="form-control" placeholder="name*" autocomplete="off"
              formControlName="name">
          </div>
        </div>
        <div class="col-md-12" *ngIf="['docker', 'acr', 'gcr', 'harbor'].includes(registryForm.get('registryType').value)">
          <div class="form-group">
            <label for="Strategy">Repository URI*</label>
            <input id="repo" type="text" class="form-control" placeholder="repository URI*" autocomplete="off"
              formControlName="repo">
          </div>
        </div>
        <div class="col-md-12" *ngIf="registryForm.get('registryType').value == 'ecr'">
          <div class="form-group">
            <label for="Strategy">Region*</label>
            <select class="form-control" formControlName="region">
              <option value="">select the region</option>
              <option [value]="item?.name" *ngFor="let item of awsRegions; let i =index ">{{item?.label}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-12" *ngIf="registryForm.get('registryType').value != 'gcr'">
          <div class="form-group">
            <label for="Strategy">
              {{ registryForm.get('registryType')?.value == 'ecr' ? 'Access Key' : 'Username' }}*
            </label>
            <input id="username" type="text" class="form-control"
              [attr.placeholder]="registryForm.get('registryType').value == 'ecr' ? 'Access Key' : 'Username'"
              autocomplete="off" formControlName="username">
          </div>
        </div>
        <div class="col-md-12" *ngIf="registryForm.get('registryType').value != 'gcr'">
          <div class="form-group">
            <label for="Strategy">
              {{ registryForm.get('registryType')?.value == 'ecr' ? 'Secret Key' : 'Password' }}*
            </label>
            <input id="password" [attr.type]="showPassword ? 'text' : 'password'" class="form-control"
              [attr.placeholder]="registryForm.get('registryType').value == 'ecr' ? 'Secret Key' : 'Password'"
              autocomplete="off" formControlName="password">
            <div class="text-right mt-1" (click)="showPassword = !showPassword"
              style="font-size: 11px; color: #84acfd; font-weight: 600; cursor: pointer;">
              <span *ngIf="!showPassword" style="color: #84acfd;">Show</span> <span *ngIf="showPassword"
                style="color: #84acfd;">Hide</span> secret
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="registryForm.get('registryType').value == 'gcr'">
          <div class="form-group file-field">
            <label for="file">File*</label>
            <input type="file" style="display: none;" #file (change)="onUploadFile($event)">
            <input type="text" class="form-control" [value]="fileName" placeholder="File" (click)="file.click()">
            <em class="fa fa-download" (click)="file.click()"></em>
          </div>
        </div>
      </div>
      <div class="row py-3 pt-4">
        <div class="col-md-12 text-center">
          <button type="button" class="btn btn-primary update-btn" (click)="onSubmit()"
            [disabled]="loading || (registryForm.invalid)">
            {{ registry?.id ? 'Update' : 'Create' }}
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-content msg-content">
    <div class="modal-header msg-head align-items-center">
      <h5 class="modal-title msgtitle" id="exampleModalLabel">
        <button
          type="button"
          class="btn btn-primary link-btn"
          data-bs-toggle="modal"
          data-bs-target="#right"
        >
          <i class="bi bi-link-45deg"></i>
        </button>
  
        <span>AWS role/user policy</span>
      </h5>
  
      <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
    </div>
  
    <div class="modal-body">
      <div class="section_title">
      </div>
      <div class="aws-support" [ngClass]="{ toggled: linkOpen == true }">
        <div class="card">
          <div class="card-body">
            <div class="line">
              <div class="_htem">
                <em class="fa fa-link"></em>
                <span>Creation of the role</span>
              </div>
              <div class="_btem">
                <span>Create a role with this policy:
                    <ul>
                        <li>
                            <strong>AmazonEKSClusterPolicy </strong>
                        </li>
                    </ul>
                    Or Use this policy file:
                </span>
                <p>
                  <code>
                      {{ '{' }}
                          <div class="space_one">"Version": "2012-10-17",</div>
                          <div class="space_one">"Statement":</div>
                          <div class="space_one">[</div>
                          <div class="space_two">{{ '{' }}</div>
                          <div class="space_three">"Effect": "Allow",</div>
                          <div class="space_three">"Action": [</div>
                          <div class="space_four">"eks:*",</div>
                          <div class="space_four">"ec2:*",</div>
                          <div class="space_four">"elasticloadbalancing:*",</div>
                          <div class="space_four">"kms:DescribeKey"</div>
                          <div class="space_three">],</div>
                          <div class="space_three">"Resource": "*"</div>
                          <div class="space_two">{{ '}' }}</div>
                          <div class="space_one">]</div>
                      {{ '}' }}
                  </code>
                  <button (click)="onCopy('policy', 0)">
                    <em
                      class="bi"
                      [ngClass]="{
                        'bi-files': copyState == false || copiedIndex != 0,
                        'bi-check-square': copyState == true && copiedIndex == 0
                      }"
                    ></em>
                  </button>
                </p>
                <span>Trust relationships</span>
                <p>
                  <code>
                    {{ '{' }}
                    <div class="space_one">"Version": "2012-10-17",</div>
                    <div class="space_one">"Statement":</div>
                    <div class="space_one">[</div>
                    <div class="space_two">{{ '{' }}</div>
                    <div class="space_three">"Effect": "Allow",</div>
                    <div class="space_three">"Principal": {{ ' {' }}</div>
                    <div class="space_four">"Service": "eks.amazonaws.com"</div>
                    <div class="space_three">{{ '}' }},</div>
                    <div class="space_three">"Action": "sts:AssumeRole"</div>
                    <div class="space_two">{{ '}' }}</div>
                    <div class="space_one">]</div>
                    {{ '}' }}
                  </code>
                  <button (click)="onCopy('trust', 1)">
                    <em
                      class="bi"
                      [ngClass]="{
                        'bi-files': copyState == false || copiedIndex != 1,
                        'bi-check-square': copyState == true && copiedIndex == 1
                      }"
                    ></em>
                  </button>
                </p>
              </div>
            </div>
            <div class="line">
              <div class="_htem">
                <em class="fa fa-link"></em>
                <span>Update the configMap</span>
              </div>
              <div class="_btem">
                <span>Use kubectl</span>
                <p>
                  <code>
                    <span>
                        kubectl edit -n kube-system configmap/aws-auth
                    </span
                    >
                  </code>
                  <button (click)="onCopy('kubectl', 2)">
                    <em
                      class="bi"
                      [ngClass]="{
                        'bi-files': copyState == false || copiedIndex != 2,
                        'bi-check-square': copyState == true && copiedIndex == 2
                      }"
                    ></em>
                  </button>
                </p>
                <span>Update this code and add to the configMap</span>
                <p>
                  <code>
                    - - - 
                    <div class="space_one">mapUsers: |</div>
                    <div class="space_two">- groups:</div>
                    <div class="space_three">- system:bootstrappers</div>
                    <div class="space_three">- system:nodes</div>
                    <div class="space_three">userarn: arn:aws:iam::"account-id":user/"username"</div>
                    <div class="space_three">username: "username"</div>
                    - - - 
                  </code>
                  <button (click)="onCopy('yamlrole', 3)">
                    <em
                      class="bi"
                      [ngClass]="{
                        'bi-files': copyState == false || copiedIndex != 3,
                        'bi-check-square': copyState == true && copiedIndex == 3
                      }"
                    ></em>
                  </button>
                </p>
                <p>
                  <code>
                    - - - 
                    <div class="space_one">mapRoles: |</div>
                    <div class="space_two">- groups:</div>
                    <div class="space_three">- system:bootstrappers</div>
                    <div class="space_three">- system:nodes</div>
                    <div class="space_three">rolearn:arn:aws:iam::"account-id":role/"role-name"</div>
                    <div class="space_three">username: system:node:{{ '{{' }}EC2PrivateDNSName{{ '}}' }}</div>
                    - - - 
                  </code>
                  <button (click)="onCopy('yamluser', 4)">
                    <em
                      class="bi"
                      [ngClass]="{
                        'bi-files': copyState == false || copiedIndex != 4,
                        'bi-check-square': copyState == true && copiedIndex == 4
                      }"
                    ></em>
                  </button>
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
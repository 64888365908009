import { Injectable } from '@angular/core';

@Injectable()

export class ContextService {
  contextValue: any = null

  updateContext(newValue: any) {
    this.contextValue = newValue;
  }
}

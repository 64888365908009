import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Invitation } from 'src/app/data/model/invitation';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { ShareService } from 'src/app/data/service/share.service';
import { UserService } from 'src/app/modules/secure/services/user.service';
import { getRandomColor, Position, RESOURCES, Type } from 'src/app/utils/const';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss'],
})
export class InviteMemberComponent implements OnInit {
  inviteForm: UntypedFormGroup;
  privileges = [
    { value: 'Project Owner', privileges: ['owner'] },
    {
      value: 'Project Owner +',
      privileges: ['owner', 'self_provisioner'],
    },
    { value: 'Validator', privileges: ['validator'] },
    { value: 'Super Admin', privileges: ['super_admin'] },
    { value: 'Viewer', privileges: ['viewer'] },
    { value: 'Member', privileges: [] }
  ];

  constructor(
    public modalRef: BsModalRef,
    public shareService: ShareService,
    private userService: UserService,
    private service: AsideExtenderService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  initForm() {
    this.inviteForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required,Validators.email]),
      privilege: new UntypedFormControl('', [Validators.required]),
    });
  }

  memberInvitation() {    
    if(this.inviteForm.controls.email.invalid){
      this.service.show({
        title: 'Failed to send the invitation',
        message: "Email incorrect or missing.",
        type: Type.ERROR,
        position: Position.TOP,
      });
    }else {
      var invit: Invitation = {
        email: this.inviteForm.get('email').value,
        privilege: this.inviteForm.get('privilege').value,
        organizationId: this.shareService.organizationSelected.id,
        resourceName: 'organization',
        resourceID: this.shareService.organizationSelected.id,
        uiUrl: window.location.host,
      };
  
      this.userService.createInvitation(invit).subscribe(
        (data) => {
  
          this.service.show({
            title: 'Success',
            message: 'Invitation succesfully sent',
            type: Type.SUCCESS,
            position: Position.TOP,
          });
          this.onCloseDialog();
        },
        (err) => {
          this.service.show({
            title: 'Failed to send the invitation',
            message: err.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.onCloseDialog();
        }
      );

    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  constructor( private route: ActivatedRoute) {}

  type: string = 'subscription';

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.type = param.type;
    });
  }
}

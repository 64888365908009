import { Injectable } from '@angular/core';
import {Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {ShareService} from './share.service';
import {OrganizationService} from './organization.service';
import {Location} from '@angular/common';
import {RESOURCES} from '../../utils/const';
import {HttpClient} from '@angular/common/http';
import {Session} from '../model/Session';
import {LoginService} from '../../auth/pages/login/services/login.service';
import {ConfigService} from './config.service';
import {Role} from "../model/role";

@Injectable({
  providedIn: 'root',
})
export class AppAuthGuardService implements CanActivate {
  whiteListUIURL = [
    '/',
    '/auth',
    '/auth/invitations/accept',
    '/auth/login',
    '/auth/login/',
    '/auth/login/teams',
    '/auth/login/free',
    '/auth/register',
    '/auth/change-password',
    '/auth/forgot-password',
    '/auth/see-you-soon',
    '/auth/signin-email',
    '/auth/signup-email',
    '/auth/verify-email-address',
    '/auth/unknown-organization'

  ];

  constructor(
    protected router: Router,
    private location: Location,
    private shareService: ShareService,
    private loginService: LoginService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authenticate = await this.authenticated();
    if (!authenticate) {
      this.router.navigate(['/auth/login/']);
      return false;
    }
    if (
      route.paramMap.get('idProject') &&
      route.queryParamMap.get('newProject')
    ) {
      this.location.replaceState(
        '/projects/' + route.paramMap.get('idProject') + '/detail'
      );
      window.location.reload(); // for reload new  role in project
    }
    if (this.shareService.organizations?.length === 0) {
      return false;
    }

    let userRoles: Role[];
    let userSession = this.shareService.getSession();

    if (
      userSession &&
      userSession.currentUser &&
      userSession.currentUser.roles
    ) {
      userRoles = this.shareService.getSession().currentUser.roles;
    } else {
      return false;
    }
    const requiredRoles = route.data.roles;
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else {
      if (!userRoles || userRoles.length === 0) {
        return false;
      }
      let granted = false;
      for (const requiredRole of requiredRoles) {
        const data = requiredRole.split(':');
        const privilege = data[0];
        const resource = data[1];

        if (data.length !== 2) {
      continue;
        }
        let role: any = undefined;
        const idProject = route.paramMap.get('idProject');
        if (resource === RESOURCES.project.name) {
          role = await this.getRole(privilege, resource, idProject);
        } else if (resource === RESOURCES.organization.name) {
          role = await this.getRole(
            privilege,
            resource,
            this.shareService.organizationSelected.id
          );
        }
        if (role !== undefined) {
          granted = true;
          break;
        }
      }
      if (!granted) {
        await this.router.navigate(['/']);
      }
      this.shareService.errorMessage = undefined;
      return granted;
    }
  }

  hasOneOfRoles(requiredRoles: string[]): boolean {
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else {
      if (this.getUserRoles().length === 0) {
        return false;
      }
      for (const requiredRole of requiredRoles) {
        const data = requiredRole.split(':');
        const privilege = data[0];
        const resource = data[1];

        let roleSetted = this.setRole(
          privilege,
          resource,
          this.shareService.organizationSelected.id
        );

        if (data.length == 2 && resource == 'organization' && roleSetted) {
          return true;
        }
        const resourceId = data[2];
        roleSetted = this.setRole(privilege, resource, resourceId);
        if (data.length == 3 && roleSetted) {
          return true;
        }
      }
      return false;
    }
  }

  hasOrganizationRoles(privilege: string): boolean {
    if (this.getUserRoles() === undefined) {
      return  false;
    }
    let filtered = this.getUserRoles().filter(
      (role: Role) =>
        role.privilege === privilege &&
        role.organizationId === this.shareService.organizationSelected.id
    );
    return filtered.length > 0 ? true : false;
  }

  hasProjectRoles(privilege: string, projectID: string): boolean {
     if (this.getUserRoles() === undefined) {
       return false;
     }
    let filtered = this.getUserRoles().filter(
      (userRole: Role) =>
        userRole.privilege === privilege && userRole.resourceId === projectID
    );
    return filtered.length > 0 ? true : false;
  }

  async getRole(
    privilege: string,
    resource: string,
    resourceId: string
  ): Promise<Role> {
    if (this.shareService.organizations?.length === 0) {
      this.shareService.setOrganizationSelected(
        undefined,
        this.shareService.getSession()
      );
      return undefined;
    }
    return this.setRole(privilege, resource, resourceId);
  }

  setRole(privilege: string, resource: string, resourceId: string): Role {
    if (!this.shareService.organizationSelected) {
      return undefined;
    }
    return this.getUserRoles().find(
      (userRole) =>
        userRole.privilege === privilege &&
        userRole.resource === resource &&
        userRole.resourceId === resourceId
    );
  }

  isTokenValid(session: Session): boolean {
    if (!session || !session.createdAt || session.createdAt<=0) {
      return false
    }
    if (
      Date.now() - session.createdAt + 60 * 1000 >
      session.expires_in * 1000
    ) {
      return false;
    }
    return true;
  }

  isUIAnonymous(): boolean {
    let pathSplitted = this.location.path().split('?');
    if (this.whiteListUIURL.includes(pathSplitted[0])) {
      return true;
    }
    return false;
  }

  async authenticated(): Promise<boolean> {
    let session = this.shareService.getSession();
    if(session && ! this.isTokenValid(session)){
      let newSession = await this.loginService.refresh(session.refresh_token).toPromise().then((response) => {
        return response;
      }).catch((error) => {
        return null;
      });
      if (!newSession || !newSession.id_token)  {
        return false
      } else {
        newSession.currentUser = session.currentUser;
        this.shareService.setSession(newSession);
        newSession.currentUser = await this.loginService.getCurrentUser().toPromise();
        this.shareService.updateSession(newSession);
        return true
      }
    } else if (this.isTokenValid(session)) {
      return true
    } else {
      return false
    }
  }

  getUserRoles(): Role[] {
    let userRoles: Role[];
    let userSession = this.shareService.getSession();
    if (
      userSession &&
      userSession.currentUser &&
      userSession.currentUser.roles
    ) {
      userRoles = this.shareService.getSession().currentUser.roles;
    }
    return userRoles;
  }


}

import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { ShareService } from 'src/app/data/service/share.service';

@Component({
  selector: 'app-aws-role-doc',
  templateUrl: './aws-role-doc.component.html',
  styleUrls: ['./aws-role-doc.component.scss']
})
export class AwsRoleDocComponent {
  copyState: boolean = false;
  linkOpen = false;
  copiedIndex: any;

  constructor(
    private clipboardService: ClipboardService,
    public shareService: ShareService,
    public modalRef: BsModalRef
  ) {}

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  ngOnInit(): void {}

  openSupport(): void {
    this.linkOpen = !this.linkOpen;
  }

  onCopy(state: string, i: any) {
    switch (state) {
      case 'policy':
        this.clipboardService.copy(
          `{
            "Version": "2012-10-17",
            "Statement": 
            [
                {
                    "Effect": "Allow",
                    "Action": [
                        "eks:*",
                        "ec2:*",
                        "elasticloadbalancing:*",
                        "kms:DescribeKey"
                  ],
                    "Resource": "*"
              }
            ]
        }`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'trust':
        this.clipboardService.copy(
          `{
            "Version": "2012-10-17",
            "Statement": [
              {
                "Effect": "Allow",
                "Principal": {
                  "Service": "eks.amazonaws.com"
                },
                "Action": "sts:AssumeRole"
              }
            ]
          }
          `
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'kubectl':
        this.clipboardService.copy(
          `kubectl edit -n kube-system configmap/aws-auth`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'yamluser':
        this.clipboardService.copy(
        `mapUsers: |
            - groups:
              - system:bootstrappers 
            - system:nodes	     
              userarn: arn:aws:iam::"account-id":user/"username"
              username: "username"
        `);
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
        case 'yamlrole':
        this.clipboardService.copy(
        `mapRoles: | 
            - groups: 
              - system:bootstrappers 
              - system:nodes 
              rolearn:arn:aws:iam::"account-id":role/"role-name" 
              username: system:node:{{EC2PrivateDNSName}}

        `);
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      default:
        break;
    }
  }
}

export class Cluster {
  apiServerUrl: string;
  apiGatewayAddresses: string;
  caCert: string;
  cloudProviderId: string;
  clusterCA: string;
  cpuAmount: string;
  cpuRemaining: string;
  createdDate: Date;
  healthTopic: string;
  id: string;
  kubeConfigResponseTopic: string;
  kubeConfigTopic: string;
  lastSeen: Date;
  memoryAmount: string;
  memoryRemaining: string;
  name: string;
  organizationId: string;
  readCrdFromApiTopic: string;
  readCrdFromClusterTopic: string;
  region: string;
  setCrdResponseTopic: string;
  setCrdTopic: string;
  type: string;
  userCert: string;
  userKey: string;
  createdByCno: boolean;
  shared: boolean;

  constructor(id: string, name: string, type: string, apiServerUrl: string) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.apiServerUrl = apiServerUrl;
  }
}

export class Organization {
  id: string;
  name: string;
  brokerUrl: string;
  chargebeeCustomerId: string;
  chargebeeSubscriptionId: string;
  chargebeeSubscriptionStatus: string;
  subscriptionType: string;
  numberOfUser: number;
  configCompleted: string;
  isContacted: boolean;
  url: any;
}

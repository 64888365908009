<div class="modal-content">
  <div class="modal-header" *ngIf="shareService?.organizations?.length != 0">
    <button type="button" class="m-0 p-0 close_btn" data-bs-dismiss="modal" aria-label="Close"
      (click)="onCloseDialog()">
      <i class="bi bi-x-lg"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="u_header">
      <div class="title_1">
        Deploy More Apps, Link More Clusters, Get Support!
      </div>
      <div class="title_2">
        Upgrading allows you to perform more deployments with more clusters connected.
      </div>
    </div>
    <div class="content">
      <div class="row">
        <!-- <div class="col-md-4 wrapper_box">
          <div class="content_box">
            <div class="plan_title">Free</div>
            <div class="specific">Only Automatic & Blue/Green</div>
            <div class="details">
              <div class="detail" *ngFor="let df of detailsFree">{{ df }}</div>
            </div>
            <div class="price">
              <div class="price_value">$0</div>
              <div class="price_per">Up to 5 deployments</div>
            </div>
          </div>
        </div> -->
        <div class="col-md-6 wrapper_box_teams">
          <div class="trial">14 days left in trial</div>
          <div class="content_box">
            <div class="plan_title">Startups</div>
            <div class="specific">All strategies available</div>
            <div class="details">
              <div class="detail" *ngFor="let dt of detailsTeams">
                {{ dt }}
              </div>
            </div>
            <form [formGroup]="selectPerForm">
              <div class="price">
                <!-- <div class="price_value">
                  ${{
                  selectPerForm.get("numberOfUser").value *
                  selectPerForm.get("selectedPlanPer").value.value *
                  teamPrice
                  }}
                </div> -->
                <div class="price_value">
                  Custom
                </div>
                <div class="price_per">
                  per user/month
                  <!-- <select class="dropbx" (change)="OnChangePer($event); (false)" formControlName="selectedPlanPer">
                    <option *ngFor="let item of selectionPer" [ngValue]="item">
                      {{ item.name }}
                    </option>
                  </select> -->
                </div>
              </div>
            </form>

            <div class="wrapper_btn"
              *ngIf="auth?.hasOrganizationRoles('super_admin') && currentOrganization?.subscriptionType.includes('free')">
              <button class="teams_btn" (click)="openModal2(proPopup, 'teams')">
                Create your organization
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 wrapper_box">
          <div class="content_box">
            <div class="plan_title">Entreprises</div>
            <div class="specific">No limits in value creation</div>
            <div class="details">
              <div class="detail" *ngFor="let de of detailsEntreprise">
                {{ de }}
              </div>
            </div>
            <div class="price">
              <div class="price_value">Custom</div>
              <div class="price_per">per user/month</div>
            </div>
            <div class="wrapper_btn">
              <button class="btn_entreprise" (click)="openModal2(proPopup, 'pro')">
                Create your organization
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #proPopup>
  <div class="modal-header">
    <h4 class="modal-title pull-left" style="color: #91acd5 !important">Create your Organization</h4>

    <i class="bi bi-x-lg dialog-close-icon" class="btn-close close pull-right" style="font-size: 1rem !important" aria-label="Close" (click)="modalRef2?.hide()"></i>
  </div>
  <div class="modal-body">
    <div class="msg-acc-form">
      <form [formGroup]="entrepriseForm">
        <div class="row d-flex justify-content-center">
          <div class="col-md-12 col-sm-12">
            <div class="form-group mb-4" >
              <label class="msg-acc-label">Organization Name<sup>*</sup></label>
              <input [autofocus]="true" formControlName="organizationName" class="form-control"
                placeholder="Organization Name" />
                <span *ngIf="entrepriseForm.invalid && entrepriseForm.value.organizationName !== ''"
                style="color: red;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                ">
                  Can't contain uppercase letters or special characters.</span>
            </div>

            <div class="col-md-12 col-sm-12">
              <div class="d-flex justify-content-between">
                <button type="button" (click)="onSubmit()" [disabled]="entrepriseForm.invalid || loading"
                  class="btn btn-primary submit">
                  <span>Create organization</span>
                  <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;"
                    role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                  </span>
                </button>
                <button *ngIf="modalRef" type="button" class="btn btn-danger" (click)="onCloseDialog2()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from 'src/app/data/model/environment';
import { GetRecordsModel } from 'src/app/data/model/GetRecordsModel';
import { Project } from 'src/app/data/model/project';
import { Workload } from 'src/app/data/model/workload';
import { ShareService } from 'src/app/data/service/share.service';

@Injectable({
  providedIn: 'root'
})
export class CdService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseURL: string;
  constructor(private httpClient: HttpClient,
              private shareService: ShareService) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }


  getProjects(): Observable<GetRecordsModel<Project>> {
      return this.httpClient.get<GetRecordsModel<Project>>(
        this.baseURL + this.shareService.organizationSelected.id + '/projects', this.httpOptions);
  }

  getEnvironments(id: string): Observable<GetRecordsModel<Environment>> {
    return this.httpClient.get<GetRecordsModel<Environment>>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + id + '/environments', this.httpOptions);
  }

  getWorkloads(queryFilter: string): Observable<GetRecordsModel<Workload>> {
    return this.httpClient.get<GetRecordsModel<Workload>>(this.baseURL + this.shareService.organizationSelected?.id + '/workloads?'+queryFilter);
  }

  getWorkloadsByProject(id: any, queryFilter:string): Observable<GetRecordsModel<Workload>> {
    return this.httpClient.get<GetRecordsModel<Workload>>(this.baseURL + this.shareService.organizationSelected?.id + '/projects/'+ id +'/workloads?strategy='+queryFilter);
  }

  getWorkloadsByEnvironment(id: any, idEnv: any,queryFilter: string): Observable<GetRecordsModel<Workload>>{
    return this.httpClient.get<GetRecordsModel<Workload>>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + id + '/environments/' + idEnv + '/workloads?strategy='+queryFilter);
  }

  editWorkload({idproject,idEnv,idWorkload}, form: any): Observable<any> {
    return this.httpClient.patch(this.baseURL + this.shareService?.organizationSelected?.id +'/projects/' + idproject + '/environments/' + idEnv + '/workloads/'+ idWorkload,form);
  }

  editWorkloads(form: any): Observable<any> {
  return this.httpClient.patch(this.baseURL + this.shareService?.organizationSelected?.id +'/workloads',form);
  }
  getWorkloadById(idproject: string, idEnv: string, id: string): Observable<Workload>{
    return this.httpClient.get<Workload>(this.baseURL + this.shareService?.organizationSelected?.id + '/projects/' + idproject + '/environments/' + idEnv + '/workloads/' + id);
  }

  searchWorkload() {}

  switchWorkload(ids: any) {
    return this.httpClient.patch(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + ids.projectID + "/environments/"+ ids.envID + "/workloads/"+ ids.workloadID+ "/switch",{});
  }

  rollWorkload(ids: any) {
    return this.httpClient.patch(this.baseURL + this.shareService.organizationSelected.id + "/projects/" + ids.projectID + "/environments/"+ ids.envID + "/workloads/"+ ids.workloadID+ "/rollback",{});
  }


}

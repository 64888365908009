import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as jsYaml from 'js-yaml';

function yamlToEnv(yamlObject) {
  const output = [];

  function traverse(obj, prefix = '') {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        if (!Array.isArray(obj[key])) {
          traverse(obj[key], `${prefix}${key}_`);
        } else {
            // TODO
        }
      } else {
        output.push([`${prefix}${key}`.toLocaleUpperCase().replace(/[\s\-.]/g, '_'),obj[key].toString()])
      }
    }
  }

  traverse(yamlObject);
  return output;
}

@Component({
  selector: 'config-upload',
  templateUrl: './config-upload.component.html',
  styleUrls: ['./config-upload.component.scss']
})
export class ConfigUploadComponent implements OnInit{
  @Input() typeConfig: string
  @Output() envVars = new EventEmitter<string[]>();
  @ViewChild('file') inputFile!: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
  }

  async extractFileContent(e: File[]) {
    const file = e[0];
    const contenu = await file.arrayBuffer()
    const decoderTxt = new TextDecoder();
    const buffer = decoderTxt.decode(contenu);
    this.inputFile.nativeElement.value = null;
    switch(file.type) {
      case "application/json": this.isJsonString(buffer)
      break;
      case "application/x-yaml": this.isYamlString(buffer)
      break;
      default: this.isTextString(buffer)
      break;
    }
  }

  isJsonString(file: string) {
    const jsonParsed = JSON.parse(file);
    const paires = Object.entries(jsonParsed);
    console.log(paires)
  }

  isYamlString(file: string) {
    let output = [];
    const documents = jsYaml.loadAll(file);
    for (const doc of documents) {
      output = output.concat(yamlToEnv(doc))
    }
    this.envVars.emit(output)
  }

  isTextString(file: string) {
    let output = [];
    const lines = file.split("\n");

    const lineFiltered = lines.filter(line => {
        let parts = [];
        if (!line.trim().startsWith(`#`) && !line.trim().startsWith(`@`) && !line.trim().startsWith(`/`)) {
            parts = line.split(/=(.*)/s);
            parts.pop()
        }
        return parts.length === 2 && parts[0].toLowerCase().replace(/ /g, "") === parts[0].trim().toLowerCase();
    });
    lineFiltered.forEach((l) => {
      const row = l.split(/=(.*)/s);
      row.pop()
      if(row[0].includes(".")) {
        output.push([row[0].replace(/[^a-zA-Z0-9]/g,'_').toLocaleUpperCase().trim(),row[1].trim()])
      } else {
        output.push([row[0].trim(),row[1].trim()]);
      }
    });
    this.envVars.emit(output)
  }

}

import { CurrentUser } from './../../../data/model/Session';
import { Component, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ShareService } from '../../../data/service/share.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrganizationService } from 'src/app/data/service/organization.service';
import axios from 'axios';

@Component({
  selector: 'app-command-cnoctl',
  templateUrl: './command-cnoctl.component.html',
  styleUrls: ['./command-cnoctl.component.scss'],
})
export class CommandCnoctlComponent implements OnInit {
  copyState: boolean = false;
  linkOpen = false;
  copiedIndex: any;
  emailUser: any
  organizationName: string
  organizationToken: string
  releaseTabValue: string = "mac"
  platformInfo : string
  cnoTagName : string;
  downloadReleaseForMac : string;
  downloadReleaseForWin : string;
  downloadReleaseForLinux : string;


  constructor(
    private clipboardService: ClipboardService,
    public shareService: ShareService,
    public modalRef: BsModalRef,
    private organizationService: OrganizationService

  ) { }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  ngOnInit(): void {
    this.getCNOVersion()
    this.emailUser = this.shareService.email
    this.organizationName = this.shareService?.organizationSelected?.name
    this.getOrganizationToken()
    this.platformInfo = navigator.platform
  }

  getCNOVersion() {
   axios.get("https://api.github.com/repos/beopencloud/cno/releases/latest").then((res) => {
   this.cnoTagName = res?.data?.tag_name
   this.downloadReleaseForMac = `https://github.com/beopencloud/cno/releases/download/${this.cnoTagName}/cnoctl_Darwin_x86_64.tar.gz`;
   this.downloadReleaseForWin = `https://github.com/beopencloud/cno/releases/download/${this.cnoTagName}/cnoctl_Windows_x86_64.tar.gz`;
   this.downloadReleaseForLinux = `https://github.com/beopencloud/cno/releases/download/${this.cnoTagName}/cnoctl_Linux_x86_64.tar.gz`;
   }).catch((err) => {
   })
  }

  getOrganizationToken(): void {
    this.organizationService.getOrganizationToken().subscribe(
      (data) => {
        this.organizationToken = data?.token
      }
    );

  }

  activeMacTab() {
    this.releaseTabValue = "mac"
  }

  activeLinuxTab() {
    this.releaseTabValue = "linux"
  }

  activeWindowsTab() {
    this.releaseTabValue = "windows"
  }

  openSupport(): void {
    this.linkOpen = !this.linkOpen;
  }

  onCopy(state: string, i: any) {
    switch (state) {
      case 'download':
        this.clipboardService.copy(
          'https://github.com/beopencloud/cno/releases'
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'tokenLogin':
        this.clipboardService.copy(
          `cnoctl login --token ${this.organizationToken} --organization ${this.organizationName}`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'configure':
        this.clipboardService.copy(
          `cnoctl config --server-url ${this.shareService.apiBaseURL} --organization ${this.organizationName}`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'login':
        this.clipboardService.copy(`cnoctl login -u ${this.emailUser}`);
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'newProject':
        this.clipboardService.copy(
          `cnoctl create project myproject`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'newEnv':
        this.clipboardService.copy(
          `cnoctl create environment my-env --project myproject --prod false --cluster my-cluster`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'useEnv':
        this.clipboardService.copy(
          `cnoctl use env -e my-env --project myproject`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'brew':
        this.clipboardService.copy(
          `brew tap beopencloud/cno ; brew install cnoctl`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'curl':
        this.clipboardService.copy(
          `curl -sSL https://raw.githubusercontent.com/beopencloud/cno/main/scripts/cnoctl.sh | sh -s`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'releasemac':
        this.clipboardService.copy(
          `VERSION=$(curl -s https://api.github.com/repos/beopencloud/cno/releases/latest | grep '"tag_name":' | sed -E 's/.*"([^"]+)".*/\\1/'); wget https://github.com/beopencloud/cno/releases/download/$VERSION/cnoctl_Darwin_x86_64.tar.gz; tar -xzf cnoctl_Darwin_x86_64.tar.gz; sudo mv cnoctl /usr/local/bin/`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'releaselinux':
        this.clipboardService.copy(
          `VERSION=$(curl -s https://api.github.com/repos/beopencloud/cno/releases/latest | grep '"tag_name":' | sed -E 's/.*"([^"]+)".*/\\1/'); wget https://github.com/beopencloud/cno/releases/download/$VERSION/cnoctl_Linux_x86_64.tar.gz; tar -xzf cnoctl_Linux_x86_64.tar.gz; sudo mv cnoctl /usr/local/bin/`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'releasewindows':
        this.clipboardService.copy(
          `VERSION=$(curl -s https://api.github.com/repos/beopencloud/cno/releases/latest | grep '"tag_name":' | sed -E 's/.*"([^"]+)".*/\\1/'); wget https://github.com/beopencloud/cno/releases/download/$VERSION/cnoctl_Windows_x86_64.tar.gz; tar -xzf cnoctl_Windows_x86_64.tar.gz; sudo mv cnoctl /usr/local/bin/`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      default:
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  url: string;
  constructor(private httpClient: HttpClient) {
    this.url = window.location.origin;

  }

  getApiUrl(): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(this.url + "/api-url", { headers, responseType: 'text'});
  }
  getOidcClientId(): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(this.url + "/oidc-client-id", { headers, responseType: 'text'});
  }
  getSocketUrl(): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(this.url + "/notification-url", { headers, responseType: 'text'});
  }

  getEnvsVars(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get(this.url + "/envs-vars-url", { headers, responseType: 'json'});
  }
  getConfig(){
   return forkJoin([
      this.getApiUrl(),
      this.getOidcClientId(),
      // this.getSocketUrl(),
    ])
  }


}

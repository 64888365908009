<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="!loading && hasNotAccess">
    <span style="color: #f3280db9; font-size: 20px;">
        Access Denied
        <i class="fa fa-window-close" aria-hidden="true"></i>
    </span>
    <p>You don't have permission for this page</p>
</div>

  
<main class="dashboard px-4" *ngIf="!loading && !hasNotAccess">
    <div class="row">
        <div class="col-xl-12 col-md-12 header-div">
            <div class="title">
                <p>Codes ({{pipelines?.length || 0}})</p>
                <div class="dropdown">
                    <button class="dropbtn" >
                        <span>Framework/Langage</span>
                        <span><i class="bi bi-chevron-down"></i></span>
                    </button>
                    <div class="dropdown-content" *ngIf="pipelines">
                        <button (click)="onChangeFramework('')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == ''}">
                            <span>All</span>
                        </button>
                        <button (click)="onChangeFramework('Angular')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Angular'}">
                            <span>Angular</span>
                        </button>
                        <button (click)="onChangeFramework('Aspnet')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Aspnet'}">
                            <span>Aspnet</span>
                        </button>
                        <button (click)="onChangeFramework('Csharp')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Csharp'}">
                            <span>C#</span>
                        </button>
                        <button (click)="onChangeFramework('Django')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Django'}">
                            <span>Django</span>
                        </button>
                        <button (click)="onChangeFramework('Elixir')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Elixir'}">
                            <span>Elixir</span>
                        </button>
                        <button (click)="onChangeFramework('Flask')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Flask'}">
                            <span>Flask</span>
                        </button>
                        <button (click)="onChangeFramework('Golang')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Golang'}">
                            <span>Golang</span>
                        </button>
                        <button (click)="onChangeFramework('Laravel')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Laravel'}">
                            <span>Laravel</span>
                        </button>
                        <button (click)="onChangeFramework('Nodejs')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Nodejs'}">
                            <span>NextJs</span>
                        </button> 
                        <button (click)="onChangeFramework('Phoenix')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Phoenix'}">
                            <span>Phoenix</span>
                        </button> 
                        <button (click)="onChangeFramework('NextJs')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'NextJs'}">
                            <span>NextJs</span>
                        </button>                        
                        <button (click)="onChangeFramework('React')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'React'}">
                            <span>React</span>
                        </button>
                        <button (click)="onChangeFramework('Springboot')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Springboot'}">
                            <span>Springboot</span>
                        </button>
                        <button (click)="onChangeFramework('Vuejs')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Vuejs'}">
                            <span>Vuejs</span>
                        </button>
                        <button (click)="onChangeFramework('Others')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentFramework == 'Others'}">
                            <span>Others</span>
                        </button>
                    </div>
                </div>
                <div class="dropdown">
                    <button class="dropbtn" >
                        <span>Git Provider</span>
                        <span><i class="bi bi-chevron-down"></i></span>
                    </button>
                    <div class="dropdown-content">
                        <button (click)="onChangeProvider('')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentProvider == ''}">
                            <span>All</span>
                        </button>
                        <button (click)="onChangeProvider('github')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentProvider == 'github'}">
                            <span>Github</span>
                        </button>
                        <button (click)="onChangeProvider('gitlab')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentProvider == 'gitlab'}">
                            <span>Gitlab</span>
                        </button>
                        <button (click)="onChangeProvider('bitbucket')" type="button"
                            class="my-1 m-0 btn btn-success form-control filter-btn"
                            [class]="{active: currentProvider == 'bitbucket'}">
                            <span>Bitbucket</span>
                        </button>
                    </div>
                </div>
            </div>

            <button *ngIf="
                  canAddCode() ||
                  auth?.hasOrganizationRoles('super_admin') ||
                  auth?.hasOrganizationRoles('owner+') ||
                  auth?.hasOrganizationRoles('owner')"
                  class="create-app" (click)="onAddPipeline()"><i class="bi bi-plus" style="color: #fff;"></i> Code</button>
        </div>
    </div>
    <div class="row g-2">
        <section class="col-xl-12 col-md-12">
            <div class="div-styled">
                <div class="table_wrapper">
                    <table class="table member-table">
                        <thead>
                            <tr>
                                <th scope="col">Repo Name</th>
                                <th scope="col">Framework/Langage</th>
                                <th scope="col">Project</th>

                                <th scope="col">Git Providers</th>
                                <!-- <th scope="col">Branch Ref.</th> -->
                                <th scope="col">Created At</th>

                                <th scope="col" class="role">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of pipelines">
                                <td style="width: 30%;cursor: pointer;" (click)="onViewPipeline(item)" >
                                    <p>{{item.name}} </p>
                                </td>
                                <td style="width: 15%;">
                                    <p style="padding-top: 5px;"><img src="../../../../assets/icons/{{item.framework}}_full_color_logo.svg" alt=""
                                            style="width: 25px;">&nbsp;&nbsp;{{item.framework}}</p>
                                </td>
                                <td style="width: 20%;">
                                    <p>{{item?.projectName ? item?.projectName?.length > 20 ? item?.projectName?.slice(0,24)+"..." : item?.projectName : "--"}}</p>
                                </td>
                                <td style="width: 10%;">
                                    <p>{{item.gitProvider?.charAt(0).toUpperCase() + item.gitProvider?.slice(1)}}</p>
                                </td>
                                <!-- <td style="width: 10%;">
                                    <p>{{item.branchRef?.charAt(0).toUpperCase() + item.branchRef?.slice(1) || "---"}}</p>
                                </td> -->
                                <td style="width: 10%;">
                                    <p>{{item.CreatedAt  | date : "dd/MM/YYYY"}}</p>
                                </td>
                                <td style="width: 15%;text-align: center;">
                                    <p class="action-button">
                                        <i class="bi-eye-fill" (click)="onViewPipeline(item)" style="color: #4077e4;font-size: 20px;margin-right: 5px;"></i>&nbsp;&nbsp;
                                        <i class="bi-pencil-square" (click)="onOpenEditModal(item)" style="color: #1CC66D;font-size: 20px;margin-right: 5px;"
                                          *ngIf="canAddCode() || auth?.hasOrganizationRoles('super_admin') ||
                                                auth?.hasOrganizationRoles('owner+') ||
                                                auth?.hasOrganizationRoles('owner')"></i> &nbsp;&nbsp;
                                        <i class="bi bi-trash" *ngIf="
                                            canAddCode() || auth?.hasOrganizationRoles('super_admin') ||
                                            auth?.hasOrganizationRoles('owner+') ||
                                            auth?.hasOrganizationRoles('owner')"
                                            style="color: #fd8484;font-size: 20px;" (click)="onOpenDeleteModal(template, item)" ></i></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p *ngIf="!loading && pipelines.length == 0" class="no-app-found">No app found.</p>
                    <p *ngIf="loading" class="no-app-found">Loading apps ...</p>

                </div>
            </div>
            <div class="div-button">
                <button *ngIf="
                canAddCode() ||
                  auth?.hasOrganizationRoles('super_admin') ||
                  auth?.hasOrganizationRoles('owner+') ||
                  auth?.hasOrganizationRoles('owner')" (click)="onAddPipeline()">

                    Add Code

                </button>
            </div>
        </section>
    </div>
</main>
<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="loading">
    <spinner [status]="status"></spinner>
    <span style="color: #043c66; font-size: 20px;">Codes loading</span>
  </div>

<ng-template #template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="onCloseDialog()">
                <i class="bi bi-x-lg"></i>
            </button>
        </div>

        <div class="modal-body">
            <div class="delete-popup">
                <h3 class="heading">Are You Sure?</h3>
                <div class="delete-text">
                    Delete Pipeline <br><br> « <strong>{{ pipelineToDelete?.name.charAt(0).toUpperCase() +
                        pipelineToDelete?.name.slice(1) }}</strong> »
                </div>

                <div class="attachment-link d-flex justify-content-between mt-3">
                    <button class="btn-delete" type="button" (click)="onSubmitDeleteApp()" [disabled]="isDeleted">
                        Delete
                    </button>
                    <button class="btn-cancel" (click)="onCloseDialog()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="!domainLoaded">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">Domains loading</span>
</div>

<div class="row div-container" *ngIf="domainLoaded">
  <div class="col-md-12 div-title d-flex justify-content-between">
    <div>
      <button (click)="goBack()"><i class="bi-chevron-left"></i> &nbsp; Go back</button>
      <strong>Domain</strong>
    </div>
    <button class="btn btn-update" *ngIf="(auth?.hasOrganizationRoles('super_admin') || auth?.hasOrganizationRoles('owner')) && !onlyClusterShared"
      (click)="onCreate()">Create new domain</button>
  </div>
  <div class="col-md-12 info-banner d-flex justify-content-between mb-2" id="banner" *ngIf="currentCluster">
    <i class="fa fa-info-circle fa-2x"></i>
    <div class="d-flex justify-content-center align-items-center">
      Please use this address to add the <strong class="mx-1">{{ currentCluster?.type == 'aws' ? 'CNAME' : 'A' }} </strong>
      record to your DNS manager.
      Click here to copy address <i class="fa fa-hand-o-right mx-1"></i> <strong class="copy" (click)="onCopyApiGatewayUrl(currentCluster?.id)"> {{ currentCluster?.apiGatewayAddresses }} <i class="fa fa-copy mx-1"></i></strong>

    </div>
    <span class="fa fa-times ml-5 close" (click)="onCloseBanner('banner')"></span>
  </div>
  <div class="col-md-12 table-bloc">
    <div class="div-table">
      <table class="table member-table table-responsive">
        <thead>
          <tr>
            <th scope="col">Host</th>
            <th scope="col">Gateway</th>
            <th scope="col">Certificate Secret</th>
            <th scope="col">Cluster Name</th>
            <th scope="col">Scope</th>
            <th scope="col">Security</th>
            <th scope="col" class="action"> <i class="bi bi-x-lg"></i></th>
          </tr>
        </thead>
        <tbody *ngIf="!onlyClusterShared">
          <tr *ngFor="let item of domains">
            <td class="pt-3"> {{ item?.host }} </td>
            <td class="pt-3">
              <div class="custom-tooltip" *ngIf="selectApiGatewayUrl(item?.clusterID)[0]" (click)="onCopyApiGatewayUrl(item?.clusterID)">
                {{ selectApiGatewayUrl(item?.clusterID, true)[0] }}
                <span class="text">
                  Please use this address to add a <strong>{{ selectApiGatewayUrl(item?.clusterID)[1] == 'aws' ? 'CNAME' : 'A' }}</strong> record to your DNS manager.
                  <br>
                  <span>
                    {{ selectApiGatewayUrl(item?.clusterID, false)[0] }}
                  </span>
                  <br>
                  Click to copy <i class="fa fa-hand-o-down"></i>
                </span>
              </div>
            </td>
            <td class="pt-3" *ngIf="item.certificateSecretNamespace && item.certificateSecretName">
              {{ item?.certificateSecretNamespace }} / {{ item?.certificateSecretName }}
            </td>
            <td class="pt-3" *ngIf="!item.certificateSecretName || !item.certificateSecretNamespace">-</td>
            <td class="pt-3">
              {{ item?.clusterName ? item?.clusterName : getClusterName(item?.clusterID) }} <br>
              <!-- <small *ngIf="item.environmentID">(env: {{ item.environmentName }})</small> -->
            </td>
            <td class="pt-3">
              {{ item.environmentID ? 'Environment' : item.clusterID ? 'Cluster' : '-' }}
            </td>
            <td class="pt-3">
              <span *ngIf="item.isUnsecure">No Secure</span>
              <span *ngIf="!item.isUnsecure">Secure</span>
            </td>
            <td class="pt-3 cancel nowrap">
              <i class="bi bi-x-lg" (click)="toggleModal('deletePopup', item)"></i>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="onlyClusterShared">
          <tr>
            <td colspan="6" class="text-center"><i class="fa fa-warning"></i> Permission denied</td>
          </tr>
        </tbody>
      </table>
      <!-- <p *ngIf="domainLoaded" class="no-app-found">Loading domains.</p> -->

      <p *ngIf="domains?.length == 0" class="no-domain-found">No domain found.</p>
    </div>
  </div>
</div>

<modal modalId="deletePopup" size="cno-modal-sm">
  <div class="flex-c" content>
    <div class="mheader">
      <img src="../../../../../../assets/icons/clear_white.svg" alt="" srcset="" data-dismiss="modal"><span>{{'Are you sure ?'}}</span>
    </div>
    <div class="mbody" *ngIf="deleteDomainForm">
      <div>
        <span>{{'delete domain : ' + domainSelected?.host}}</span>
      </div>

      <div>
        <form [formGroup]="deleteDomainForm">
          <label for="host">
            <span *ngIf="(cname|async) == 'INVALID'">{{'invalid domain host'}}</span>
          </label>
          <input id="" type="text" class="form-control" placeholder="ENTER DOMAIN HOST" formControlName="host">
        </form>
      </div>

      <div>
        <button class="btn submit" (click)="deleteDomainById()"
          [disabled]="deleteDomainForm?.invalid"><span>{{'Delete'}}</span></button>
        <button class="btn" data-dismiss="modal"><span>{{'Cancel'}}</span></button>
      </div>
    </div>
  </div>
</modal>

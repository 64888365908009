import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginService } from 'src/app/auth/pages/login/services/login.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { Position, Type } from 'src/app/utils/const';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Organization } from 'src/app/data/model/organization';

@Component({
  selector: 'app-config-saml',
  templateUrl: './config-saml.component.html',
  styleUrls: ['./config-saml.component.scss'],
})
export class ConfigSamlComponent implements OnInit {
  organization: Organization;
  configForm: FormGroup;
  loading: boolean = false;
  loadingTest: boolean = false;
  tested: boolean = true;
  loaderDelete: boolean = false;
  confirmDelete: boolean = false;
  saml: any;
  constructor(
    public modalRef: BsModalRef,
    public modalRef2: BsModalRef,
    private asideExtenderService: AsideExtenderService,
    public loginService: LoginService,
    private organizationService: OrganizationService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getSamlConf();
  }

  initForm() {
    this.configForm = new FormGroup({
      entityId: new FormControl('', [Validators.required]),
      urlSso: new FormControl('', [Validators.required]),
      certificate: new FormControl('', [Validators.required]),
    });
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onTest() {
    this.loadingTest = true;
    let url = this.organization?.name
      ? this.organization?.name
      : this.organization?.url;
    this.loginService.TestSAML(url);
    setTimeout(() => {
      this.loadingTest = false;
      this.tested = true;
    }, 1000);
  }

  onSubmit(): void {
    if (this.configForm.valid) {
      this.loading = true;
      let formData = this.configForm.value;

      let samlData = {
        organizationID: this.organization.id,
        samlEntityID: formData.entityId,
        samlSsoUrl: formData.urlSso,
        samlCertificat: formData.certificate,
      };

      this.organizationService
        .configureAuthSaml(this.organization.id, samlData)
        .subscribe(
          (response) => {
            this.asideExtenderService.show({
              type: Type.SUCCESS,
              message: 'Config SAML',
              title: 'Your SAML configuration is OK!',
              position: Position.TOP,
            });
            this.getSamlConf();
            this.loading = false;
            this.tested = false;
          },
          (error) => {
            this.asideExtenderService.show({
              type: Type.ERROR,
              title: 'Configuration failed',
              message: error.error,
              position: Position.TOP,
            });
            this.loading = false;
          }
        );
    }
  }

  getSamlConf(): void {
    this.organizationService.getConfAuthSaml(this.organization.id).subscribe(
      (response) => {
        this.saml = response;
        this.configForm.get('entityId').setValue(response.samlEntityID);
        this.configForm.get('urlSso').setValue(response.samlSsoUrl);
        this.configForm.get('certificate').setValue(response.samlCertificat);
      },
      (error) => {}
    );
  }

  onCloseDialog2(): void {
    this.modalRef2.hide();
  }

  onOpenDeleteModal(template: TemplateRef<any>, project: any) {
    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
    };
    this.modalRef2 = this.modalService.show(template, config);
  }

  deleteSamlConf(): void {
    this.loaderDelete = true;
    this.organizationService.deleteConfAuthSaml(this.organization.id).subscribe(
      (response) => {
        this.loaderDelete = false;
        this.onCloseDialog2();
        this.saml = null;
        this.configForm.get('entityId').setValue('');
        this.configForm.get('urlSso').setValue('');
        this.configForm.get('certificate').setValue('');
      },
      (error) => {
        this.asideExtenderService.show({
          type: Type.ERROR,
          title: 'Deleting failed',
          message: error.error,
          position: Position.TOP,
        });
        this.loaderDelete = false;
      }
    );
  }
}

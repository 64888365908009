import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {GetRecordsModel} from '../../../data/model/GetRecordsModel';
import {Project, ProjectMember} from '../../../data/model/project';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ShareService} from '../../../data/service/share.service';
import {Metrics} from '../../../data/model/metric';
import {Environment} from '../../../data/model/environment';
import {ProvisioningRequest} from '../../../data/model/provisioning_request';
import { Role } from 'src/app/data/model/role';
import { User } from 'src/app/data/model/user';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseURL: string;

  constructor(private httpClient: HttpClient,
              private shareService: ShareService) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }
  getProjectsByOrganisation(name?: string, page?: number, limit?: number): Observable<GetRecordsModel<Project>> {
    return this.httpClient.get<GetRecordsModel<Project>>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects?page=' + page + '&limit=' + limit + '&name=' + name, this.httpOptions);
  }
  getById(idProject: string): Observable<Project> {
    return this.httpClient.get<Project>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + idProject);
  }
  getProjectMembers(idProject: string): Observable<User[]> {
    return this.httpClient.get<User[]>(
      this.baseURL + this.shareService.organizationSelected.id + '/project/' + idProject+'/users');
  }
  getByName(name: string): Observable<Project> {
    return this.httpClient.get<Project>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/name/' + name);
  }
  delete(project: Project): Observable<object> {
    return this.httpClient.delete(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + project.id, this.httpOptions);
  }
  create(project: Project): Observable<Project> {
    return this.httpClient.post<Project>(this.baseURL + this.shareService.organizationSelected.id + '/projects', project, this.httpOptions);
  }
  update(project: Project): Observable<Project> {
    project.organizationId = this.shareService.organizationSelected.id;
    return this.httpClient.put<Project>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + project.id, project, this.httpOptions);
  }
  getProjectsMetrics(name: string): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + name + '/metrics', this.httpOptions);
  }
   getProjectsRequestMetrics(name: string): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/request/' + name + '/metrics', this.httpOptions);
  }
  getEnvironments(id: string, page: number, limit: number, search=""): Observable<GetRecordsModel<Environment>> {
    return this.httpClient.get<GetRecordsModel<Environment>>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + id + '/environments?page=' + page + '&limit=' + limit + '&search=' + search, this.httpOptions);
  }
  getProvisioning(id: string, page: number, limit: number): Observable<GetRecordsModel<ProvisioningRequest>> {
    return this.httpClient.get<GetRecordsModel<ProvisioningRequest>>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + id + '/provisioningrequests?page=' + page + '&limit=' + limit, this.httpOptions);
  }
  getEnvironmentsMetrics(id: string, name: string): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + id + '/environments/' + name + '/metrics', this.httpOptions);
  }
  getEnvironmentsRequestMetrics(id: string, name: string): Observable<Metrics> {
    return this.httpClient.get<Metrics>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + id + '/environments/request/' + name + '/metrics', this.httpOptions);
  }
  deleteEnv(env: Environment, force = false): Observable<object> {
    return this.httpClient.delete(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/' + env.id +`?force=`+`${force}`, this.httpOptions);
  }
  createProvisioning(pro: ProvisioningRequest): Observable<ProvisioningRequest> {
    return this.httpClient.post<ProvisioningRequest>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + pro.projectId + '/provisioningrequests', pro, this.httpOptions);
  }
  assigneRoleToProject(role: Role): Observable<ProjectMember> {
      return this.httpClient.post<ProjectMember>(
        this.baseURL + this.shareService.organizationSelected.id + '/users/privileges', role, this.httpOptions);
  }
  deleteProjectMember(role: Role): Observable<any> {
    return this.httpClient.delete<any>(this.baseURL + this.shareService.organizationSelected.id + '/users/'+role.email+'/resources/'+role.resource+'/'+role.resourceId+'/privileges/'+role.privilege, {});
  }
  updateProvisioning(pro: ProvisioningRequest): Observable<ProvisioningRequest> {
    return this.httpClient.put<ProvisioningRequest>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + pro.projectId + '/provisioningrequests/' + pro.id, pro, this.httpOptions);
  }
  deleteProvisioning(pro: ProvisioningRequest): Observable<any> {
    return this.httpClient.delete<any>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + pro.projectId + '/provisioningrequests/' + pro.id);
  }

  getProvisioningById(pro: ProvisioningRequest): Observable<ProvisioningRequest> {
    return this.httpClient.get<ProvisioningRequest>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + pro.projectId + '/provisioningrequests/' + pro.id);
  }
  createEnvironment(env: Environment): Observable<Environment> {
    return this.httpClient.post<Environment>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments', env, this.httpOptions);
  }
  updateEnvironment(env: Environment): Observable<Environment> {
    return this.httpClient.put<Environment>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/' + env.id, env, this.httpOptions);
  }

   createEnvironmentNotQuota(env: Environment): Observable<Environment> {
    return this.httpClient.post<Environment>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/unlimited', env, this.httpOptions);
  }
  updateEnvironmentNotQuota(env: Environment): Observable<Environment> {
    return this.httpClient.put<Environment>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/' + env.id + '/unlimited', env, this.httpOptions);
  }

  getEnvironmentById(env: any): Observable<Environment> {
    return this.httpClient.get<Environment>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/' + env.id);
  }

  getPlan(query: any): Observable<any>{
    return this.httpClient.get<any>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + query.projectId + '/environments/' + query.id +'/resources/plan');
  }

  getTagsByOrganizationID(): Observable<any>{
    return this.httpClient.get(
      this.baseURL + this.shareService.organizationSelected.id + '/tagstrategies');
  }


  getQuota(): Observable<any>{
    return this.httpClient.get(
      this.baseURL + this.shareService.organizationSelected.id + '/quota');
  }

  addQuota(form: any): Observable<any> {
    return this.httpClient.post(this.baseURL + this.shareService.organizationSelected.id + '/quota',form);
  }

  getEnvMetrics(form: any): Observable<any> {
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + '/projects/'+ form?.idProject+'/environments/'+form?.idEnvironment+'/'+ form?.name +'/metrics');
  }

  getUsers(search: any): Observable<any> {
    return this.httpClient.get(this.baseURL + this.shareService.organizationSelected.id + '/users?search='+search);
  }

  rollback(env: any): Observable<Environment> {
    return this.httpClient.get<Environment>(
      this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/' + env.id + '/migration/rollback');
  }

  apply (env: Environment, data: any): Observable<Environment> {
    return this.httpClient.put<Environment>(this.baseURL + this.shareService.organizationSelected.id + '/projects/' + env.projectId + '/environments/' + env.id +'/resources/apply', data, this.httpOptions);
  }
}

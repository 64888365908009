import { Component, EventEmitter } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, SUPPORT_EMAIL, Type } from 'src/app/utils/const';
import { Domain } from 'src/app/utils/types';
import { ShareService } from 'src/app/data/service/share.service';
import * as moment from 'moment';
import { AppService } from '../../app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClusterService } from 'src/app/modules/clusters/services/cluster.service';
import { Cluster } from 'src/app/data/model/cluster';
import { ProjectService } from 'src/app/modules/onboard/services/project.service';
import { Project } from 'src/app/data/model/project';
import { Environment } from 'src/app/data/model/environment';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';

@Component({
  selector: 'app-add-domain',
  templateUrl: './add-domain.component.html',
  styleUrls: ['./add-domain.component.scss'],
})
export class AddDomainComponent {
  public event: EventEmitter<any> = new EventEmitter();
  formSubmited: boolean = false;
  domainForm: UntypedFormGroup;
  error: any = null;
  loading: boolean = false;
  domain: Domain;
  isunsecure: boolean = false;
  scope: string = 'cluster';
  supportEmail: string = SUPPORT_EMAIL;
  onlyClusterShared: boolean = false;
  loaderCluster: boolean = false;
  loaderProject: boolean = false;
  loaderEnvironment: boolean = false;
  projects: Project[] = [];
  clusters: Cluster[] = [];
  environments: Environment[] = [];
  environment: Environment;
  currentUser: any = undefined;
  selectedValue: any;
  validateHost: any[] = [
    Validators.pattern('^(?:\\*\\.)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$'),
    Validators.required,
  ];

  constructor(
    private modalRef: BsModalRef,
    private service: AsideExtenderService,
    private projectService: ProjectService,
    private clusterService: ClusterService,
    private appService: AppService,
    private shareService: ShareService,
    public auth: AppAuthGuardService
  ) {}

  ngOnInit(): void {
    const session = JSON.parse(localStorage.getItem('session'));
    this.currentUser = session.currentUser;

    this.initForm();
    this.getClusters();
    this.getProjects();
  }

  get host() {
    return this.domainForm.get('host');
  }

  get name() {
    return this.domainForm.get('name');
  }

  get namespace() {
    return this.domainForm.get('namespace');
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onChangeScope(event: any) {
    this.scope = event.target.value;
    this.addOrClearValidator();
  }

  addOrClearValidator() {
    if (this.scope == 'cluster') {
      this.domainForm.get('clusterId').addValidators(Validators.required);
      this.domainForm.get('projectId').removeValidators(Validators.required);
      this.domainForm
        .get('environmentId')
        .removeValidators(Validators.required);
    } else {
      this.domainForm.get('projectId').addValidators(Validators.required);
      this.domainForm.get('environmentId').addValidators(Validators.required);
      this.domainForm.get('clusterId').removeValidators(Validators.required);
    }

    this.domainForm.get('projectId').updateValueAndValidity();
    this.domainForm.get('environmentId').updateValueAndValidity();
    this.domainForm.get('clusterId').updateValueAndValidity();
    this.domainForm.updateValueAndValidity();

    this.selectProjectIfOnce();
  }

  onChangeCluster(value: any) {}

  onFilter(value) {}

  onChangeProject(value: any) {
    const project = this.projects.find((p) => p.id == value);
    if (project?.environments) {
      this.environments = project?.environments;
    } else {
      this.environments = [];
    }

    this.selectEnvironmentIfOnce();
  }

  onChangeEnvironment(value: any) {
    this.environment = this.environments.find((e) => e.id == value);
    if (this.environment) {
      this.domainForm.patchValue({
        namespace: this.environment?.namespace,
      });
    }
  }

  canCreateOnlyScopeEnvironment() {
    if (this.auth.hasOrganizationRoles('owner')) {
      this.scope = 'environment';
      return true;
    }

    if (!this.auth.hasOrganizationRoles('super_admin')) {
      let authorizedProjects = [];

      this.projects.forEach((project) => {
        if (this.auth.hasProjectRoles('admin', project?.id)) {
          authorizedProjects.push(project);
        }
      });

      if (authorizedProjects.length > 0) {
        this.projects = authorizedProjects;
        this.selectProjectIfOnce();
        this.scope = 'environment';
        return true;
      }
    }

    return false;
  }

  selectProjectIfOnce() {
    if (this.projects.length == 1) {
      const project = this.projects[0];
      this.domainForm.get('projectId').setValue(project?.id);

      this.selectEnvironmentIfOnce();
    }
  }

  selectEnvironmentIfOnce() {
    let env = undefined;
    if (this.environments.length == 1) {
      env = this.environments[0];
    }
    this.domainForm.patchValue({
      environmentId: env?.id,
      namespace: env?.namespace,
    });
  }

  selectClusterIfOnce() {
    if (this.clusters.length == 1) {
      this.domainForm.patchValue({
        clusterId: this.clusters[0]?.id,
      });
    }
  }

  getClusters(): void {
    this.loaderCluster = true;
    this.domainForm.get('clusterId').disable();
    this.clusterService.getAll().subscribe((data) => {
      this.loaderCluster = false;
      this.clusters = data.records;
      this.domainForm.get('clusterId').enable();

      if (this.currentUser.email != this.supportEmail) {
        if (this.clusters.length == 1 && this.clusters.find((c) => c.shared)) {
          this.onlyClusterShared = true;
        } else {
          const index = this.clusters.findIndex((c) => c.shared);
          if (index !== -1) {
            this.clusters.splice(index, 1);
          }
        }
      }

      this.selectClusterIfOnce();
    });
  }

  getProjects(): void {
    this.loaderProject = true;
    this.loaderEnvironment = true;
    this.domainForm.get('projectId').disable();
    this.domainForm.get('environmentId').disable();
    this.projectService.getProjectsByOrganisation('').subscribe((response) => {
      this.loaderProject = false;
      this.projects = response.records;
      this.domainForm.get('projectId').enable();
      this.getEnvironment();
      this.loaderEnvironment = false;
      this.domainForm.get('environmentId').enable();
      this.selectProjectIfOnce();
    });
  }

  getEnvironment() {
    if (this.domain?.environmentID) {
      for (let index = 0; index < this.projects.length; index++) {
        const project = this.projects[index];
        this.environment = project.environments.find(
          (e) => e.id == this.domain?.environmentID
        );

        if (this.environment) {
          this.environments = project.environments;
          this.domainForm.patchValue({
            projectId: project.id,
            environmentId: this.environment.id,
          });
          break;
        }
      }
    }
  }

  onChangeIsSecure(event: any) {
    this.isunsecure = event.target.checked ? false : true;
    if (this.isunsecure) {
      this.domainForm.get('namespace').removeValidators(Validators.required);
      this.domainForm.get('name').removeValidators(Validators.required);
    } else {
      this.domainForm.get('namespace').addValidators(Validators.required);
      this.domainForm.get('name').addValidators(Validators.required);
    }

    this.domainForm.get('namespace').updateValueAndValidity();
    this.domainForm.get('name').updateValueAndValidity();
    this.domainForm.updateValueAndValidity();
  }

  initForm(): void {
    this.domainForm = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.domain?.id ? this.domain?.certificateSecretName : '',
        Validators.required
      ),
      host: new UntypedFormControl(
        this.domain?.id ? { value: this.domain?.host, disabled: true } : '',
        this.validateHost
      ),
      namespace: new UntypedFormControl(
        this.domain?.id ? this.domain?.certificateSecretNamespace : '',
        Validators.required
      ),
      isUnsecure: new UntypedFormControl(
        this.domain?.id ? this.domain?.isUnsecure : false
      ),
      clusterId: new UntypedFormControl(
        this.domain?.id ? this.domain?.clusterID : '',
        Validators.required
      ),
      projectId: new UntypedFormControl(''),
      environmentId: new UntypedFormControl(''),
    });
    if (this.domain?.id) {
      this.isunsecure = this.domain.isUnsecure;

      this.scope = 'cluster';
      if (this.domain?.environmentID) {
        this.scope = 'environment';
        this.addOrClearValidator();
      }
    }
  }

  formatDateToHuman(dateStr) {
    const date = moment(dateStr);
    return date.fromNow();
  }

  onSubmit(): void {
    if (this.domainForm?.valid) {
      const formValue = this.domainForm.value;
      let name, namespace;

      if (this.isunsecure) {
        name = '';
        namespace = '';
      } else {
        name = formValue.name;
        namespace = formValue.namespace;
      }

      let host = formValue.host.replace('*.', '');

      let clusterId = formValue.clusterId;
      let environmentId = '';

      if (this.scope == 'environment') {
        clusterId = this.environment.clusterId;
        environmentId = formValue.environmentId;
      }

      const dataToSend = {
        host: host,
        organizationId: this.shareService.organizationSelected.id,
        certificateSecretName: name,
        certificateSecretNamespace: namespace,
        isUnsecure: this.isunsecure,
        clusterId: clusterId,
        environmentId: environmentId,
      };

      this.loading = true;
      if (this.domain?.id) {
        this.appService.updateDomain(dataToSend, this.domain?.id).subscribe(
          (_) => {
            this.event.emit({ domain: _, res: 200, created: false });
            this.domain = {} as Domain;
            this.loading = false;
            this.initForm();
            this.onCloseDialog();
            this.error = null;
            this.service.show({
              title: 'Update Domain',
              message: 'Domain updated successfully',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
          },
          (er) => {
            this.loading = false;
            this.error = er;
            this.service.show({
              title: 'Update Domain',
              message: er.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
          }
        );
      } else {
        this.appService.createDomain(dataToSend).subscribe(
          (_) => {
            this.loading = false;
            this.initForm();
            this.event.emit({ domain: _, res: 200, created: true });
            this.onCloseDialog();
            this.error = null;
            this.service.show({
              title: 'Add Domain',
              message: 'Domain created successfully',
              type: Type.SUCCESS,
              position: Position.TOP,
            });
          },
          (er) => {
            this.loading = false;
            this.error = er;
            this.service.show({
              title: 'Add Domain',
              message: er.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
          }
        );
      }
    }
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { GitService } from 'src/app/shared/services/git.service';
import { AppService } from '../app.service';
import { ShareService } from 'src/app/data/service/share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Pipeline, Registry, UpdatePipeline } from 'src/app/utils/types';
import { ProjectService } from '../../onboard/services/project.service';
import { Project } from 'src/app/data/model/project';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, Type } from 'src/app/utils/const';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, last, take } from 'rxjs/operators';

type Code = {
defaultBranch: string,
full_name: string,
id: number,
name: string,
orgName: string,
ssh_url: string
}
type Framework = {
  name: string,
  value: string
}
type GitProvider = {
  name: string,
  value: string
}
@Component({
  selector: 'app-add-app',
  templateUrl: './add-app.component.html',
  styleUrls: ['./add-app.component.scss'],
})
export class AddAppComponent implements OnInit {
  openForm: UntypedFormGroup;
  repoUrl: string;
  projects: Project[] = [];
  repositories: any[] = [];
  registries: Registry[] = [];
  gitProvider: GitProvider;
  gitProviderToken: string;
  loading: boolean = false;
  currentSubscription: string = '';
  isOtherProvider: boolean = false;
  loadingProjects: boolean = false;
  loadingRegistries: boolean = false;
  isUpdateRepo: boolean = false;
  codeInitValue: any;
  pipelineId: string = '';
  isEditing: boolean = false;
  imageName: string = '';
  validateName: any[] = [
    Validators.pattern('[a-z]([-a-z0-9]*[a-z0-9])?'),
    Validators.maxLength(50),
    Validators.required,
  ];
  filteredOptions: Project[] = [...this.projects];
  defaultOptions: Project[] = [];
  showAddProjectButton: boolean = false;
  projectSelected: Project;
  isSelected: boolean = false;
  advanced: boolean = false;

  repoSelected: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    private gitService: GitService,
    private appService: AppService,
    private shareService: ShareService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
    private service: AsideExtenderService,
    public context: ModalOptions
  ) {}

  ngOnInit(): void {
    this.codeInitValue = this.context.initialState;
    this.currentSubscription =
      this.shareService.organizationSelected.subscriptionType;
    this.route.queryParams.subscribe((params) => {
      this.gitProviderToken = params['token'];
    });

    this.initForm();
    this.getProjects();
    this.getGitProvider();
    this.getAllRegistries();
    if (Object.keys(this.codeInitValue).length !== 0) {
      console.log('is entered');
      this.initUpdateForm(this.context.initialState);
      this.isUpdateRepo = true;
    }
  }

  onChangeAdvance() {
    this.advanced = !this.advanced;
  }
  initForm() {
    this.openForm = new UntypedFormGroup({
      host: new FormControl('', Validators.required),
      project: new FormControl('', Validators.required),
      registry: new FormControl('', Validators.required),
      name: new FormControl('', this.validateName),
      repoName: new FormControl(''),
      branchRef: new FormControl(''),
      repository: new FormControl('', Validators.required),
      framework: new FormControl('', Validators.required),
      imageName: new FormControl('', this.validateName),
    });
  }

  frameworks: Framework[] = [
    { name: 'Angular', value: 'Angular' },
    { name: 'Asp.net', value: 'Asp.net' },
    { name: 'C#', value: 'CSharp' },
    { name: 'Django', value: 'Django' },
    { name: 'Elixir', value: 'Elixir' },
    { name: 'Flask', value: 'Flask' },
    { name: 'Golang', value: 'Golang' },
    { name: 'Laravel', value: 'Laravel' },
    { name: 'Phoenix', value: 'Phoenix' },
    { name: 'NextJS', value: 'Nextjs' },
    { name: 'NodejS', value: 'Nodejs' },
    { name: 'NestJS', value: 'Nestjs' },
    { name: 'React', value: 'React' },
    { name: 'Springboot', value: 'Springboot' },
    { name: 'Vuejs', value: 'Vuejs' },
    { name: 'Others', value: 'Others' },
  ];

  gitProviders: GitProvider[] = [
    { name: 'Github', value: 'github' },
    { name: 'Gitlab', value: 'gitlab' },
    { name: 'GitlabServer', value: 'gitlabserver' },
    { name: 'Bitbucket', value: 'bitbucket' },
    { name: 'BitbucketServer', value: 'bitbucketserver' },
  ];

  filterOptions() {
    this.filteredOptions = this.projects.filter((option: Project) =>
      option.name
        .toLowerCase()
        .includes(this.openForm.value.project.toLowerCase())
    );
    this.isSelected = false;
    if (
      this.openForm.value.project == undefined &&
      this.openForm.value.project == '' &&
      this.filteredOptions.length === 0
    ) {
      this.defaultOptions = this.projects;
      this.showAddProjectButton = false;
    } else if (
      this.openForm.value.project !== undefined &&
      this.filteredOptions.length !== 0
    ) {
      this.defaultOptions = this.filteredOptions;
      this.showAddProjectButton = false;
    } else {
      this.defaultOptions = [];
      this.showAddProjectButton = true;
    }
  }
  myFunction() {
    if (
      (this.openForm.value.project == undefined ||
        this.openForm.value.project == '') &&
      this.filteredOptions.length === 0
    ) {
      this.defaultOptions = this.projects;
      this.showAddProjectButton = false;
    }
  }

  selectOption(option: Project) {
    this.projectSelected = option;
    this.filteredOptions = [];
    this.openForm.patchValue({
      project: option?.name,
    });
    if (option.name === this.openForm.value.project) {
      this.isSelected = true;
    }
  }

  toggleEdit(editableInput?: HTMLInputElement): void {
    this.isEditing = !this.isEditing;
    if (this.isEditing && editableInput) {
      setTimeout(() => {
        editableInput.focus();
      }, 0);
    }
  }
  addNewProject() {
    const project: any = {
      name: this.openForm.value.project,
      tags: [],
    };
    this.loadingProjects = true;
    this.projectService.create(project).subscribe(
      async (data) => {
        this.loadingProjects = false;
        this.getProjects();
        this.isSelected = true;
        this.openForm.patchValue({
          project: project.name,
        });
        this.service.show({
          title: 'Project',
          position: Position.TOP,
          type: Type.SUCCESS,
          message: 'Created successfully',
        });
      },
      (error) => {
        this.loadingProjects = false;
        this.service.show({
          title: 'Error Project',
          position: Position.TOP,
          type: Type.ERROR,
          message: error.error,
        });
      }
    );
  }

  initUpdateForm(data: any) {
    this.gitProvider = this.gitProviders.find(
      (host) => host.value === data?.gitProvider
    );
    this.pipelineId = data.id;
    if (data?.gitProvider === 'bitbucketserver' || data?.gitProvider === 'gitlabserver') {
      this.isOtherProvider = true;
    }
    this.repositories = [{ full_name: data?.repoName }];
    this.openForm.patchValue({
      host: this.gitProviders.find((host) => host.value === data?.gitProvider),
      branchRef: data?.branchRef,
      framework: this.frameworks.find(
        (framework) => framework.name === data?.framework || framework.value === data?.framework
      ),
      repository: this.isOtherProvider
        ? data?.repoURL
        : this.repositories?.find((repo) => repo.full_name === data?.repoName),
      repoName: this.isOtherProvider ? data?.name : data?.repoName,
      name: data?.name,
      imageName: data?.imageName,
    });

    this.openForm.controls['name'].disable();
    this.openForm.controls['imageName'].disable();
    this.openForm.controls['registry'].disable();
  }

  onSelectChange(): void {
    this.gitProvider = this.openForm.value.host;

    this.repositories = [];
    this.openForm.patchValue({
      dockerfilePath: '/',
    });
    if (
      this.gitProvider?.value == 'github' &&
      this.currentSubscription !== 'free' &&
      !this.isUpdateRepo
    ) {
      this.loginWithGithub();
    }
    if (
      this.gitProvider?.value == 'gitlab' &&
      this.currentSubscription !== 'free' &&
      !this.isUpdateRepo
    ) {
      this.loginWithGitlab();
    }
    if (
      this.gitProvider?.value == 'bitbucket' &&
      this.currentSubscription !== 'free' &&
      !this.isUpdateRepo
    ) {
      this.loginWithBitbucket();
    }
    if (
      this.gitProvider?.value == 'bitbucketserver' &&
      this.currentSubscription !== 'free'
    ) {
      this.isOtherProvider = true;
      this.openForm.patchValue({
        branchRef: 'master',
      });
    }
    if (
      this.gitProvider?.value == 'gitlabserver' &&
      this.currentSubscription !== 'free'
    ) {
      this.isOtherProvider = true;
      this.openForm.patchValue({
        branchRef: 'main',
      });
    }
    if (this.currentSubscription === 'free') {
      this.service.show({
        type: Type.ERROR,
        message: 'Forbidden',
        title: 'Create Pipeline',
        position: Position.TOP,
      });
    }
  }
  onChangeProject(): boolean {
    if (this.projects.length == 1) {
      this.openForm.controls['project'].setValue(this.projects[0]);
    }
    return true;
  }

  onChangeRegistry(): boolean {
    if (this.registries.length == 1) {
      this.openForm.controls['registry'].setValue(this.registries[0]);
    }
    this.repoUrl = this.openForm.value.registry.repo;
    return true;
  }

  onRepoChange(codeValue: Code): void {
    this.repoSelected = true;
    this.openForm.patchValue({
      branchRef: 'main',
    });
    const repo = this.openForm.controls['repository'].value;

    let repoNameArray;

    if (typeof repo == 'string') {
      repoNameArray = repo.split('/');
    }else{
      repoNameArray = repo?.name.split('/');
    }
    const repoName = repoNameArray[repoNameArray.length - 1].replace('.git', '');

    this.openForm.get('name').setValue(repoName);
    this.openForm.get('imageName').setValue(repoName);
    this.onChangeRegistry();
    if (this.openForm.get('name').invalid) {
      this.advanced = this.openForm.get('name').invalid;
    }
  }

  getGitProvider() {
    this.route.queryParams.subscribe((params) => {
      let currentUrl = window.location.href;
      const gitProvider = params['provider'];
      if (gitProvider !== undefined) {
        for (let item of this.gitProviders) {
          if (item.value === gitProvider) {
            this.gitProvider = item;
            break;
          }
        }
      }
      if (this.gitProvider && currentUrl.includes('apps')) {
        const selectedGitProvider = this.gitProviders.find(
          (item) => item.value === this.gitProvider.value
        );
        if (selectedGitProvider) {
          this.openForm.patchValue({
            host: selectedGitProvider,
            dockerfilePath: '/',
          });
          this.getRepos(selectedGitProvider, 1);
        }
      }
    });
  }

  getRepos(selectedValue: GitProvider, page: number): void {
    if (selectedValue?.value == 'github' && this.gitProviderToken !== null) {
      this.gitService
        .getUserRepositories(this.gitProviderToken, page)
        .then((response: any) => {
          this.repositories = this.repositories?.concat(
            response.data.map(
              ({ ssh_url, name, full_name, owner, id, default_branch }) => ({
                ssh_url,
                name,
                full_name,
                orgName: owner.login,
                id,
                defaultBranch: default_branch,
              })
            )
          );
          if (this.repositories?.length === 100) {
            this.getRepos(selectedValue, ++page);
          }
        })
        .catch((error: any) => console.log(error));
    } else if (
      selectedValue?.value == 'gitlab' &&
      this.gitProviderToken !== null
    ) {
      this.gitService
        .getGitlabRepositories(this.gitProviderToken)
        .subscribe((response: any) => {
          this.repositories = response.map(
            ({ ssh_url_to_repo, name, id, default_branch }) => ({
              ssh_url: ssh_url_to_repo,
              name,
              full_name: name,
              id,
              defaultBranch: default_branch,
            })
          );
        }),
        (error: any) => console.log(error);
    } else if (
      selectedValue?.value == 'bitbucket' &&
      this.gitProviderToken !== null
    ) {
      this.gitService
        .getBitbucketRepositories(this.gitProviderToken, page)
        .subscribe((response: any) => {
          this.repositories = this.repositories?.concat(
            response.values.map(({ links, full_name, uuid, mainbranch }) => ({
              ssh_url: links.clone[1].href,
              name: full_name,
              full_name,
              id: uuid,
              defaultBranch: mainbranch.name,
            }))
          );
          if (this.repositories?.length === 100) {
            this.getRepos(selectedValue, ++page);
          }
        }),
        (error: any) => console.log(error);
    }
  }

  getProjects() {
    this.loadingProjects = true;
    this.openForm.get('project').disable();
    this.projectService.getProjectsByOrganisation('').subscribe((projects) => {
      this.projects = projects.records;
      this.loadingProjects = false;
      this.openForm.get('project').enable();
      if (this.projects.length === 1) {
        this.openForm.patchValue({
          project: this.projects[0].name,
        });
        this.projectSelected = this.projects[0];
      }
      if (this.isUpdateRepo) {
        this.openForm.patchValue({
          project: this.projects?.find(
            (project) => project.id === this.codeInitValue?.projectId
          )?.name,
        });
        this.projectSelected = this.projects?.find(
          (project) => project.id === this.codeInitValue?.projectId
        );

        this.openForm.get('project').disable();
      }
    });
  }

  reloadPage() {
    this.loading = false;

    this.router.navigate(['/apps']).then((data) => {
      window.location.reload();
    });
  }

  redirectToProjectCreation() {
    this.onCloseDialog();
    this.router.navigate(['/onboard/projects'], {
      queryParams: { 'add-project': 'true' },
    });
  }

  redirectToRegistryCreation() {
    this.onCloseDialog();
    this.router.navigate(['/registry']);
  }

  getAllRegistries(): void {
    this.loadingRegistries = true;
    this.appService.getRegistries().subscribe(
      (_: Registry[]) => {
        this.registries = _;
        this.loadingRegistries = false;
        this.openForm.patchValue({
          registry: this.registries?.find(
            (registry) => registry.id === this.codeInitValue?.registryId
          ),
        });
      },
      (err) => {
        this.loadingRegistries = false;
        this.service.show({
          type: Type.ERROR,
          message: 'Fail to load registries.',
          title: 'Registry',
          position: Position.TOP,
        });
      }
    );
  }

  loginWithGithub() {
    const clientId = environment.githubClientID;
    const scope = 'user project admin:org repo';
    const redirectUri =
      environment.gitProviderRedirectURL +
      '?provider=github%26currentOrg=' +
      this.shareService.organizationSelected.name;
    const authorizationUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`;
    window.location.href = authorizationUrl;
  }
  loginWithGitlab() {
    const clientId = environment.gitlabClientID;
    const scope = 'api read_api read_user openid';
    const redirectUri =
      environment.gitProviderRedirectURL +
      '?provider=gitlab%26currentOrg=' +
      this.shareService.organizationSelected.name;
    const authorizationUrl = `https://gitlab.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUri}`;
    window.location.href = authorizationUrl;
  }

  loginWithBitbucket() {
    const clientId = environment.bitbucketClientID;
    const scope = 'account';
    const redirectUri =
      environment.gitProviderRedirectURL +
      '?provider=bitbucket%26currentOrg=' +
      this.shareService.organizationSelected.name;
    const authorizationUrl = `https://bitbucket.org/site/oauth2/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUri}`;
    window.location.href = authorizationUrl;
  }
  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onCreateCode() {
    this.openForm.get('project').enable();
    this.loading = true;
    let dataToSend = {
      repoName: this.isOtherProvider
        ? this.openForm.value.name
        : this.openForm.value.repository.full_name,
      repoURL: this.isOtherProvider
        ? this.openForm.value.repository
        : this.openForm.value.repository.ssh_url,
      framework: this.openForm.value.framework.value,
      organizationId: this.shareService.organizationSelected.id,
      gitProvider: this.gitProvider?.value,
      projectId: this.projectSelected.id,
      projectName: this.projectSelected.name,
      repositoryId: this.isOtherProvider
        ? ''
        : this.openForm.value.repository.id?.toString(),
      branchRef: this.openForm.value.branchRef,
      registryId: this.openForm.value.registry.id,
      name: this.openForm.value.name.toLowerCase(),
      imageName: this.openForm.value.imageName.toLowerCase(),
    } as Pipeline;
    this.appService
      .createPipeline(this.projectSelected.id, dataToSend)
      .subscribe(
        (response: Pipeline) => {
          this.createPipelineWebhookAndDeployKey(response);
        },
        (error) => {
          this.service.show({
            title: 'Add Code',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.openForm.get('project').disable();
          this.loading = false;
        }
      );
  }

  onUpdateCode() {
    this.loading = true;

    this.openForm.get('project').enable();
    this.openForm.controls['name'].enable();
    this.openForm.controls['registry'].enable();

    let dataToSend: UpdatePipeline = {
      framework: this.openForm.value.framework.value,
      branchRef: this.openForm.value.branchRef,
      registryId: this.openForm.value.registry.id,
      gitProvider: this.gitProvider?.value,
      projectName: this.projectSelected.name,
    };
    this.appService
      .updatePipeline(this.pipelineId, this.projectSelected.id, dataToSend)
      .subscribe(
        (response: Pipeline) => {
          this.reloadPage();

          this.service.show({
            title: 'Update Pipeline',
            message: 'Pipeline updated successfully',
            type: Type.SUCCESS,
            position: Position.TOP,
          });

          this.onCloseDialog();
        },
        (error) => {
          this.service.show({
            title: 'Update Pipeline',
            message: error.error,
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.openForm.get('project').disable();
          this.loading = false;
        }
      );
  }

  createPipelineWebhookAndDeployKey(pipeline: Pipeline) {
    const splitName = pipeline.repoName.split('/');
    let orgName = splitName[0];
    let repoName = splitName[1];
    return this.appService
      .getPipelineById(this.projectSelected.id, pipeline?.id)
      .subscribe(
        (response: Pipeline) => {
          let pipelineData = response;
          if (this.gitProvider.value == 'github') {
            this.appService
              .createWebhookForGithub(
                this.gitProviderToken,
                pipeline.projectId,
                pipelineData.id,
                pipelineData.secret,
                pipeline.gitProvider,
                repoName,
                orgName
              )
              .then(() => {
                this.appService
                  .createDeploymentKeyForGithub(
                    this.gitProviderToken,
                    repoName,
                    pipelineData.sshPublicKey,
                    orgName
                  )
                  .then(() => {
                    this.service.show({
                      title: 'Add Code',
                      message: 'Code created successfully',
                      type: Type.SUCCESS,
                      position: Position.TOP,
                    });
                    this.router.navigate(['/app-details'], {
                      queryParams: {
                        id: pipeline.id,
                        projectId: pipeline.projectId,
                        registryId: pipeline.registryId,
                      },
                    });

                    this.onCloseDialog();
                  });
              })
              .catch((error) => {
                this.service.show({
                  title: 'Add Code',
                  message: 'Webhook creation error.',
                  type: Type.ERROR,
                  position: Position.TOP,
                });
                this.loading = false;
                this.appService
                  .deletePipeline(pipeline)
                  .subscribe((response) => {
                    // console.log(response);
                  });
              });
          } else if (this.gitProvider.value == 'gitlab') {
            this.appService
              .createWebhookForGitlab(
                this.gitProviderToken,
                pipeline.projectId,
                pipeline.repositoryId,
                pipelineData.id,
                pipelineData.secret,
                pipeline.gitProvider
              )
              .subscribe(
                () => {
                  this.appService
                    .createDeploymentKeyForGitlab(
                      this.gitProviderToken,
                      pipelineData.sshPublicKey,
                      pipeline.repositoryId
                    )
                    .subscribe(() => {
                      this.service.show({
                        title: 'Add Code',
                        message: 'Code created successfully',
                        type: Type.SUCCESS,
                        position: Position.TOP,
                      });
                      this.router.navigate(['/app-details'], {
                        queryParams: {
                          id: pipeline.id,
                          projectId: pipeline.projectId,
                          registryId: pipeline.registryId,
                        },
                      });

                      this.onCloseDialog();
                    });
                },
                (error) => {
                  this.service.show({
                    title: 'Add Code',
                    message: 'Webhook creation error.',
                    type: Type.ERROR,
                    position: Position.TOP,
                  });
                  this.loading = false;
                  this.appService
                    .deletePipeline(pipeline)
                    .subscribe((response) => {
                      // console.log(response);
                    });
                }
              );
          } else if (this.gitProvider.value == 'bitbucket') {
            this.appService
              .createWebhookForBitbucket(
                this.gitProviderToken,
                pipeline.projectId,
                pipelineData.id,
                pipeline.gitProvider,
                orgName
              )
              .subscribe(
                () => {
                  this.appService
                    .createDeploymentKeyForBitbucket(
                      this.gitProviderToken,
                      orgName,
                      repoName,
                      pipelineData.sshPublicKey
                    )
                    .subscribe(() => {
                      this.service.show({
                        title: 'Add Code',
                        message: 'Code created successfully',
                        type: Type.SUCCESS,
                        position: Position.TOP,
                      });
                      this.router.navigate(['/app-details'], {
                        queryParams: {
                          id: pipeline.id,
                          projectId: pipeline.projectId,
                          registryId: pipeline.registryId,
                        },
                      });

                      this.onCloseDialog();
                    });
                },
                (error) => {
                  this.service.show({
                    title: 'Add Code',
                    message: 'Webhook creation error.',
                    type: Type.ERROR,
                    position: Position.TOP,
                  });
                  this.loading = false;
                  this.appService
                    .deletePipeline(pipeline)
                    .subscribe((response) => {
                      // console.log(response);
                    });
                }
              );
          } else if (this.gitProvider.value == 'bitbucketserver' || this.gitProvider.value == 'gitlabserver') {
            this.reloadPage();
          }
        },
        (error) => console.log(error)
      );
  }
  advancedCollapse() {
    this.advanced = !this.advanced;
  }
}

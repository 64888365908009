import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddAppComponent } from '../add-app/add-app.component';
import { AppService } from '../app.service';
import { Position, Type } from 'src/app/utils/const';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Application, Pipeline } from 'src/app/utils/types';
import { removeFilterStorage } from 'src/app/utils/app-init';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { ShareService } from 'src/app/data/service/share.service';

type GitProvider = {
  name: string,
  value: string
}
@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppDashboardComponent implements OnInit ,AfterViewInit{
  pipelines: Pipeline[] = [];
  pipelineToDelete: any;
  modalRef: BsModalRef;
  initialAppsValues: any[] = []
  currentFramework = '';
  currentProvider = '';
  loading: boolean = false;
  isDeleted: boolean = false;
  gitProviders: GitProvider[] = [
    { name: 'Github', value: "github" },
    { name: 'Gitlab', value: "gitlab" },
    { name: 'Bitbucket', value: "bitbucket" }
  ]

  hasNotAccess: boolean = false;

  ngOnInit(): void {
    this.getPipelines();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      const gitProvider = params["provider"]
      if (gitProvider !== undefined ) {
        setTimeout(() => {
          this.onAddPipeline()
        },1000)}
      const origin = params["operation"]
      if (origin == 'addcode') {
        setTimeout(() => {
          this.onAddPipeline()
        },1000)}
    })
    removeFilterStorage()

  }
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private appService: AppService,
    private service: AsideExtenderService,
    public auth: AppAuthGuardService,
    public shareService: ShareService,
    private route: ActivatedRoute
  ) {

  }


  getPipelines(isDeleted?: boolean) {

    if(!isDeleted) {this.loading = true}
    this.appService.getOrganisationPipelines()?.subscribe((response: any) => {
      this.pipelines = response
      this.initialAppsValues = response
      this.loading = false;
    },(error) =>{
      if (error.status === 403){
        this.hasNotAccess = true;
      }else{
        this.service.show({
          title: 'Get All Codes',
          message: error?.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
      this.loading = false;
    })
  }



  onAddPipeline(): void {
    const config: ModalOptions = { class: 'popup-right-side' };
    this.modalService.show(AddAppComponent, config);
  }

  goToProvider(): void {
    this.router.navigate(['/providers']);

  }
  onOpenDeleteModal(template: TemplateRef<any>, repo: any) {
    this.pipelineToDelete = repo
    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onOpenEditModal(repo: any) {
    const config: ModalOptions = { class: 'popup-right-side', initialState: repo };
    this.modalService.show(AddAppComponent, config);
  }

  onSubmitDeleteApp() {
    this.isDeleted = true
    if(this.isDeleted){
      this.appService.deletePipeline(this.pipelineToDelete).subscribe((response) => {
        this.getPipelines(this.isDeleted)
        this.onCloseDialog()
        this.service.show({
          title: 'Delete Pipeline',
          message: "Pipeline deleted successfully",
          type: Type.SUCCESS,
          position: Position.TOP,
        });
      }, (error) => {
        this.service.show({
          title: 'Delete Pipeline',
          message: error.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.onCloseDialog()
      });

    }
  }

  onCloseDialog(): void {
    this.modalRef.hide();
    this.isDeleted = false
  }

  onResetValues(){
    this.pipelines = this.initialAppsValues
  }

  onChangeFramework(data: string){
    if (data !== "" &&  this.currentProvider == "") {
      this.currentFramework = data
      let tabList = this.initialAppsValues.filter((app: Pipeline) => app.framework == data)
      this.pipelines = tabList
     }else if(data !== "" &&  this.currentProvider !== ""){
      this.currentFramework = data
      let tabList = this.initialAppsValues.filter((app: Pipeline) => app.framework === data && app.gitProvider === this.currentProvider);
      this.pipelines = tabList
     }else {
      this.onResetValues();
      this.currentFramework = ''
     }
  }

  onChangeProvider(data: string){
    if (data !== "" &&  this.currentFramework == "") {
      this.currentProvider = data
      let tabList = this.initialAppsValues.filter((app: Pipeline) => app.gitProvider == data)
      this.pipelines = tabList
    }else if(data !== "" &&  this.currentFramework !== ""){
      this.currentProvider = data
      let tabList = this.initialAppsValues.filter((app: Pipeline) => app.gitProvider === data && app.framework === this.currentFramework);
      this.pipelines = tabList
     }else {
      this.onResetValues();
      this.currentProvider = ''
     }
  }
  onCreateApplication(type: string) {
    this.router.navigate(['/apps'], {queryParams: {gitProvider: type}});
  }
  onViewPipeline(pipeline: Pipeline) {
    this.router.navigate(["/app-details"],{queryParams: {id: pipeline.id, projectId: pipeline.projectId,registryId: pipeline.registryId}});
  }

  canAddCode(){
    let session = JSON.parse(localStorage.getItem('session'));
    let roles = session?.currentUser?.roles;

    let canAdd = false;
    roles.forEach(role => {
      if (role.resource == 'project' && role.privilege == 'admin' && role.organizationId == this.shareService.organizationSelected.id) {
        canAdd = true;
      }
    });

    return canAdd;
  }
}

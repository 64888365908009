<div class="row div-container" *ngIf="!display">
    <div class="col-md-8 col-xl-8 _apps">
      <div class="heading">
        <span>Apps ({{totalApps}})</span>
        <div class="action__">
          <a  [routerLink]="'/apps'" [queryParams]="{operation: 'addcode'}"><i class="bi bi-plus"></i><span>{{'Code'}}</span></a>
        </div>
      </div>
      <div class="__applist">
        <div class="app-list-container" *ngIf="myApps?.length > 0">
          <div class="app-item card" *ngFor="let app of myApps ; let i = index">
            <div class="row no-gutters">
              <div class="col-auto p-2 app-logo" [class]="getClusterImageColor(app)">
                <img *ngIf="!app.shared" [src]="getClusterTypeImage(app.clusterType)" alt="Cloud provider image"
                  [ngClass]="{'doimage': app.clusterType =='digitalocean'}" />
                <img class="shared" *ngIf="app.shared" src="../../../../../assets/img/news/cloudoor-04.png" alt="Cloud provider image"/>
              </div>
              <div class="col">
                <div class="row no-gutters app-info">
                  <div class="col">
                    <a class="appName" (click)="gotoEditWorkloadView(app)">{{ app.metadata.name }}</a>
                  </div>
                  <div class="col-auto">
                    <div class="envirPro">
                      <div class="envName">
                        <a [routerLink]="['/deliver']"
                          [queryParams]="{projectId: app.projectId, envId: app.environmentId}">{{
                          app.environmentName }}</a>
                      </div>
                      <div class="d-flex product">
                        production
                        <div class="icon ml-3">
                          <i class="bi bi-circle-fill" [class.active]="app.isProd"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row no-gutters app-info py-2 align-items-center">
                  <div class="col">
                    <div class="row no-gutters leftinline align-items-center">
                      <div class="col-md-2">
                        <div class="appName">{{ displayStrategyName(app) }}</div>
                      </div>
                      <div class="col-md-7">
                        <div class="envName">
                          <div class="flex">
                            <div  *ngIf="app?.spec?.strategySpec?.live?.length > 0;" class="_align" >
                            <div style="padding: 5px 5px;"> <span class="bold">Live</span></div>
                            <div style="padding: 5px 5px;" [ngClass]="{'isBorder': app?.spec?.strategySpec?.live.length > 1 }">
                                  <ul *ngFor="let rec of app?.spec?.strategySpec?.live; let last = last;" class="ulStyled">
                                    <li class="wrapper_tooltip" ngClass="{'pl-5': last}">
                                      <span style="margin-right: 0px;">
                                        <div class="tooltip">
                                          <small>Version: {{rec.version}} </small><br>
                                          <small>Image: {{rec.image}} </small><br>
                                          <small>Container: {{rec.name}} </small>
                                        </div>
                                        {{ getVersion(rec.version) }}
                                      </span>
                                    </li>
                                  </ul>
                            </div>
                                <div>
                                  <span class="bold m-0" *ngIf="app?.spec?.deploymentStrategy === 'canary' ">{{ 100 - app.spec.strategySpec.trafficSplitting}}%</span>
                                </div>
                            </div>

                            <div *ngIf="app.spec.strategySpec?.staging?.length > 0;" class="div-container">
                              <span class="bar">|</span>

                              <div style="padding: 5px 5px;"> <span class="bold">Staging</span></div>

                              <div style="padding: 5px 5px;"
                                [ngClass]="{'isBorder': app.spec.strategySpec?.staging?.length > 1 }">
                                <ul *ngFor="let rec of app.spec.strategySpec.staging; let last = last;"
                                  class="ulStyled">
                                  <li class="wrapper_tooltip" ngClass="{'pl-5': last}">
                                    <span>
                                      <div class="tooltip">
                                        <small>Version: {{rec.version}} </small><br>
                                        <small>Image: {{rec.image}} </small><br>
                                        <small>Container: {{rec.name}} </small>
                                      </div>
                                      {{ getVersion(rec.version) }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <span class="bold m-0" *ngIf="app?.spec?.deploymentStrategy === 'canary'">{{
                                  app?.spec?.strategySpec?.trafficSplitting }}%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 success-contain">
                        <div *ngIf="parseValueDailyFlow(app) > 0" [ngClass]="{
                        'litinfo': isInfo(app),
                        'warninginfo': isWarning(app),
                        'litRed': isDanger(app) }">
                          <div class="px-2 successRate">Success rate:</div>
                          <div class="successRate">{{parseValueSuccessRate(app)}}%</div>
                        </div>
                        <div class="warninginfo" *ngIf="parseValueDailyFlow(app) == 0">
                          <div class="px-2 successRate"></div>
                          <div class="successRate">
                            <div class="wrapper_tooltip" ngClass="{'pl-5': last}">
                              <span style="padding: 3px 7px;width: 250px !important;"
                                class="tooltip info-metrics ">
                                Use CNO API Gateway to get metrics
                              </span>
                              <i class="bi bi-exclamation-circle" style="justify-content: end;display: flex;"></i>
                            </div>
                          </div>
                        </div>
                        <div class="envirPro d-flex justify-content-end">
                          <div class="last-update"><span class="envName" *ngIf="app?.lastUpdatedDate">Last Update :</span> &nbsp;{{
                            displayDateForHuman(app?.lastUpdatedDate) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <view-statement></view-statement> -->
      </div>

    </div>
    <div class="col-md-4 col-xl-4">

        <!-- cloud registration  button start -->
        <div class="form-btn" *ngIf="!isProviderAdded && isFirstClick == null  && isSelectProvider == false">
          <h4>Cloud registration</h4>
          <button class="buttonStyled" (click)="onChangeCloud('aws')">
            <span style="margin: 0px 20px;"><img src="../../../../../assets/img/aws.svg"></span>
            <p class="pstyled">Add existing cluster from AWS</p>
          </button>

          <button class="buttonStyled" (click)="onChangeCloud('gcp')">
            <span style="margin: 0px 20px;"><img src="../../../../../assets/img/gcp.svg"></span> <p class="pstyled">Add existing cluster
              from GCP</p>
          </button>

          <button class="buttonStyled" (click)="onChangeCloud('azure')">
            <span style="margin: 0px 20px;"><img src="../../../../../assets/img/Tracé-azure.svg"></span>
            <p class="pstyled">Add existing cluster from Azure</p>
          </button>
          <h4 class="on-prem">On-prem registation</h4>

          <p >You can integrate any Kubernetes clusters <br>(hosted by cloud providers or custom).</p>
          <button class="add-cluster-btn"  (click)="onAddCluster()">

            {{"Add Cluster"}}
          </button>
        </div>
        <!-- cloud registration  button end -->
        <!-- add provider form start -->

        <div class="form-btn" *ngIf="!isProviderAdded && isFirstClick == true && isSelectProvider == false">
            <select
            (change)="cloudChange($event)"
            class="input-select"
            [formControl]="selectControl"
          >
            <option value="aws" >Aws</option>
            <option *ngFor="let item of items" [value]="item.value">
            {{ item.name }}
            </option>
          </select>
          <div class="form" >
              <!-- aws section  -->
            <form [formGroup]="awsProviderForm"  style="width: inherit;" *ngIf="selectControl.value == 'aws'" >
              <div class="form-group">
                <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
              </div>
              <div class="form-group">
                <select class="form-control" formControlName="defaultRegion" id="defaultRegion" onfocus='this.size=1;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                  <option value="" selected disabled>{{'Default region*'}}</option>
                  <option [value]="item?.name" *ngFor="let item of regions; let i =index ">{{item?.label}}</option>
                </select>
              </div>
              <div class="form-group">
                <input id="accessKey" type="text" class="form-control" placeholder="Access Key ID*" autocomplete="off" formControlName="accessKey">
              </div>

              <div class="form-group">
                <input id="secretKey" type="password" class="form-control" placeholder="Secret Access Key*" autocomplete="off" formControlName="secretKey">
              </div>
              <div class="form-group">
                <input id="sessionToken" type="text" class="form-control"  autocomplete="off" formControlName="sessionToken"  placeholder="Session Token" >
              </div>
            </form>
            <!-- azure section  -->
              <form [formGroup]="azureProviderForm" style="width: inherit;" *ngIf="selectControl.value== 'azure'">
                 <div class="form-group">
                <select class="form-control" formControlName="defaultRegion" onfocus='this.size=1;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                  <option value="" selected disabled>{{'Default Location*'}}</option>
                  <option [value]="item?.name" *ngFor="let item of azureregions; let i =index ">{{item?.label}}</option>
                </select>
              </div>
                <div class="form-group">
                  <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
                </div>

                <div class="form-group">
                  <input id="clientId" type="text" class="form-control" placeholder="Client ID*" autocomplete="off" formControlName="clientId">
                </div>

                <div class="form-group">
                  <input id="clientSecret" type="password" class="form-control"  autocomplete="off" formControlName="clientSecret"  placeholder="Client Secret" >
                </div>
                <div class="form-group">
                  <input id="subscription" type="text" class="form-control" placeholder="Subscription ID*" autocomplete="off" formControlName="subscription">
                </div>
                <div class="form-group">
                  <input id="tenant" type="text" class="form-control" placeholder="Tenant ID*" autocomplete="off" formControlName="tenant">
                </div>
                <div class="form-group">
                  <input id="resourcegroup" type="text" class="form-control" placeholder="Resource Groupe name*" autocomplete="off" formControlName="resourcegroup">
                </div>

              </form>
              <!-- gcp section  -->

              <form [formGroup]="gcpProviderForm"  style="width: inherit;" *ngIf="selectControl.value == 'gcp'">
                 <div class="form-group">
                <select class="form-control" formControlName="defaultRegion" onfocus='this.size=1;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                  <option value="" selected disabled>{{'Default Zone*'}}</option>
                  <option [value]="item?.name" *ngFor="let item of gcpregions; let i =index ">{{item?.label}}</option>
                </select>
              </div>
                <div class="form-group">
                  <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
                </div>
                <input type="file" style="display: none;" #file (change)="upload($event)">
                <div class="form-group file-field mt-3">
                    <input type="text" class="form-control" [value]="filepreview" placeholder="File" (click)="file.click()">
                    <em class="fa fa-download" (click)="file.click()"></em>
                </div>
              </form>

          </div>
            <div style="display: flex;flex-direction: row;">
              <button class="buttonConfirm" (click)="save()" [disabled]="loading || (selectControl?.value==='gcp' && (gcpProviderForm?.invalid || file === undefined))
                      || (selectControl?.value==='aws' && awsProviderForm?.invalid)
                      || (selectControl?.value==='azure' && azureProviderForm?.invalid)">
                Connect your {{ selectControl.value}} cloud provider
              </button>
              <div class="spinner-border text-primary spinner-style" role="status" *ngIf="loading">
                  <span class="sr-only">Loading... </span>
              </div>

            </div>
            <a href="#" *ngIf="selectControl.value == 'aws'" (click)="openDocAws()" data-bs-toggle="modal" data-bs-target="#right" style="margin-bottom: 50px;">
              To grant eks access to role/user
            </a>
        </div>
        <!-- add provider form end -->

        <!-- select provider start -->
        <div class="form-btn" *ngIf="!isProviderAdded && isFirstClick == null && isSelectProvider == true">
          <h4>Providers</h4>
          <form [formGroup]="providerForm" (ngSubmit)="SaveExistingProvider(providerForm?.value)" class="form-select-provider">

          <div *ngFor="let provider of cloudProviders">
              <div  class="fetchClustersDiv">
                <input type="radio" [value]="provider?.id" formControlName="id" class="form-check-input">
                <span>{{provider?.cloudprovider }} ({{provider?.name }})</span>
              </div>
          </div>
            <div style="display: flex;flex-direction: row;">
                <button type="submit" class="buttonConfirm" [disabled]="loading || !providerForm.value.id">
                    Select provider
                </button>
                <div class="spinner-border text-primary spinner-style" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                  </div>
            </div>
            <div style="display: flex;flex-direction: row;">
              <button class="add-new-button" (click)="addNewProvider()" >
                  Add new provider
              </button>

          </div>
          <div style="display: flex;flex-direction: column; align-items: center;">
                <h4 class="on-prem">On-prem registation</h4>

          <p >You can integrate any Kubernetes clusters <br>(hosted by cloud providers or custom).</p>
          <button class="add-cluster-btn"  (click)="onAddCluster()">

            {{"Add Cluster"}}
          </button>
          </div>
          </form>
        </div>
        <!-- select provider end -->


        <!-- standard registation  (add cluster form) start -->

        <div *ngIf="!isProviderAdded && isFirstClick == false" class="form-btn" >
          <h4>Standard registation</h4>

         <div class="form">
          <form  [formGroup]="clusterForm" style="width: inherit;" >

            <div class="form-group">
              <input id="name" type="text" class="form-control" placeholder="Name*" autocomplete="off" formControlName="name">
            </div>
            <div class="form-group">
              <select class="form-control select-btn" formControlName="type" id="type" onfocus='this.size=1;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                <option value="" >{{'Cluster type'}}</option>
                <option [value]="item" *ngFor="let item of cloudType; let i =index ">{{item}}</option>
              </select>
            </div>
            <div class="form-group">
              <input id="name" type="text" class="form-control" placeholder="API server URL *" autocomplete="off" formControlName="apiServerUrl">
            </div>
              </form>
         </div>
            <button class="buttonConfirm" (click)="AddMyCluster()">
                Add my cluster
            </button>
        </div>
        <!-- standard registation  (add cluster form) end -->
        <!-- select cluster start -->

        <div *ngIf="isProviderAdded == true " class="form-btn" >
          <h4>Clusters</h4>

          <form [formGroup]="existClusterForm" (ngSubmit)="SaveExistingCluster()" style="display: flex;flex-direction: column; align-items: center;">
            <div  *ngIf="!['azure', 'gcp'].includes(providerSelected.cloudprovider)"  style="display: flex; flex-direction: column;">
              <select formControlName="region" id="region" (change)="regionChange($event)" form-control style="padding: 15px;" formControlName="region">
                <option value={region} selected > {{region}} </option>
                <option [value]="item?.name" *ngFor="let item of regions; let i =index ">{{item?.label}}</option>
              </select>
            </div>
          <div *ngFor="let cluster of fetchClusters">
              <div  class="fetchClustersDiv" [ngClass]="{'isNoAccess': !cluster.authorizedAccess}" style="width: 21rem;">
                <input type="radio" [value]="cluster.name" formControlName="cloudProviderName" class="form-check-input" [ngClass]="{'blockClick': !cluster.authorizedAccess}" >
                <span class="cluster-span">{{cluster.name }}</span> <span *ngIf="!cluster.authorizedAccess"  class="alert-icon"><i class="bi bi-exclamation-circle"></i>&nbsp;{{"No Access"}}</span>
              </div>
          </div>

          <div   style=" margin-top: 1rem;">
            <span  style="font-size: 18; font-weight: 700; color: red;" *ngIf=" isLoadingCluster == false && fetchClusters == null">{{"No Clusters"}}</span>
            <span  style="font-size: 18; font-weight: 700;" *ngIf="isLoadingCluster == true">{{"Loading Clusters ..."}}</span>

          </div>
            <div style="display: flex;flex-direction: row;">
                <button type="submit" class="buttonConfirm" [disabled]="loading">
                    Select my cluster
                </button>
                <div class="spinner-border text-primary spinner-style" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                  </div>
            </div>
          </form>
         </div>
        <!-- select cluster end -->

    </div>
</div>


<div class="__commands" *ngIf="display">
  <div class="__middle">
    <div class="__line">
      <span>Exports </span>
      <p id="custom-p">
        <code id="custom-code">
          <span>
              DOOR_AGENT_LICENCE={{cluster?.id}} <br>
              DOOR_AGENT_CA_CERT={{cluster?.caCert}} <br>
              DOOR_AGENT_USER_CERT={{cluster?.userCert}} <br>
              DOOR_AGENT_USER_KEY={{cluster?.userKey}} <br>
              KAFKA_BROKERS={{shareService.organizationSelected?.brokerUrl}} <br>
          </span>
        </code>
        <button (click)="onCopy('exportValue', 1)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 1,
              'bi-check-square': copyState == true && copiedIndex == 1
            }"
          ></em>
        </button>
      </p>
      <span>Create the namespace</span>
      <p>
        <code>
          <span>
            kubectl create namespace door-system
          </span>
        </code>
        <button (click)="onCopy('newNamespace', 2)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 2,
              'bi-check-square': copyState == true && copiedIndex == 2
            }"
          ></em>
        </button>
      </p>
      <span>Delete Secret if existing</span>
      <p>
        <code>
          <span>kubectl delete secret my-licence -n door-system</span>
        </code>

        <button (click)="onCopy('delSecret', 3)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 3,
              'bi-check-square': copyState == true && copiedIndex == 3
            }"
          ></em>
        </button>
      </p>
      <span>Create Secret</span>
      <p>
        <code>
          <span>kubectl create secret generic my-licence -n door-system --from-literal=uuid=$DOOR_AGENT_LICENCE --from-literal=user.key="$(echo $DOOR_AGENT_USER_KEY | base64 -d)" --from-literal=user.crt="$(echo $DOOR_AGENT_USER_CERT | base64 -d)" --from-literal=ca.crt="$(echo $DOOR_AGENT_CA_CERT | base64 -d)"</span>
        </code>

        <button (click)="onCopy('addSecret', 4)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 4,
              'bi-check-square': copyState == true && copiedIndex == 4
            }"
          ></em>
        </button>
      </p>
      <span>Add helm door repo</span>
      <p>
        <code>
          <span>helm repo add door-repo https://beopencloud.github.io/cno</span>
        </code>

        <button (click)="onCopy('addrepo', 5)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 5,
              'bi-check-square': copyState == true && copiedIndex == 5
            }"
          ></em>
        </button>
      </p>
      <span>Update Helm Repos</span>
      <p>
        <code>
          <span>helm repo update</span>
        </code>

        <button (click)="onCopy('updaterepo', 6)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 6,
              'bi-check-square': copyState == true && copiedIndex == 6
            }"
          ></em>
        </button>
      </p>
      <span>If your cluster already have metric server or the install fail change doorAgent.metricServer value to false</span>
      <p>
        <code>
          <span>helm install door door-repo/door-agent --set doorAgent.metricServer=true --set global.doorAPI.externalUrl={{baseUrl}} --set doorAgent.kafka.brokers=$KAFKA_BROKERS -n door-system --create-namespace</span>
        </code>

        <button (click)="onCopy('install', 7)">
          <em
            class="bi"
            [ngClass]="{
              'bi-files': copyState == false || copiedIndex != 7,
              'bi-check-square': copyState == true && copiedIndex == 7
            }"
          ></em>
        </button>
      </p>
    </div>
  </div>
  <div  class="__redirection">
    <a routerLink="/dashboard">
        <span>{{'Close'}}</span>
    </a>
  </div>
</div>

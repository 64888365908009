import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { forkJoin } from 'rxjs';
import { CloudProvider } from 'src/app/data/model/cloud-provider';
import { Cluster } from 'src/app/data/model/cluster';
import { Metrics } from 'src/app/data/model/metric';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { OrganizationService } from 'src/app/data/service/organization.service';
import { ShareService } from 'src/app/data/service/share.service';
import { AwsRoleDocComponent } from 'src/app/layouts/popups/aws-role-doc/aws-role-doc.component';
import { NoContactOrgComponent } from 'src/app/layouts/popups/no-contact-org/no-contact-org.component';
import { ClusterService } from 'src/app/modules/clusters/services/cluster.service';
import { CloudProviderService } from 'src/app/modules/parameters/services/cloud-provider.service';
import { Position, Type, regions } from 'src/app/utils/const';
import { UpgradePlanComponent } from 'src/app/layouts/popups/upgrade-plan/upgrade-plan.component';
import { DashboardService } from '../../services/dashboard.service';
import { Workload } from 'src/app/data/model/workload';
import * as moment from 'moment';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss'],
})
export class AddProviderComponent implements OnInit, AfterViewInit {
  cpuClustersMetrics: Metrics;
  nbClusters = 0;
  isFirstClick: any = null;
  isProviderAdded: boolean = false;
  isSelectProvider: boolean = false;
  cloudProviderId = null;
  region: string;
  typeCloudProvider = null;
  fetchClusters: Cluster[] = [];
  existClusterForm: UntypedFormGroup;
  providerForm: UntypedFormGroup;
  clusterForm: UntypedFormGroup;
  awsProviderForm: UntypedFormGroup;
  gcpProviderForm: UntypedFormGroup;
  azureProviderForm: UntypedFormGroup;
  selectControl: UntypedFormControl;
  loading = false;
  switchView: string = 'aws';
  myApps: Workload[] = [];
  totalApps: number = 0;
  items = [
    { name: 'Azure', value: 'azure' },
    { name: 'Google Cloud Provider', value: 'gcp' },
  ];
  file: any;
  providers = [];
  cloudProviders = [];
  filepreview = 'JSON File';
  awsdefaultregion: string;
  regions = regions.filter((m) => m.cloudprovider == 'aws');
  gcpregions = regions.filter((m) => m.cloudprovider == 'gcp');
  azureregions = regions.filter((m) => m.cloudprovider == 'azure');
  cloudType: string[] = [];
  providerSelected: any;
  isLoadingCluster: boolean;
  display: boolean = false;
  copiedIndex: any;
  copyState: boolean;
  baseUrl: string;
  instanceMoment = moment;
  cluster: Cluster;

  constructor(
    private clusterService: ClusterService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private service: AsideExtenderService,
    private cloudProviderService: CloudProviderService,
    private organizationService: OrganizationService,
    private modalService: BsModalService,
    private clipboardService: ClipboardService,
    public shareService: ShareService,
    private dashboardService: DashboardService
  ) {
    this.baseUrl = this.shareService.apiBaseURL;
  }

  ngOnInit(): void {
    this.initForm();
    this.initCloudProvider();
    this.isFirstClick = null;
    this.getCurrentRegion();
    this.getTypeClouds();
    this.getCloudProviders();
    this.initProviderForm();
    this.checkExistingCluster();
    this.getApps();
  }

  getClusterImageColor(app: Workload) {
    const successRate = 67;

    if (['canary', 'ab/testing'].includes(app?.spec?.deploymentStrategy)) {
      if (successRate > 0 && successRate <= 50) {
        return 'bg-litred';
      } else if (successRate > 50 && successRate <= 75) {
        return 'bg-yellow';
      } else 'bg-darkblue';
    }

    return 'bg-darkblue';
  }

  getClusterTypeImage(
    clusterType: 'gke' | 'eks' | 'aks' | 'openshift' | 'digitalocean'
  ): string {
    switch (clusterType) {
      case 'eks':
        return '/assets/img/aws-white.png';

      case 'aks':
        return '/assets/img/azure-icon.png';

      case 'gke':
        return '/assets/img/google-provider.png';

      case 'openshift':
        return '/assets/img/openshift.png';

      case 'digitalocean':
        return '/assets/img/digital-ocean.svg';
      default:
        return '/assets/img/google-provider.png';
    }
  }

  isInfo(app: any) {
    let val = app?.metrics?.live?.succesRate['5m']?.split(',')[0];
    if (val >= 90) {
      return true;
    } else {
      return false;
    }
  }
  isWarning(app: any) {
    let val = app?.metrics?.live?.succesRate['5m']?.split(',')[0];
    if (val < 90 && val >= 75) {
      return true;
    } else {
      return false;
    }
  }
  isDanger(app: any) {
    let val = app?.metrics?.live?.succesRate['5m']?.split(',')[0];
    if (val < 75) {
      return true;
    } else {
      return false;
    }
  }

  onCopy(state: string, i: any) {
    switch (state) {
      case 'exportValue':
        this.clipboardService.copy(
          'DOOR_AGENT_LICENCE=' +
            this.cluster?.id +
            'DOOR_AGENT_CA_CERT=' +
            this.cluster?.caCert +
            'DOOR_AGENT_USER_CERT=' +
            this.cluster?.userCert +
            'DOOR_AGENT_USER_KEY=' +
            this.cluster?.userKey +
            'KAFKA_BROKERS=' +
            this.shareService.organizationSelected.brokerUrl
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'newNamespace':
        this.clipboardService.copy('kubectl create namespace door-system');
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'delSecret':
        this.clipboardService.copy(
          'kubectl delete secret my-licence -n door-system'
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'addSecret':
        this.clipboardService.copy(
          `kubectl create secret generic my-licence -n door-system --from-literal=uuid=$DOOR_AGENT_LICENCE --from-literal=user.key="$(echo $DOOR_AGENT_USER_KEY | base64 -d)" --from-literal=user.crt="$(echo $DOOR_AGENT_USER_CERT | base64 -d)" --from-literal=ca.crt="$(echo $DOOR_AGENT_CA_CERT | base64 -d)"`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'addrepo':
        this.clipboardService.copy(
          `helm repo add door-repo https://beopencloud.github.io/cno`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'updaterepo':
        this.clipboardService.copy(`helm repo update`);
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      case 'install':
        this.clipboardService.copy(
          `helm install door door-repo/door-agent --set doorAgent.metricServer=true --set global.doorAPI.externalUrl=${this.baseUrl} --set doorAgent.kafka.brokers=$KAFKA_BROKERS -n door-system --create-namespace`
        );
        this.copyState = true;
        this.copiedIndex = i;
        setTimeout(() => {
          this.copyState = false;
        }, 1200);
        break;
      default:
        break;
    }
  }

  getApps(): void {
    this.dashboardService.getAllApps().subscribe({
      next: (resp: any) => {
        this.myApps = resp.records.filter((i: any) => !this.findNamespace(i.metadata.namespace));
        this.totalApps = resp.records.filter((i: any) => !this.findNamespace(i.metadata.namespace)).length;
      },
    });
  }

  displayDateForHuman(date: any) {
    if (date) {
      let parseToMoment = moment(date);
      return parseToMoment.fromNow();
    }
  }

  parseValueDailyFlow(app: any) {
    return parseInt(app?.metrics?.live?.flow['day']?.split(',')[0]);
  }

  parseValueSuccessRate(app: any) {
    return parseInt(app?.metrics?.live?.succesRate['5m']?.split(',')[0]);
  }

  displayStrategyName(app: any) {
    if (app.spec.deploymentStrategy == 'default') {
      return app.spec.strategySpec.autoDeploy
        ? 'Auto Delivery'
        : 'Manual Delivery';
    }
    return app.spec.deploymentStrategy;
  }

  gotoEditWorkloadView(app: Workload): void {
    this.router.navigateByUrl(
      `/deliver/${app.metadata.uid}?proj=${app.projectId}&env=${app.environmentId}`
    );
  }

  findNamespace(namespace: string) {
    const ns = ['door-apigateway', 'door-apigateway-internal','door-ci', 'door-monitoring','door-system']
      return ns.includes(namespace);
  }

  getVersion(version: string): string {
    return this.truncate(version, 11);
  }

  private truncate(str: string, maxlength: number) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  }

  getCloudProviders() {
    this.cloudProviderService.findAll().subscribe(
      (_) => {
        this.loading = false;
        this.cloudProviders = _?.records;
        if (this.cloudProviders.length > 0) {
          this.isSelectProvider = true;
        }
      },

      (err) => {
        this.loading = false;
        this.cloudProviders = [];
        this.service.show({
          type: Type.ERROR,
          message: err.error,
          title: 'Cloud Provider',
          position: Position.TOP,
        });
      }
    );
  }

  private checkExistingCluster(): void {
    this.clusterService.getAll().subscribe({
      next: (resp: any) => {
        if (resp.totalRecord > 1) {
          this.router.navigate(['/dashboard']);
        }
      },
    });
  }

  getCurrentRegion() {
    this.route.queryParams.subscribe((params) => {
      this.cloudProviderId = params.cloudProviderId;
      this.region = params.region;
      this.typeCloudProvider = params.type;
    });
    this.existClusterForm = this.formBuilder.group({
      cloudProviderId: [this.cloudProviderId],
      region: new UntypedFormControl(this.region),
      cloudProviderName: ['', Validators.required],
    });
  }

  getTypeClouds() {
    this.clusterService.getClusterTypes().subscribe((data) => {
      this.cloudType = data;
    }),
      (error) => {
        this.service.show({
          title: 'Failed to fetch cluster type',
          message: error.message,
          type: Type.ERROR,
          position: Position.TOP,
        });
      };
  }
  initForm() {
    this.clusterForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
      apiServerUrl: new UntypedFormControl('', Validators.required),
    });
  }
  initProviderForm() {
    this.providerForm = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
    });
  }
  initCloudProvider(): void {
    this.awsProviderForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      accessKey: new UntypedFormControl('', Validators.required),
      sessionToken: new UntypedFormControl(''),
      secretKey: new UntypedFormControl('', Validators.required),
      defaultRegion: new UntypedFormControl('', Validators.required),
    });

    this.azureProviderForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      clientId: new UntypedFormControl('', Validators.required),
      clientSecret: new UntypedFormControl(''),
      subscription: new UntypedFormControl('', Validators.required),
      tenant: new UntypedFormControl('', Validators.required),
      resourcegroup: new UntypedFormControl('', Validators.required),
      defaultRegion: new UntypedFormControl('', Validators.required),
    });

    this.gcpProviderForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      defaultRegion: new UntypedFormControl('', Validators.required),
      serviceAccount: new UntypedFormControl(''),
    });

    this.selectControl = new UntypedFormControl('');
  }

  openDocAws(): void {
    const config: ModalOptions = { class: 'popup-right-side' };
    this.modalService.show(AwsRoleDocComponent, config);
  }

  onAddCluster() {
    this.isFirstClick = false;
  }
  onChangeCloud(value: string) {
    this.isFirstClick = true;
    this.selectControl.setValue(value);
  }
  cloudChange(event: any) {
    let item = event.target.value;
    this.switchView = item;
  }
  getClustersMetrics(): void {
    forkJoin([
      this.clusterService.getClusterCpuMetrics(),
      this.clusterService.getClusterMemoryMetrics(),
    ]).subscribe(
      (data) => {
        this.cpuClustersMetrics = data[0];
        this.nbClusters = Object.keys(this.cpuClustersMetrics.data).length;
      },
      (er) => {
        console.log(er);
      }
    );
  }

  AddMyCluster(): void {
    if (this.clusterForm.valid) {
      const formValue = this.clusterForm.value;
      const cluster = new Cluster(
        '',
        formValue.name,
        formValue.type,
        formValue.apiServerUrl
      );
      this.loading = true;
      this.clusterService.create(cluster).subscribe(
        (data: any) => {
          this.loading = false;
          this.getClusterById(data?.id);
          this.display = true;

          this.service.show({
            title: 'Cluster',
            message: 'created successfully',
            position: Position.TOP,
            type: Type.SUCCESS,
          });
        },
        (er) => {
          this.loading = false;
          this.service.show({
            title: 'Cluster',
            message: er.error,
            position: Position.TOP,
            type: Type.ERROR,
          });
        }
      );
    }
  }

  getClusterById(clusterId: string): void {
    this.clusterService
      .getById(clusterId)
      .toPromise()
      .then(
        (data) => {
          this.cluster = data;
          this.clusterForm.patchValue({
            name: data.name,
            type: data.type,
            apiServerUrl: data.apiServerUrl,
          });
        },
        (er) => {
          this.service.show({
            title: 'Cluster',
            message: er.error,
            position: Position.TOP,
            type: Type.ERROR,
          });
        }
      );
  }

  save() {
    const cloudprovider = new CloudProvider();
    if (this.selectControl.value == 'aws' && this.awsProviderForm?.valid) {
      this.loading = true;
      const awsform = this.awsProviderForm.value;
      cloudprovider.name = awsform.name;
      cloudprovider.cloudprovider = 'aws';
      cloudprovider.awsaccesskeyid = awsform.accessKey;
      cloudprovider.awssecretaccesskey = awsform.secretKey;
      cloudprovider.awsSessionToken = awsform.sessionToken;
      cloudprovider.awsdefaultregion = awsform.defaultRegion;
    }

    if (
      this.selectControl.value == 'gcp' &&
      this.gcpProviderForm?.valid &&
      this.file
    ) {
      this.loading = true;
      const gcpform = this.gcpProviderForm.value;
      cloudprovider.name = gcpform.name;
      cloudprovider.cloudprovider = 'gcp';
      cloudprovider.gcpserviceaccountjsonkeyfile = this.file.replace(
        'data:application/json;base64,',
        ''
      );
    }

    if (this.selectControl.value == 'azure' && this.azureProviderForm?.valid) {
      this.loading = true;
      const azureform = this.azureProviderForm.value;
      cloudprovider.name = azureform.name;
      cloudprovider.armclientid = azureform.clientId;
      cloudprovider.armclientsecret = azureform.clientSecret;
      cloudprovider.armsubscriptionid = azureform.subscription;
      cloudprovider.armtenantd = azureform.tenant;
      cloudprovider.cloudprovider = 'azure';
      cloudprovider.armresourcegroupname = azureform.resourcegroup;
    }

    this.cloudProviderService.create(cloudprovider).subscribe(
      (data: any) => {
        this.providers.unshift(data);
        this.service.show({
          title: 'Cloud provider',
          message: 'has been added',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
        this.file = undefined;
        const queryParams = {
          cloudProviderId: data.id,
          region: data.awsdefaultregion,
        };
        this.cloudProviderId = data.id;
        this.providerSelected = data;
        this.loading = false;
        if (this.selectControl.value == 'aws') {
          this.isProviderAdded = true;
        } else {
          this.isProviderAdded = false;
          this.isFirstClick = null;
          this.isSelectProvider = false;
        }
        this.checkExistingCluster()
        this.SaveExistingProvider(data);
      },
      (error) => {
        this.service.show({
          title: 'Cloud Provider',
          message: error.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.loading = false;
      }
    );
  }

  upload(event) {
    this.filepreview = event.target.files[0]?.name;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.file = reader.result;
    };

    let extension = this.filepreview.split('.')[1];

    if (extension != 'json') {
      this.file = null;
      this.filepreview = null;
      this.service.show({
        title: 'File',
        message: 'Your file format is incorrect. Please upload json file',
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
  }

  makeOrganizationConfigComplited() {
    this.organizationService.makeOrganizationConfigComplited().subscribe(
      (data) => {},
      (error) => {
        this.service.show({
          title: 'Failed to select cluster',
          message: error.message,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
    );
  }

  SaveExistingCluster() {
    if (this.existClusterForm.valid) {
      const formValue = this.existClusterForm.value;
      this.loading = true;
      formValue.cloudProviderId = this.cloudProviderId;

      this.clusterService.addExistingCluster(formValue).subscribe(
        (data) => {
          this.awsdefaultregion = data.awsdefaultregion;

          this.makeOrganizationConfigComplited();

          this.service.show({
            title: 'Cluster',
            message: 'Add Successfully',
            position: Position.TOP,
            type: Type.SUCCESS,
          });
          this.loading = false;
          this.isProviderAdded = false;
          this.isSelectProvider = false;
          this.router.navigate(['/clusters']);
        },
        (error) => {
          if (error.status == 400) {
            this.service.show({
              title: 'Cluster',
              message: 'Duplicate apiServerUrl registered.',
              position: Position.TOP,
              type: Type.ERROR,
            });
          }
          if (error.status == 412) {
            this.service.show({
              title: 'Cluster',
              message: 'Cloud provider unauthorized for cluster',
              position: Position.TOP,
              type: Type.ERROR,
            });
          }
          this.loading = false;
        }
      );
    }
  }
  SaveExistingProvider(cloudprovider) {
    // const formValue = this.providerForm.value;
    const formValue = cloudprovider;
    this.isLoadingCluster = true;
    this.cloudProviders.map((item: any) => {
      if (item.id == formValue.id) {
        this.providerSelected = item;
      }
    });
    this.cloudProviderId = this.providerSelected.id;
    this.existClusterForm = this.formBuilder.group({
      cloudProviderId: [this.cloudProviderId],
      region: new UntypedFormControl(this.region),
      cloudProviderName: ['', Validators.required],
    });
    this.typeCloudProvider = this.providerSelected.cloudprovider;
    if (this.providerSelected.cloudprovider == 'aws') {
      this.existClusterForm
        .get('region')
        .setValue(this.providerSelected.awsdefaultregion);
      this.clusterService
        .getClusterRegion(
          this.providerSelected.id,
          this.providerSelected.awsdefaultregion
        )
        .subscribe(
          (data) => {
            this.isLoadingCluster = false;
            this.fetchClusters = data;
          },
          (error) => {
            //
          }
        );
    } else {
      this.clusterService
        .getExistingCluster(this.providerSelected.id)
        .subscribe(
          (data) => {
            this.isLoadingCluster = false;
            this.fetchClusters = data;
          },
          (error) => {
            //
          }
        );
    }
    this.isProviderAdded = true;
  }

  regionChange(event: any) {
    const selectedRegion = event.target.value;
    this.isLoadingCluster = true;
    this.existClusterForm.patchValue({ region: selectedRegion });
    this.clusterService
      .getClusterRegion(this.cloudProviderId, selectedRegion)
      .subscribe(
        (data) => {
          this.isLoadingCluster = false;
          this.fetchClusters = data;
        },
        (error) => {
          //
        }
      );
  }

  addNewProvider() {
    this.isSelectProvider = false;
    this.isFirstClick = true;
    this.selectControl.setValue('aws');
  }

  ngAfterViewInit(): void {
    // if (this.shareService.organizationSelected && this.shareService.isFree) {
    //   setTimeout(() => {
    //     const config: any = {
    //       class: 'modal-xl modal-dialog-centered p-5',
    //       backdrop: 'static',
    //       animated: true,
    //       keyboard: false,
    //       initialState: {
    //         close: false,
    //       },
    //     };
    //     this.modalService.show(UpgradePlanComponent, config);
    //   }, 1000)
    // }
  }
}

<div style="display:flex; flex-direction: column;text-align: center;margin-top: 33vh;" *ngIf="!globalLoader">
  <spinner [status]="status"></spinner>
  <span style="color: #043c66; font-size: 20px;">Loading</span>
</div>
<div class="modal-content msg-content" *ngIf="globalLoader">
    <div class="modal-header msg-head align-items-center">
      <h5 class="modal-title msgtitle" id="exampleModalLabel">
        <button type="button" class="btn btn-primary link-btn mr-4" data-bs-toggle="modal" data-bs-target="#right">
          <i class="bi bi-pencil-fill"></i>
        </button>
        <span>
          {{ buildConfInitValue?.data ? 'Update Configuration' : 'Add New Configuration' }}
        </span>
      </h5>

      <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
    </div>

    <div class="modal-body">
      <form [formGroup]="openForm">
        <div class="row">
            <div class="col-md-12 col-xl-12 div-input"  >
                <label> Name*</label>
                <input id="name" class="form-control"  type="text" autocomplete="off" formControlName="name"  placeholder="name*" style="margin: 0px !important;height: 2.7rem !important;" >
              </div>
              <div class="col-md-12 col-xl-12 div-input"  >
                <label> Image*</label>
                <div style="display: flex;">
                  <input class="form-control"  type="text" autocomplete="off" formControlName="repoUrl"  placeholder="image name*" style="margin: 0px !important;height: 2.7rem !important;" readonly>
                  <input class="form-control"  type="text" autocomplete="off" formControlName="imageName" style="margin: 0px !important;height: 2.7rem !important;">
                </div>
              </div>
              <div class="col-md-12 col-xl-12 div-input"  >
                <label> Dockerfile path*</label>
                <input id="image" class="form-control"  type="text" autocomplete="off" formControlName="dockerfilePath"  placeholder="set / as default*" style="margin: 0px !important;height: 2.7rem !important;" >
              </div>
              <div class="col-md-12 col-xl-12 div-input" >
                <label> Branchs*</label>
                <div style="display: flex;flex-direction: row;" >
                    <div *ngFor="let item of otherBranchs; let i = index" class="branch-list">
                    <span class="list-branch" >{{item}}<i class="bi bi-x" (click)="onDeleteOtherBranch(i)"></i></span>
                    </div>
                </div>
                <div class="push-branch" style="display: flex;flex-direction: row; width: auto;">
                    <input id="branch" class="form-control"  type="text" autocomplete="off" formControlName="branch"  placeholder="Branch name*" style="margin: 0px !important;height: 2.7rem !important;" >
                    <button class="add-branch" (click)="pushOtherBranch()"> <i class="bi bi-plus" style="color: #fff;"></i> &nbsp;&nbsp;Add</button>

                </div>
              </div>
              <div class="col-md-12 col-xl-12 div-input">
                <label> Severity level* </label>
                <select class="" formControlName="scanSeverity" >

                  <option *ngFor="let item of severetyLevels; let i =index " [ngValue]="item">
                     {{ item.name }}
                  </option>
                </select>
              </div>

          <div class="col-md-12 col-xl-12 div-args-input">
            <label style="color: #33517A;"> Build Args </label>

          </div>
            <div formArrayName="buildArgs" class="col-md-12 col-xl-12 build-args mt-5 mb-2">
              <div class="row mb-2 pl-3" *ngFor="let buildArg of buildArgs.controls; let i = index" [formGroupName]="i">
                <div class="col-md-4">
                  <div class="title_value mb-2">Key {{ i + 1 }}</div>
                  <input type="text" formControlName="key" class="form-input" placeholder="Key" />
                  <div class="text-right d-flex justify-content-between">
                    <small *ngIf="buildArg.get('key').invalid" style="color: #ff6b6b; font-size: 11px;">Invalid key</small>
                    <small *ngIf="buildArg.get('key').hasError('duplicate')" style="color: #ff6b6b; font-size: 11px;">Duplicate key</small>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="title_value mb-2">Value {{ i + 1 }}</div>
                  <input type="text" formControlName="value" class="form-input" placeholder="Value" />
                </div>
                <div class="col-md-1 pt-4">
                  <button (click)="onRemoveBuildArg(i)" class="btn_delete">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-xl-12" style="color: #ff6b6b;" *ngIf="openForm.get('buildArgs').invalid">
                  <ul style="font-size: 14px;">
                    <li>Delete spaces at beginning and end of key</li>
                    <li>Delete spaces in key</li>
                    <li>Remove special characters from key</li>
                    <li>The key and value are required</li>
                  </ul>
                </div>
                <div class="col-md-12 col-xl-12 _actions">
                  <button class="btn btn_add" (click)="onAddBuildArg('', '')" [disabled]="openForm.get('buildArgs').invalid">
                    <i class="fa fa-plus"></i> Build Argument
                  </button>
                  <config-upload (envVars)="loadEnvs($event)"></config-upload>
                </div>
              </div>
            </div>
          <div class="col-md-12 col-xl-12 div-button" >
            <button class="create-arg" (click)="onSubmit()"  [disabled]="loading || openForm.invalid || openForm.get('buildArgs').invalid" >
              {{ buildConfInitValue?.data ? 'Update Configuration' : 'Create Configuration' }}
            </button>
          </div>
        </div>
      </form>


    </div>
  </div>

import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, SUPPORT_EMAIL, Type } from 'src/app/utils/const';
import { AppService } from '../app.service';
import { Domain } from 'src/app/utils/types';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { AddDomainComponent } from './add-domain/add-domain.component';
import { ClusterService } from '../../clusters/services/cluster.service';
import { Cluster } from 'src/app/data/model/cluster';

declare var $: any;
export function equalsTo(name: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value?.toLowerCase() === name?.toLowerCase()
      ? null
      : { wrongColor: control.value };
}

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss'],
})
export class DomainComponent {
  formSubmited = false;
  domains: Domain[] = [];
  error: any = null;
  domainSelected: Domain;
  currentModal: string = '';
  domainLoaded: boolean = false;
  loading: boolean = false;
  deleteDomainForm: UntypedFormGroup;
  formDisable: boolean = false;
  onlyClusterShared: boolean = false;
  currentDomain: Domain;
  bsModalRef: BsModalRef;
  clusters: Cluster[] = [];
  currentCluster: Cluster;
  display = false;
  currentUser: any;
  supportEmail: string = SUPPORT_EMAIL;

  constructor(
    private _location: Location,
    private modalService: BsModalService,
    private service: AsideExtenderService,
    private appService: AppService,
    private clusterService: ClusterService,
    public auth: AppAuthGuardService
  ) {}

  ngOnInit(): void {
    const session = JSON.parse(localStorage.getItem('session'));
    this.currentUser = session.currentUser;
    this.getAllDomains();
  }

  onOpen(): void {
    const config: any = {
      class: 'popup-right-side',
      initialState: {
        domain: this.currentDomain,
      },
    };

    this.bsModalRef = this.modalService.show(AddDomainComponent, config);

    this.bsModalRef.content.event.subscribe((res) => {
      if (res.created) {
        this.currentCluster = this.clusters.find(
          (c) => c.id == res?.domain?.clusterID
        );
      }

      this.getAllDomains();
    });
  }

  onCloseBanner(elId) {
    setTimeout(() => {
      document.getElementById(elId).innerHTML = '';
      document.getElementById(elId).style.width = '0';
      document.getElementById(elId).style.padding = '0';
      document.getElementById(elId).style.background = 'transparent';
    }, 100);
  }

  onCreate(domain) {
    this.currentDomain = null;
    this.onOpen();
  }

  onEdit(domain) {
    this.currentDomain = domain;
    this.onOpen();
  }

  getClusters(): void {
    this.clusterService.getAll().subscribe((data) => {
      this.domainLoaded = true;
      this.clusters = data.records;
      if (this.currentUser.email != this.supportEmail) {
        if (this.clusters.length == 1 && this.clusters.find((c) => c.shared)) {
          this.onlyClusterShared = true;
        }
      }
    });
  }

  getClusterName(clusterId) {
    const cluster = this.clusters.find((c) => c.id == clusterId);
    if (cluster) {
      return cluster.name;
    }
    return '';
  }

  initDeleteForm() {
    this.deleteDomainForm = new UntypedFormGroup({
      host: new UntypedFormControl('', [
        Validators.required,
        equalsTo(this.domainSelected?.host),
      ]),
    });

    this.deleteDomainForm.statusChanges.subscribe((_) => {
      if (_ == 'INVALID') {
        this.formDisable = false;
      }

      if (_ == 'VALID') {
        this.formDisable = true;
      }
    });
  }

  async onCopyApiGatewayUrl(clusterId: any) {
    if (this.clusters.length > 0) {
      const findCluster = this.clusters.find((c) => c?.id == clusterId);
      if (findCluster) {
        try {
          await navigator.clipboard.writeText(findCluster?.apiGatewayAddresses);
          this.service.show({
            title: 'Copy success',
            message: 'Link has been copied',
            type: Type.SUCCESS,
            position: Position.TOP,
          });
        } catch (err) {
          this.service.show({
            title: 'Copy error',
            message: err,
            type: Type.ERROR,
            position: Position.TOP,
          });
        }
      }
    }
  }

  selectApiGatewayUrl(clusterId: string, elipsis: boolean) {
    if (this.clusters.length > 0) {
      const findCluster = this.clusters.find((c) => c?.id == clusterId);
      if (findCluster) {
        let str = findCluster?.apiGatewayAddresses;

        if (elipsis) {
          str = str.length > 15 ? str.substring(0, 15) + '...' : str;
        }

        return [str, findCluster?.type];
      }
    }
    return [null, null];
  }

  getAllDomains(): void {
    this.domainLoaded = false;
    this.appService.getDomains().subscribe(
      (_: Domain[]) => {
        this.getClusters();
        if (this.auth?.hasOrganizationRoles('super_admin') || this.auth?.hasOrganizationRoles('owner')) {
          this.domains = _;
        } else {
          this.domains = [];
          this.service.show({
            type: Type.ERROR,
            message: 'Permission denied',
            title: 'Domain',
            position: Position.TOP,
          });
        }
      },
      (err) => {
        this.service.show({
          type: Type.ERROR,
          message: err.error,
          title: 'Domains',
          position: Position.TOP,
        });
        this.domainLoaded = false;
      }
    );
  }

  formatDateToHuman(dateStr) {
    const date = moment(dateStr);
    return date.fromNow();
  }

  goBack(): void {
    this._location.back();
  }

  close(): void {
    $(`#${this.currentModal}`).modal('hide');
  }
  toggleModal(modalId: string, item: Domain): void {
    this.domainSelected = item;
    this.currentModal = modalId;
    $(`#${modalId}`).modal('show');
    this.initDeleteForm();
  }

  deleteDomainById(): void {
    this.appService.deleteDomain(this.domainSelected.id).subscribe(
      () => {
        this.getAllDomains();
        this.service.show({
          title: 'Delete Domain',
          message: 'Domain deleted successfully',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
        this.close();
      },
      (error) => {
        this.service.show({
          title: 'Delete Domain',
          message: error?.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.close();
      }
    );
  }
}

import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import {ShareService} from './share.service';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  socket;
  constructor(private shareService: ShareService) {   }
  setupSocketConnection(token: any): void {
    // this.socket = io(this.shareService.socketURL, {query: 'token=' + token});
  }
}

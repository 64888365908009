import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { AppService } from 'src/app/modules/app/app.service';
import { Position, RESOURCES, Type } from 'src/app/utils/const';


type FormData = {
  applicationID: string,
  applicationName: string,
  branchRef: string,
  organizationId: string,
  projectId: string
}
@Component({
  selector: 'app-run-new-pipeline',
  templateUrl: './run-new-pipeline.component.html',
  styleUrls: ['./run-new-pipeline.component.scss'],
})
export class RunNewPipelineComponent implements OnInit {
  application: any;
  pipeForm: UntypedFormGroup;

  // build config
  loadingBuildConfig: boolean = false;
  buildconfigs: any = [];
  submitStateButton = false;

  resources = RESOURCES;
  defaultEnvironments = ['dev', 'preprod', 'prod'];
  defaultProdEnvironments = ['preprod', 'prod'];

  constructor(
    public modalRef: BsModalRef,
    private service: AsideExtenderService,
    public auth: AppAuthGuardService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getBuildconfigPipeline();
  }

  initForm() {
    this.pipeForm = new UntypedFormGroup({
      branchName: new UntypedFormControl('', [Validators.required]),
      version: new UntypedFormControl('', [Validators.required]),
      dockerImage: new UntypedFormControl('', Validators.required),
    });
  }

  getBuildconfigPipeline() {
    this.loadingBuildConfig = true;
    this.appService
      .getBuildconfigPipeline(this.application.projectId, this.application.id)
      .subscribe((buildconfigs: any) => {
        this.buildconfigs = buildconfigs;
        this.loadingBuildConfig = false;
      });
  }

  submitRunNewPipeline() {
    this.submitStateButton = true;
    if (this.pipeForm.controls.branchName.invalid) {
      this.service.show({
        title: 'Failed to run the pipeline',
        message: 'Branch name is incorrect or missing.',
        type: Type.ERROR,
        position: Position.TOP,
      });
    } else if (this.pipeForm.controls.version.invalid) {
      this.service.show({
        title: 'Failed to run the pipeline',
        message: 'The version is incorrect or missing.',
        type: Type.ERROR,
        position: Position.TOP,
      });
    } else if (this.pipeForm.controls.dockerImage.invalid) {
      this.service.show({
        title: 'Failed to run the pipeline',
        message: 'The docker image is incorrect or missing.',
        type: Type.ERROR,
        position: Position.TOP,
      });
    } else {
      var pipeInfo: any = {
        branchName: this.pipeForm.get('branchName').value,
        version: this.pipeForm.get('version').value,
        dockerImage: this.pipeForm.get('dockerImage').value,

      };
      let dataToSend: any = {
        branchRef: pipeInfo.branchName,
        version: pipeInfo.version,
        message: pipeInfo.version,
        buildConfigId: pipeInfo.dockerImage,
      };

      this.appService
        .runPipeline(
          this.application.projectId,
          this.application.id,
          dataToSend
        )
        .subscribe(
          (response: any) => {
            if (response.status==200) {
              this.service.show({
                title: 'Run Pipeline',
                message: 'Run pipeline with success',
                type: Type.SUCCESS,
                position: Position.TOP,
              });
            }
            this.onCloseDialog();
            this.submitStateButton = false;
          },
          (error) => {
            this.service.show({
              title: 'Run Pipeline',
              message: error.error,
              type: Type.ERROR,
              position: Position.TOP,
            });
            this.onCloseDialog();
            this.submitStateButton = false;
          }
        );
    }
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  onChangeConfigration(): boolean {
    console.log(this.pipeForm.value);
    let buildConfigId = this.pipeForm.get('dockerImage').value;
    if(this.buildconfigs.length == 1) {
      buildConfigId = this.buildconfigs[0].id;
      this.pipeForm.controls["dockerImage"].setValue( buildConfigId)
    }
    let configuration = this.buildconfigs.find((bc) => bc.id === buildConfigId);
    console.log(configuration);
    console.log(this.buildconfigs);
    console.log(buildConfigId);
    if (configuration !== -1) {
      if (!this.canRunOrDeploy(configuration)) {
        this.pipeForm.get('dockerImage').setValue('');
      }
    }

    console.log(this.pipeForm.value);
    return true
  }

  canRunOrDeploy(buildConfig) {
   let canRunOrDeploy = false;

    if (['dev', 'preprod'].includes(buildConfig.name)) {
      if (this.auth?.hasProjectRoles(this.resources.project.role.developer,buildConfig.projectId)) {
         canRunOrDeploy = true;
      }
    }

    if (this.defaultEnvironments.includes(buildConfig.name)) {
      if (
        this.auth?.hasOrganizationRoles('super_admin') ||
        this.auth?.hasOrganizationRoles('owner+') ||
        this.auth?.hasOrganizationRoles('owner') ||
        this.auth?.hasProjectRoles('admin', buildConfig.projectId)
      ) {
        canRunOrDeploy = true;
      }
    }

    if (!this.defaultEnvironments.includes(buildConfig.name)){
      canRunOrDeploy = true;
    }

    return canRunOrDeploy;
  }
}

<div class="modal-content app-content">
  <div class="modal-header align-items-center mb-4">
    <div class="d-flex align-items-center">
      <button type="button" class="btn btn-primary link-btns mr-3" data-bs-toggle="modal" data-bs-target="#right" style="
          border-radius: 50% !important;
          color: #fff !important;
          background: #213a60 !important;
          border-color: #213a60;
        ">
        <i class="bi bi-pencil-fill"></i>
      </button>
      <h5 class="modal-title app-title"> {{ organization?.id ? 'Update' : 'Create new' }} organization</h5>
    </div>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body px-4">
    <form [formGroup]="organizationForm">
      <div class="row px-4">
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Name*</label>
            <input type="text" class="form-control" formControlName="name" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Chargebee Customer Id</label>
            <input type="text" class="form-control" formControlName="chargebeeCustomerId" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Chargebee Subscription Id</label>
            <input type="text" class="form-control" formControlName="chargebeeSubscriptionId" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group pt-1">
            <label>Subscription Type</label>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="subscriptionType" />
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">Number Of User</label>
            <input type="number" class="form-control" formControlName="numberOfUser" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="Strategy">URL</label>
            <input type="text" class="form-control" formControlName="url" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group pt-1">
            <label>Subscription Status</label>
            <div class="form-group">
              <select formControlName="chargebeeSubscriptionStatus" class="form-control cluster_name">
                <option value="" hidden>Sel. Type</option>
                <option *ngFor="let cs of chargebeeStatus" [value]="cs">
                  {{ cs }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group pt-1 d-flex">
            <label style="padding-top: 1.6rem; margin-right: .8rem">Config Completed</label>
            <div class="switch-on-off">
              <label class="switch">
                <input type="checkbox" formControlName="configCompleted">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group pt-1 d-flex">
            <label style="padding-top: 1.6rem; margin-right: .8rem">Is Contacted</label>
            <div class="switch-on-off">
              <label class="switch">
                <input type="checkbox" formControlName="isContacted">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-3 pt-4">
        <div class="col-md-12 text-center">
          <button type="button" class="btn btn-primary update-btn" (click)="onSubmit()"
            [disabled]="loading || (organizationForm.invalid)">
            {{ organization.id ? 'Update' : 'Create' }}
            <span class="spinner-border text-white" style="font-size: 10px; height: 15px; width: 15px;" role="status"
              *ngIf="loading">
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

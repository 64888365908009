import { Component } from '@angular/core';

@Component({
  selector: 'app-detail-deployment',
  templateUrl: './detail-deployment.component.html',
  styleUrls: ['./detail-deployment.component.scss']
})
export class DetailDeploymentComponent {

}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TagStrategy } from 'src/app/data/model/tag_strategy';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { TagService } from 'src/app/modules/parameters/services/tag.service';
import { Position, typeTags, Type, regions, SUPPORT_EMAIL } from 'src/app/utils/const';
import { AppService } from '../app.service';
import { Registry } from 'src/app/utils/types';
import { ShareService } from 'src/app/data/service/share.service';
import * as moment from 'moment';
import { RegistryFormModalComponent } from './rregistry-form-modal/registry-form-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from 'src/app/auth/pages/login/services/login.service';
import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';

declare var $: any;
export function equalsTo(name: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
      control.value?.toLowerCase() === name?.toLowerCase()
          ? null : {wrongColor: control.value};
}
@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss'],
})
export class RegistryComponent implements OnInit {
  formSubmited = false;
  registries: Registry[] = [];
  error: any = null;
  registrySelected: Registry;
  currentModal: string = "";
  registryLoaded: boolean = false;
  loading : boolean = false;
  deleteRegistryForm: UntypedFormGroup;
  formDisable: boolean = false;
  currentRegistry: Registry;
  bsModalRef: BsModalRef;

  currentUser: any;
  supportEmail = SUPPORT_EMAIL;

  constructor(
    private _location: Location,
    private modalService: BsModalService,
    private service: AsideExtenderService,
    private appService: AppService,
    private sharedService: ShareService,
    public auth: AppAuthGuardService
  ) {}

  ngOnInit(): void {
    const session = this.sharedService.getSession();
    this.currentUser = session.currentUser;

    this.getAllRegistries();
  }

  onOpen(): void {
    const config: any = {
      class: 'popup-right-side',
      initialState: {
        registry: this.currentRegistry,
      },
    };

    this.bsModalRef = this.modalService.show(
      RegistryFormModalComponent,
      config
    );

    this.bsModalRef.content.event.subscribe((res) => {
      this.getAllRegistries();
    });
  }

  onCreate(registry) {
    this.currentRegistry = null;
    this.onOpen();
  }

  toAllowUpdateRegistry(registry) {
    if (this.currentUser.email == this.supportEmail) {
      return false;
    }
    return registry.shared;
  }
  onEdit(registry) {
    this.currentRegistry = registry;
    this.onOpen();
  }

  initDeleteForm() {
    this.deleteRegistryForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        equalsTo(this.registrySelected?.name),
      ]),
    });

    this.deleteRegistryForm.statusChanges.subscribe((_) => {
      if (_ == 'INVALID') {
        this.formDisable = false;
      }

      if (_ == 'VALID') {
        this.formDisable = true;
      }
    });
  }

  getAllRegistries(): void {
    this.registryLoaded = true;
    this.appService.getRegistries().subscribe(
      (_: Registry[]) => {
        if (this.auth?.hasOrganizationRoles('super_admin')) {
          this.registries = _;
          this.registryLoaded = false;
        } else {
          this.registries = [];
          this.registryLoaded = false;
          this.service.show({
            type: Type.ERROR,
            message: 'Permission denied',
            title: 'Registry',
            position: Position.TOP,
          });
        }
      },
      (err) => {
        this.service.show({
          type: Type.ERROR,
          message: err.error,
          title: 'Registry',
          position: Position.TOP,
        });
        this.registryLoaded = false;
      }
    );
  }

  formatDateToHuman(dateStr) {
    const date = moment(dateStr);
    return date.fromNow();
  }

  goBack(): void {
    this._location.back();
  }

  close(): void {
    $(`#${this.currentModal}`).modal('hide');
  }
  toggleModal(modalId: string, item: Registry): void {
    this.registrySelected = item;
    this.currentModal = modalId;
    $(`#${modalId}`).modal('show');
    this.initDeleteForm();
  }

  deleteRegistryById(): void {
    this.appService.deleteRegistry(this.registrySelected.id).subscribe(
      () => {
        this.getAllRegistries();
        this.service.show({
          title: 'Delete Registry',
          message: 'Registry deleted successfully',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
        this.close();
      },
      (error) => {
        this.service.show({
          title: 'Delete Registry',
          message: error?.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.close();
      }
    );
  }
}

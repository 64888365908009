import { Component, HostListener, OnInit } from '@angular/core';
import { InviteMemberComponent } from '../popups/invite-member/invite-member.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CheckScreenComponent } from '../popups/check-screen/check-screen.component';
import { SIZE_SCREEN } from 'src/app/utils/const';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {
    this.checkHostDeviceScreen();
  }

  onInvite(): void {
    const config: ModalOptions = { class: 'modal-lg modal-dialog-centered' };
    this.modalService.show(InviteMemberComponent, config);
  }

  @HostListener('window:resize', ['$event'])
  checkHostDeviceScreen(event = null) {
    if (window.innerWidth < SIZE_SCREEN) {
      this.openModalScreen();
    } else {
      this.modalService.hide();
    }
  }

  openModalScreen(): void {
    const config: ModalOptions = {
      class: 'modal-lg modal-dialog-centered',
      backdrop: 'static',
    };
    this.modalService.show(CheckScreenComponent, config);
  }
}


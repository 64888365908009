<div class="modal-content msg-content">
  <div class="modal-header msg-head align-items-center">
    <h5 class="modal-title msgtitle" id="exampleModalLabel">
      <button
        type="button"
        class="btn btn-primary link-btn mr-4"
        data-bs-toggle="modal"
        data-bs-target="#right"
      >
        <i class="bi bi-pencil-fill"></i>
      </button>

      <span>Messaging Account</span>
    </h5>

    <i class="bi bi-x-lg dialog-close-icon" (click)="onCloseDialog()"></i>
  </div>

  <div class="modal-body">
    <form class="msg-acc-form" [formGroup]="openForm">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10 col-sm-12">
          <div class="form-group mb-5">
            <label class="msg-acc-label"
              >Sender's Email<sup>*</sup></label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Enter sender's mail"
              required
              formControlName="email" 
            />
          </div>

          <div class="form-group mb-5">
            <label class="msg-acc-label"
              >Sender's SMTP Server<sup>*</sup></label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Enter sender's SMTP server"
              required
              formControlName="smtp_server" 
            />
          </div>

          <div class="form-group mb-5">
            <label class="msg-acc-label"
              >Sender's Password<sup>*</sup></label>
            <input
              type="password"
              class="form-control"
              placeholder="Enter sender's password"
              required
              formControlName="password" 
            />
          </div>

          <div class="form-group mb-5">
            <label class="msg-acc-label"
              >Sender's SMTP server Port<sup>*</sup></label
            >
            <input
              type="number"
              class="form-control"
              placeholder="Enter server port"
              required
              formControlName="port" 
            />
          </div>

          <button type="submit" class="form-control btn btn-primary submit" [disabled]="openForm.invalid || loading" (click)="onCreateAccount()" *ngIf="account == null">
            Create
          </button>

          <button type="submit" class="form-control btn btn-primary submit" [disabled]="openForm.invalid || loading" (click)="onUpdateAcount()"  *ngIf="account !== null" >
            Update
          </button>

          <button type="submit" class="form-control btn btn-primary delete" [disabled]="openForm.invalid || loading" (click)="onDeleteAccount()"  *ngIf="account !== null">
            Delete
          </button>

        </div>
      </div>
    </form>
  </div>
</div>

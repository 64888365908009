import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Session } from 'src/app/data/model/Session';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { OrganizationService } from '.././../../../data/service/organization.service';
import { ShareService } from 'src/app/data/service/share.service';
import { CHAGEBEE_STATUS_REJECT, Position, SUBSCRIPTION_DEFAULT_STATUS, Type } from 'src/app/utils/const';
import {environment} from '../../../../../environments/environment';
import { Organization } from 'src/app/data/model/organization';

@Component({
  selector: 'app-check-config',
  templateUrl: './check-config.component.html',
  styleUrls: ['./check-config.component.scss'],
})
export class CheckConfigComponent implements OnInit {
  @ViewChild('template', { read: TemplateRef }) modalInfo: TemplateRef<any>;
  loader = false;
  currentPlan = null;
  currentSesion: Session;
  currentOrg: any;
  organizationId: string;
  hasAccess = false;
  chargebeeStatusReject = CHAGEBEE_STATUS_REJECT;
  subscriptionDefaultStatus = SUBSCRIPTION_DEFAULT_STATUS;

  constructor(
    private shareService: ShareService,
    private service: AsideExtenderService,
    private route: ActivatedRoute,
    private router: Router,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.loader = true;

    this.route.params.subscribe((param) => {
      const organizationId = param.orgId;

      if (organizationId) {
        this.checkSubscription(organizationId);
      } else {
        this.loader = false;
        this.service.show({
          title: 'Check failled',
          message: 'Organization not found',
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.shareService.removeSession();
        this.router.navigate(['/auth/login']);
      }
    });
  }

  checkSubscription(organizationId: string) {
    this.organizationService.checkSubscription(organizationId).subscribe(
      (response: Organization) => {
        this.loader = false;
        this.hasAccess = false;

        if (
          response.chargebeeSubscriptionStatus == this.subscriptionDefaultStatus
        ) {
          this.hasAccess = true;
        } else {
          if (!this.chargebeeStatusReject.includes(response.chargebeeSubscriptionStatus)) {
            this.hasAccess = true;
          }
        }

        if (this.hasAccess) {
          const session = this.shareService.getSession();
          this.shareService.setOrganizationSelected(response, session);

          if (environment.local) {
            this.router.navigate(['/dashboard']);
          } else {
            const url = `${environment.schemas} ://${response.name}.${environment.baseUrl}/#/dashboard`;
            window.location.replace(url);
          }
        }else{
          this.service.show({
            title: 'Check failled',
            message: "Your subscription not available",
            type: Type.ERROR,
            position: Position.TOP,
          });
          this.loader = false;
          this.shareService.removeSession();
          this.router.navigate(['/auth/login']);
        }
      },
      (error: any) => {
        this.service.show({
          title: 'Check failled',
          message: error.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.loader = false;
        this.shareService.removeSession();
        this.router.navigate(['/auth/login']);
      }
    );
  }

  // async goToChargebee() {
  //   const redirect = window.location.origin +  '/%23/check-config/' + this.organizationId;

  //   let hostedPage = await this.organizationService
  //     .askHostedPageForCreateSubscriptionFromChargebee(
  //       redirect,
  //       this.currentOrg
  //     )
  //     .toPromise()
  //     .catch((error) => {
  //       this.service.show({
  //         type: Type.ERROR,
  //         message: 'An error occurred while creating a subscription',
  //         title: 'Chargebee failed',
  //         position: Position.TOP,
  //       });
  //     });
  //   this.loader = false;
  //   if (hostedPage) {
  //     window.location.replace(hostedPage.url);
  //   } else {
  //     this.shareService.removeSession();
  //     this.router.navigate(['/auth/login']);
  //   }
  // }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Options } from 'src/app/utils/const';

@Injectable({
	providedIn: 'root'
})
export class AsideExtenderService {
  private dataSource = new BehaviorSubject(false);
  private datacd = new BehaviorSubject<any>(localStorage.getItem('project'));
  private nofifySubject = new Subject<{options?: Options}>();

  notify = this.nofifySubject.asObservable();
  data = this.dataSource.asObservable();



  constructor() {}

  setIsExtend(state: boolean): void {
    this.dataSource.next(state);
  }

  show(options: Options) {
    if (options?.message) {
      this.nofifySubject.next({options});
    }
  }

  setdatacd(filter: string) {
    this.datacd.next(filter);
    localStorage.setItem('project',filter);
  }

  getdatacd () {
    return this.datacd.asObservable();
  }
}

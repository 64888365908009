export class CloudProvider {
  id?: string;
  name: string;
  armclientid: string;
  armclientsecret: string;
  armsubscriptionid: string;
  armtenantd: string;
  armlocation: string;
  armresourcegroupname: string;
  awsSessionToken: string;
  awsaccesskeyid: string;
  awsdefaultregion: string;
  awssecretaccesskey: string;
  cloudprovider: string;
  createdDate: Date;
  gcpserviceaccountjsonkeyfile: string;
  gcpzone: string;
  organizationId: string;
  region: string;
}



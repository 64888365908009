import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { Application, DeployApp, Pipeline, PipelineRun } from 'src/app/utils/types';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CreateDeploymentComponent } from '../create-deployment/create-deployment.component';
import { AsideExtenderService } from 'src/app/data/service/aside-extender.service';
import { Position, RESOURCES, Type } from 'src/app/utils/const';
import * as moment from 'moment';
import { Subscription, interval } from 'rxjs';
import { BuildConfigComponent } from '../build-config/build-config.component';
import { BuildConfig } from 'src/app/data/model/buildConfig';
import { RunNewPipelineComponent } from 'src/app/layouts/popups/run-new-pipeline/run-new-pipeline.component';

import { AppAuthGuardService } from 'src/app/data/service/app-auth-guard.service';


type Environment = {
  applicationId: string,
  createdAt: string,
  id: string,
  key: string,
  updatedAt: string,
  value: string
}

@Component({
  selector: 'app-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.scss']
})
export class AppDetailsComponent implements OnInit, OnDestroy {
  application: Application
  environmentItems: Environment[] = []
  openForm: UntypedFormGroup;
  otherEnvVariables = [];
  otherEnvVariablesForm: FormGroup;
  envVariables = [];
  envVariablesForm: FormGroup;
  applicationID: string = ""
  loading : boolean = false;
  loadingProvider : boolean = false
  loadingPipeline : boolean = false
  rerunLoading: boolean = false
  rerunId: string = '';
  deployApp : DeployApp[] = []
  appToDelete : DeployApp
  private intervalSubscription: Subscription;
  modalRef: BsModalRef;
  projectID: string = "";
  pipelineData: Pipeline;
  webhookUrl: string = ""
  pipelineRunDataTable: PipelineRun[] = [];
  resources = RESOURCES;
  defaultEnvironments = ['dev', 'preprod', 'prod'];
  defaultProdEnvironments = ['preprod', 'prod'];

  registryID: string = "";
  registry: any;
  buildConfigs: BuildConfig[] = []
  buildConfigToDelete : BuildConfig;

  constructor(private router: Router, private route: ActivatedRoute, private appService: AppService,
     private modalService: BsModalService,
    public auth: AppAuthGuardService,
     private service: AsideExtenderService

    ) {
    }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initForm();
    this.otherEnvVariablesForm = new FormGroup({});
    this.envVariablesForm = new FormGroup({});
    this.route.queryParams.subscribe(params => {
      this.applicationID = params['id'];
      this.projectID = params['projectId'];
      if (this.applicationID ) {
        this.getApplicationById(this.applicationID, this.projectID);
        this.getVariablesEnvironment(this.applicationID)
        this.getPipelineRunForAnApplication()
        this.getAllDeployApplication(this.applicationID, this.projectID)
        this.getPipelineById(this.projectID, this.applicationID)
        this.getBuildConfigs(this.projectID, this.applicationID)
        this.intervalSubscription = interval(5000).subscribe(() => {
            this.getPipelineRunForAnApplication();
            this.getAllDeployApplication(this.applicationID,this.projectID)
        });
      }
    });
  }

  initForm() {
    this.loadingPipeline = true
    this.openForm = new UntypedFormGroup({
      otherEnvVariables: new FormGroup({}),
      envVariables: new FormGroup({})
    });
  }
  getPipelineById(projectID: string, pipelineID: string){
    this.appService.getPipelineById(projectID, pipelineID).subscribe((response : Pipeline) => {
        this.pipelineData = response
        let provider = response?.gitProvider == 'gitlabserver' ? 'gitlab' : response?.gitProvider;
        this.webhookUrl = this.appService.getWebhookUrl(this.projectID, response?.id, provider)
      })
  }

  getPipelineRunForAnApplication(){
    const url: string = this.router.url;
    if (url.includes('app-details')) {
      this.appService.getPipelineRun(this.applicationID,this.projectID).subscribe((response: PipelineRun[] ) => {
        let tab = response
        tab?.forEach((obj: any) => {
          obj.createdAt = new Date(obj.createdAt);
        });
        tab?.sort((a: any, b: any) => b?.createdAt.getTime() - a?.createdAt.getTime());
        this.pipelineRunDataTable = tab
        this.loadingPipeline = false
      }, (error) => {

      })
    }else {
      this.intervalSubscription.unsubscribe();

    }

  }
  getErrorFailedStatus(value: string): boolean {
    if(/fail(?:ed)?|error/i.test(value)){
      return true
    }else {
      return false
    }
  }

  getAllDeployApplication(appID: string, projectId: string ){
  const url: string = this.router.url;
    if (url.includes('app-details')) {
    this.appService.getDeployApplication(appID,projectId).subscribe((response: DeployApp[]) => {
      let tab = response
      tab?.forEach((obj: any) => {
        obj.createdAt = new Date(obj.createdAt);
      });
      tab?.sort((a: any, b: any) => b?.createdAt.getTime() - a?.createdAt.getTime());
      this.deployApp = tab
    }, (error) => {

    })
      }else{
        this.intervalSubscription.unsubscribe();
      }
  }
  getApplicationById(appID: string, projectId: string) {
    this.loadingProvider = true
    this.appService.getApplicationById(appID,projectId).subscribe((response: any) => {
      this.application = response
      this.loadingProvider = false
    })
  }

  getVariablesEnvironment(appID: string) {
      this.appService.getVariablesEnvironment(appID, this.projectID).subscribe((response: any) => {
      this.environmentItems = response
      if (response.length !== 0) {
        var newTab = this.environmentItems?.map((item: any) => {
          return { key: item.key, value: item.value };
        });
        if(Object.keys(newTab).length > 0){
          newTab.forEach((element, index) => {
            this.pushEnvVariables(element, index);
          });
        }
      }

    })

  }
  pushOtherEnvVariables(envVar = null, position = null) {
    let index = position
      ? position
      : Object.entries(this.otherEnvVariablesForm.value).length;
    let envVariablesGroup = new FormGroup({
      key: new FormControl(envVar ? envVar.key : '', Validators.required),
      value: new FormControl(envVar ? envVar.value : '', Validators.required),
    });

    this.otherEnvVariablesForm.addControl('envVar' + index, envVariablesGroup);
    this.openForm.setControl('otherEnvVariables', this.otherEnvVariablesForm);
    this.otherEnvVariables = Object.entries(this.otherEnvVariablesForm.value);

  }

  onDeleteEnvVariables(index: number) {
    this.envVariablesForm.removeControl('envVar' + index);
    this.envVariables = Object.entries(this.envVariablesForm.value);
    this.openForm.setControl('envVariables', this.envVariablesForm);
    this.envVariablesForm = new FormGroup({});
    this.envVariables.forEach((element, index) => {
      this.pushEnvVariables(element[1], index);
    });
  }
  pushEnvVariables(envVar = null, position = null) {
    let index = position
      ? position
      : Object.entries(this.envVariablesForm.value).length;
    let envVariablesGroup = new FormGroup({
      key: new FormControl(envVar ? envVar.key : '', Validators.required),
      value: new FormControl(envVar ? envVar.value : '', Validators.required),
    });

    this.envVariablesForm.addControl('envVar' + index, envVariablesGroup);
    this.openForm.setControl('envVariables', this.envVariablesForm);
    this.envVariables = Object.entries(this.envVariablesForm.value);
  }

  onDeleteOtherEnvVariables(index: number) {
    this.otherEnvVariablesForm.removeControl('envVar' + index);
    this.otherEnvVariables = Object.entries(this.otherEnvVariablesForm.value);
    this.openForm.setControl('otherEnvVariables', this.otherEnvVariablesForm);
    this.otherEnvVariablesForm = new FormGroup({});
    this.otherEnvVariables.forEach((element, index) => {
      this.pushOtherEnvVariables(element[1], index);
    });
  }
  onGoToUrl(domaine: string){
    let url = 'http://' + domaine
    window.open(url,"_blank")
  }
  async onCopyUrl(image: string){
    if (image) {
      try {
        await navigator.clipboard.writeText(image);
        this.service.show({
          title: 'Copy success',
          message: 'Image has been copied',
          type: Type.SUCCESS,
          position: Position.TOP,
        });
      } catch (err) {
        this.service.show({
          title: 'Copy error',
          message: err,
          type: Type.ERROR,
          position: Position.TOP,
        });
      }
    } else {
      this.service.show({
        title: 'Copy error',
        message: 'No image currently',
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
  }
  addMore(): void {
    this.pushOtherEnvVariables();
  }

  keyValidation(tab) {
    const setKeys = new Set();
    for (const element of tab) {
      if (setKeys.has(element.key)) {
        return true;
      } else {
        setKeys.add(element.key);
      }
    }

    return false;
  }
  update(): void {
  this.loading = true
  let otherEnvVarTab = Object.values(this.openForm.value.otherEnvVariables)
  let envVarTab = Object.values(this.openForm.value.envVariables)
  let allEnvVar = otherEnvVarTab.concat(envVarTab)
  const checkEmptyKey = allEnvVar.every((element: Environment) => element.key === "");
  const validationKeyTab = this.keyValidation(allEnvVar);
  if(checkEmptyKey && (otherEnvVarTab.length !== 0 || envVarTab.length !== 0)){
    this.loading = false
    this.service.show({
      title: 'Environment Variables',
      message: "Key is required",
      type: Type.ERROR,
      position: Position.TOP,
    });
  }
  else if(validationKeyTab){
    this.loading = false
    this.service.show({
      title: 'Deploy application',
      message: "Some key is duplicated",
      type: Type.ERROR,
      position: Position.TOP,
    });
  }else{
    let dataToSend = allEnvVar.map((item: any) => ({
      applicationId: this.applicationID,
      key: item.key,
      value: item.value
    }))
    this.appService.updateVariablesEnvironment(this.applicationID, dataToSend, this.projectID).subscribe((response) => {
      this.otherEnvVariablesForm = new FormGroup({});
      this.envVariablesForm = new FormGroup({});
      this.otherEnvVariables = []
      this.getVariablesEnvironment(this.applicationID)
      this.loading = false;
      this.service.show({
        title: 'Deploy environment variables',
        message: "Environment variables updated successfully",
        type: Type.SUCCESS,
        position: Position.TOP,
      });
    }, (err) => {
      this.loading = false
      const message = err.error.match(/^(.*is invalid: not match regex)/);
      this.service.show({
        title: 'Deploy application',
        message: message ? message[1] : err.error,
        type: Type.ERROR,
        position: Position.TOP,
      });
    })
  }

  }
  create(): void {
    this.loading = true
    let otherEnvVarTab = Object.values(this.openForm.value.otherEnvVariables)
    const checkEmptyKey = otherEnvVarTab.every((element: Environment) => element.key === "");
    const validationKeyTab = this.keyValidation(otherEnvVarTab);
    if(checkEmptyKey){
      this.loading = false
      this.service.show({
        title: 'Environment Variables',
        message: "Key is required",
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
    else if(validationKeyTab){
      this.loading = false
      this.service.show({
        title: 'Environment Variables',
        message: "Some key is duplicated",
        type: Type.ERROR,
        position: Position.TOP,
      });
    }else{
      let dataToSend = otherEnvVarTab.map((item: any) => ({
        applicationId: this.applicationID,
        key: item.key,
        value: item.value
      }))

      this.appService.createVariablesEnvironment(this.applicationID, dataToSend, this.projectID).subscribe((response) => {
        this.initForm()
        this.otherEnvVariablesForm = new FormGroup({});
        this.envVariablesForm = new FormGroup({});
        this.otherEnvVariables = []
        this.getVariablesEnvironment(this.applicationID)
        this.loading = false
        this.service.show({
          title: 'Environment Variables',
          message: "Environment variables created successfully",
          type: Type.SUCCESS,
          position: Position.TOP,
        });
      }, (err) => {   this.loading = false
        const message = err.error.match(/^(.*is invalid: not match regex)/);
        this.service.show({
          title: 'Environment Variables',
          message: message ? message[1] : err.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
      })
    }


  }

  removeOtherItem(index: number): void {

    this.onDeleteOtherEnvVariables(index)
  }
  removeItem(index: number): void {

    this.onDeleteEnvVariables(index)
  }
  pipelineLogs(item: PipelineRun) {
    this.router.navigate(['/app-log'],{queryParams: {pipelineId: item.id, projectId: item.projectId}});
  }

  deployAppInAnEnvironment(): void{
    const config: any = { class: 'popup-right-side', initialState: {application: this.application}};
    this.modalService.show(CreateDeploymentComponent, config);
  }
  displayDateForHuman(date: any) {
    if (date) {
      let parseToMoment = moment(date);
      return parseToMoment.fromNow();
    }
  }
  onBack(){
    this.router.navigate(["/apps"]);

  }
  onDeleteDeployApp() {
    this.appService.deleteDeploAppliation(this.appToDelete?.environmentId, this.appToDelete?.id, this.appToDelete?.projectId).subscribe((response) => {
      this.service.show({
        title: 'Deploy Application',
        message: "Deploy app deleted successfully",
        type: Type.SUCCESS,
        position: Position.TOP,
      });
      this.onCloseDialog()
    },(error) => {
      this.service.show({
        title: 'Deploy Application',
        message: error.error,
        type: Type.ERROR,
        position: Position.TOP,
      });
      this.onCloseDialog()
    })
  }

  onOpenDeleteModal(template: TemplateRef<any>, app: DeployApp) {
    this.appToDelete = app
    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openDeleteModal(template: TemplateRef<any>, buildConfig: BuildConfig) {
    this.buildConfigToDelete = buildConfig
    const config: ModalOptions = {
      class: 'modal-md modal-dialog-centered',
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onCloseDialog(): void {
    this.modalRef.hide();
  }

  addBuildConfig(): void {
    this.appService.getRegistryById(this.application.registryId).subscribe((response: any) =>{
      this.registry = response;
      const config: any = { class: 'popup-right-side', initialState: { repositoryUrl: this.registry.repo, state: false }};
      this.modalService.show(BuildConfigComponent, config);
    })
  }
  getBuildConfigs(projectID: string, pipelineID: string): void {
    this.appService.getAllBuildConfig(projectID,pipelineID ).subscribe((response: BuildConfig[]) => {
    this.buildConfigs = response

    }, (error) => {
      this.service.show({
        title: 'Configuration',
        message: error.error,
        type: Type.ERROR,
        position: Position.TOP,
      });
      this.loading = false;

    });
  }
  openEditModal(myData: any) {
    const config: any = { class: 'popup-right-side', initialState: { data: myData, state: true}};
    this.modalService.show(BuildConfigComponent, config);
  }
  deleteBuildConfig(): void {
    this.appService.deleteBuildConfig(this.projectID, this.applicationID, this.buildConfigToDelete.id ).subscribe((response: BuildConfig) => {
      this.getBuildConfigs(this.projectID, this.applicationID)
      this.service.show({
        title: 'Delete Configuration',
        message: "Configuration deleted successfully",
        type: Type.SUCCESS,
        position: Position.TOP,
      });
      this.onCloseDialog()
      }, (error) => {
        this.service.show({
          title: 'Delete Configuration',
          message: error.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.loading = false;

      });
    }

  /* New pipeline part */
  runNewPipeline(){
    const config: any = { class: 'modal-lg modal-dialog-centered', initialState: {application: this.application} };
    const modalRef: BsModalRef = this.modalService.show(RunNewPipelineComponent, config);
  }

  rerunPipeline(projectID: string, pipelineID: string){
    if (this.canReRunPipeline()) {
      this.rerunLoading = true
      this.rerunId = pipelineID;
      this.appService.rerunPipeline(projectID, pipelineID).subscribe((response: any) => {
        this.rerunId = '';
        this.rerunLoading = false
        this.getPipelineRunForAnApplication();
      }, (error) => {
        this.service.show({
          title: 'Rerun job',
          message: error.error,
          type: Type.ERROR,
          position: Position.TOP,
        });
        this.rerunLoading = false
        this.rerunId = '';
        this.getPipelineRunForAnApplication();
      })
    } else {
      this.service.show({
        title: 'Permission denied',
        message:
          'This profile is not authorized to run pipeline in this environment',
        type: Type.ERROR,
        position: Position.TOP,
      });
    }
  }

  canAddOrUpdateOrDeleteConfig(config=null){
    let canAddOrUpdateOrDeleteConfig = false;

    if (this.auth?.hasProjectRoles('developer', this.projectID)) {
      canAddOrUpdateOrDeleteConfig = true;
      if (config) {
        if (['prod'].includes(config?.name)) {
          canAddOrUpdateOrDeleteConfig = false;
        }
      }
    }
    if (
    this.auth?.hasOrganizationRoles('super_admin') ||
    this.auth?.hasOrganizationRoles('owner+') ||
    this.auth?.hasOrganizationRoles('owner') ||
    this.auth?.hasProjectRoles('admin', this.projectID)
    ) {
      canAddOrUpdateOrDeleteConfig = true;
    }


    return canAddOrUpdateOrDeleteConfig
  }

  canDeleteDeployement(deploy){
    let canDeleteDeployement = false;
    if (
      this.auth?.hasOrganizationRoles('super_admin') ||
      this.auth?.hasOrganizationRoles('owner+') ||
      this.auth?.hasOrganizationRoles('owner') ||
      this.auth?.hasProjectRoles('admin', this.projectID)) {
      canDeleteDeployement = true;
    }

    if (deploy.buildConfigId) {
      if (this.buildConfigs) {
        let buildConfig = this.buildConfigs.find((bc) => {bc.id == deploy.buildConfigId;});

      if (!['prod'].includes(buildConfig?.name) && this.auth?.hasProjectRoles('developer', this.projectID)) {
        canDeleteDeployement = true;
      }
      }
    }

    return canDeleteDeployement;
  }

  canReRunPipeline(){
    let canRerun = false;

    if (
      this.auth?.hasOrganizationRoles('super_admin') ||
      this.auth?.hasOrganizationRoles('owner+') ||
      this.auth?.hasOrganizationRoles('owner') ||
      this.auth?.hasProjectRoles('admin', this.projectID) ||
      this.auth?.hasProjectRoles('developer', this.projectID)
    ) {
      canRerun = true;
    }

    return canRerun;
  }
}
